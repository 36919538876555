import { AppRouteObject } from '@faro/app-context';
import React, { Suspense } from 'react';
const ParentSpaceNavLinkWidgetModule = React.lazy(() =>
    import('@faro/study-space-ui').then(module => ({ default: module?.ParentSpaceNavLinkWidgetModule }))
);

const sideNavWidgetRoutes: AppRouteObject[] = [
    {
        path: 'studies/:studyId/*',
        element: (
            <Suspense fallback={null}>
                <ParentSpaceNavLinkWidgetModule />
            </Suspense>
        ),
    },
    {
        path: '*',
        element: null,
    },
];

export default sideNavWidgetRoutes;
