import { BiomedicalNamedObject, toRequiredBiomedicalNamedObject } from '@faro/study-designer-model';
import { cqrs } from '@faro/http-clients';

/**
 * Client responsible for managing locations
 */
export const locationClient = cqrs<BiomedicalNamedObject>(
    '/api/study-designer/enumerated-values/locations',
    toRequiredBiomedicalNamedObject
).listableEntityClient();
