import { StudyDesign, toStudyDesign } from '@faro/study-designer-model';
import { cqrs } from '@faro/http-clients';
import { PopulationQueryParameter, StudyPathParameter } from '../parameter';

export type StudyDesignPathParameters = StudyPathParameter;
export type StudyDesignQueryParameters = PopulationQueryParameter;

/**
 * Client responsible for accessing the backend entity services
 */
export const studyDesignClient = {
    ...cqrs<StudyDesign, StudyDesignPathParameters>(
        '/api/study-designer/studies/:studyId/study-designs',
        toStudyDesign
    ).writableEntityClient<StudyDesignQueryParameters>(),
};
