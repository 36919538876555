import { ComponentPropsWithoutRef, forwardRef, useRef, useState } from 'react';
import { FaroListControls, FaroMenu, FaroScrollFog, FaroTable, useScrollStateListener } from '@faro/design-system';
import { forceDrop, tableRowDraggableProps, useTableDragDrop } from '../../../package/react-beautiful-dnd';
import { updateScrollFog } from '../../../package/table/updateScrollFog';
import { FaroTextAreaField } from '@faro/forms';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import dataTableStyles from './DataTable.module.scss';
import styles from './MetadataTable.module.scss';
import { ContentCopyIcon, Trash2Icon } from '@faro/icons';

export interface MetadataTableProps extends ComponentPropsWithoutRef<'div'>, DataTableProps<[string, string]> {
    readOnly?: boolean;
}

interface DataTableProps<Row> extends DataTableCallbacks<Row> {
    rows: Row[];
}

interface DataTableCallbacks<Row> {
    name: string;
    onRowInsert(index: number): void;

    onRowClone(row: Row, index: number): void;

    onRowMove(row: Row, from: number, to: number): void;

    onRowDelete(row: Row, index: number): void;
}

const MetadataTable = forwardRef<HTMLDivElement, MetadataTableProps>((props, ref): JSX.Element => {
    const {
        name,
        rows = [],
        readOnly = false,
        className = '',
        onRowInsert,
        onRowClone,
        onRowMove,
        onRowDelete,
        ...rest
    } = props;

    const propsRef = useRef(props);
    propsRef.current = props;

    const [scrollContainerElement, setScrollContainerElement] = useState<HTMLElement | null>(null);

    //TODO Remove this hack by refactoring rows from nested arrays to objects with identifiers
    // and change rows.map(..) below to key off of id rather than index
    const [renderTrigger, setRenderTrigger] = useState(0);

    const { draggingRef, callbacks: dragDropContextCallbacks } = useTableDragDrop(
        scrollContainerElement?.querySelector('table') as HTMLTableElement,
        (from, to) => {
            const row = propsRef.current.rows[from];
            propsRef.current.onRowMove?.(row, from, to);
            setRenderTrigger(renderTrigger + 1);
        }
    );

    useScrollStateListener(scrollContainerElement, (scrollState: any) => {
        updateScrollFog(scrollContainerElement as HTMLElement, scrollState, { leftGutter: true, rightGutter: false });
    });

    return (
        <div
            {...rest}
            ref={ref}
            className={`
                ${styles.root}
                ${dataTableStyles.dataTableContainer}
                ${readOnly ? dataTableStyles.readOnly : ''}
                ${className}
            `}
        >
            <div ref={setScrollContainerElement} className={dataTableStyles.dataTableScrollContainer}>
                <FaroTable appearance="borderless" data-automation-id="metadataTable">
                    <thead>
                        <tr>
                            <FaroTable.GutterCell className={dataTableStyles.gutter}>
                                <div />
                            </FaroTable.GutterCell>
                            <th />
                            <th />
                        </tr>
                    </thead>
                    {rows.length === 0 ? (
                        <tbody>
                            <tr data-automation-role="metadataTableRow">
                                <FaroTable.GutterCell className={dataTableStyles.gutter}>
                                    <div />
                                </FaroTable.GutterCell>
                                <FaroTable.PlaceholderCell
                                    colSpan={2}
                                    readOnly={readOnly}
                                    onClick={() => {
                                        props?.onRowInsert?.(0);
                                    }}
                                >
                                    {readOnly ? 'Empty' : 'Click to add metadata'}
                                </FaroTable.PlaceholderCell>
                            </tr>
                        </tbody>
                    ) : (
                        <DragDropContext {...dragDropContextCallbacks}>
                            <Droppable droppableId="droppable">
                                {droppableProvided => (
                                    <tbody ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                                        {rows.map((row, index) => {
                                            return (
                                                <Draggable
                                                    key={renderTrigger + ':' + index}
                                                    draggableId={String(index)}
                                                    isDragDisabled={rows.length < 2}
                                                    index={index}
                                                >
                                                    {(draggableProvided, draggableSnapshot) => {
                                                        // Fix for drag events not ending when the draggable is dropped
                                                        // in certain situations. See method docs.
                                                        forceDrop(draggableProvided, draggingRef);

                                                        const dragging = draggableSnapshot.isDragging;
                                                        const draggableProps = tableRowDraggableProps(
                                                            draggableProvided,
                                                            scrollContainerElement,
                                                            { leftGutter: true, rightGutter: false }
                                                        );

                                                        return (
                                                            <tr
                                                                {...draggableProps}
                                                                data-automation-role="metadataTableRow"
                                                                className={`
                                                                    ${dataTableStyles.row}
                                                                    ${dragging ? dataTableStyles.dragging : ''}
                                                                `}
                                                            >
                                                                <FaroTable.GutterCell
                                                                    className={dataTableStyles.gutter}
                                                                >
                                                                    <FaroListControls
                                                                        data-automation-id="metadataTableRowControls"
                                                                        className={`${dataTableStyles.controls} ${
                                                                            dragging ? dataTableStyles.dragging : ''
                                                                        }`}
                                                                        insertTooltip="Insert row below"
                                                                        onInsert={async () => {
                                                                            await propsRef.current.onRowInsert?.(
                                                                                index + 1
                                                                            );
                                                                            setRenderTrigger(renderTrigger + 1);
                                                                        }}
                                                                        dragDisabled={rows.length < 2}
                                                                        dragging={dragging}
                                                                        handleProps={draggableProvided.dragHandleProps}
                                                                        menu={
                                                                            <FaroMenu>
                                                                                <FaroMenu.Item
                                                                                    left={<ContentCopyIcon />}
                                                                                    onClick={async () => {
                                                                                        await propsRef.current.onRowClone?.(
                                                                                            row,
                                                                                            index
                                                                                        );
                                                                                        setRenderTrigger(
                                                                                            renderTrigger + 1
                                                                                        );
                                                                                    }}
                                                                                    data-automation-id="metadataCloneMenuItem"
                                                                                >
                                                                                    Duplicate
                                                                                </FaroMenu.Item>
                                                                                <FaroMenu.Item
                                                                                    left={<Trash2Icon />}
                                                                                    color="danger"
                                                                                    onClick={async () => {
                                                                                        await propsRef.current.onRowDelete?.(
                                                                                            row,
                                                                                            index
                                                                                        );
                                                                                        setRenderTrigger(
                                                                                            renderTrigger + 1
                                                                                        );
                                                                                    }}
                                                                                    data-automation-id="metadataDeleteMenuItem"
                                                                                >
                                                                                    Delete
                                                                                </FaroMenu.Item>
                                                                            </FaroMenu>
                                                                        }
                                                                    />
                                                                </FaroTable.GutterCell>
                                                                <FaroTable.FieldCell type="text">
                                                                    <FaroTextAreaField
                                                                        className={styles.dataLabel}
                                                                        data-automation-id="metadataLabel"
                                                                        name={`${name}.${index}.0`}
                                                                        appearance="inline-editable"
                                                                        placeholder="Enter label or name"
                                                                        autoHeight
                                                                        minLines={1}
                                                                        minLength={1}
                                                                        maxLength={50}
                                                                        readOnly={readOnly}
                                                                        value={(row[0] || null) as any} // needed to bypass type check
                                                                    />
                                                                </FaroTable.FieldCell>
                                                                <FaroTable.FieldCell type="text">
                                                                    <FaroTextAreaField
                                                                        data-automation-id="metadataValue"
                                                                        name={`${name}.${index}.1`}
                                                                        appearance="inline-editable"
                                                                        placeholder="Enter value(s)"
                                                                        autoHeight
                                                                        minLines={1}
                                                                        maxLength={100}
                                                                        readOnly={readOnly}
                                                                        value={(row[1] || null) as any} // needed to bypass type check
                                                                    />
                                                                </FaroTable.FieldCell>
                                                            </tr>
                                                        );
                                                    }}
                                                </Draggable>
                                            );
                                        })}
                                        {droppableProvided.placeholder}
                                    </tbody>
                                )}
                            </Droppable>
                        </DragDropContext>
                    )}
                </FaroTable>
            </div>

            <FaroScrollFog />
        </div>
    );
});

export default MetadataTable;
