import React, { ComponentPropsWithRef, forwardRef, useContext } from 'react';
import styles from './SpaceGeneralInformation.module.scss';
import { FormikHelpers } from 'formik';
import { StudyAttributes, StudyAttributesEntity } from '@faro/study-space-service-model';
import SpaceGeneralInformationForm from './SpaceGeneralInformationForm/SpaceGeneralInformationForm';
import { ServiceContext } from '../../context';
import { useGeneralInformationData } from './useGeneralInformationData';
import { SpaceGeneralInformationSkeleton } from './SpaceGeneralInformation.skeleton';

export interface TitleFormProp {
    title: string | undefined;
}

export interface SpaceGeneralInformationProps extends ComponentPropsWithRef<'div'> {
    title?: string;
    studyAttributes: StudyAttributes;
    onSave: (valuesDelta: Partial<StudyAttributesEntity>) => Promise<any>;
    readonly readOnly?: boolean;
    readonly loading?: boolean;
}

const SpaceGeneralInformation = forwardRef<HTMLDivElement, SpaceGeneralInformationProps>((props, ref): JSX.Element => {
    const { title, studyAttributes, onSave, readOnly = false, loading, className = '', ...rest } = props;

    const serviceContext = useContext(ServiceContext);

    const [{ data, status }] = useGeneralInformationData({
        context: serviceContext,
        query: undefined,
    });

    const { therapeuticAreas = [], diseaseAreas = [], studyPhases = [] } = data || {};

    const onSubmit = async (
        valuesDelta: Partial<StudyAttributesEntity>,
        values: StudyAttributesEntity,
        { setSubmitting, resetForm }: FormikHelpers<StudyAttributesEntity>
    ) => {
        await onSave(valuesDelta);
        setSubmitting(false);
        resetForm({ values });
    };

    if (loading || (status == 'loading' && data == null)) {
        return <SpaceGeneralInformationSkeleton />;
    }

    return (
        <div
            ref={ref}
            {...rest}
            className={`
                ${styles.form}
                ${className}
            `}
            data-automation-id="studySpaceGeneralInformation"
        >
            <SpaceGeneralInformationForm
                {...{
                    readOnly,
                    studyAttributes,
                    onSubmit,
                    therapeuticAreas,
                    diseaseAreas,
                    studyPhases,
                }}
            />
        </div>
    );
});

export default SpaceGeneralInformation;
