import {
    Activity,
    ActivityModification,
    AssignAmConfigPayload,
    CommandResponse,
    RevisionedCommandResponse,
    toActivity,
} from '@faro/study-designer-model';
import { cqrs, setPathParameters } from '@faro/http-clients';
import axios from 'axios';
import { ScheduleOfActivitiesQueryParameter, StudyPathParameter } from '../parameter';

export type ActivityPathParameters = StudyPathParameter;
export type ActivityQueryParameters = ScheduleOfActivitiesQueryParameter & {
    /**
     * Filters the results matching the given classes
     * This filter is mutually exclusive with {@link #assessmentMeasurementScales}
     */
    readonly includedAssessmentMeasurementClasses?: string;

    /**
     * A collection of assessment/measurement classes that should be excluded from the result set.
     */
    readonly excludedAssessmentMeasurementClasses?: string;

    /**
     * Filters the results matching the given scales
     * This filter is mutually exclusive with {@link #includedAssessmentMeasurementClasses}
     */
    readonly assessmentMeasurementScales?: string;
};

/**
 * Client responsible for accessing the backend entity services
 */
export const activityClient = {
    ...cqrs<Activity, ActivityPathParameters>(
        '/api/study-designer/studies/:studyId/activities',
        toActivity
    ).writableEntityClient<ActivityQueryParameters>(),

    create: cqrs<ActivityModification, ActivityPathParameters>(
        '/api/study-designer/studies/:studyId/activities',
        toActivity
    ).create(),

    move(
        request: ActivityPathParameters & {
            id: string;
            placementType: 'within' | 'before' | 'after';
            relativeEntityId: string;
        }
    ): Promise<RevisionedCommandResponse<CommandResponse>> {
        return axios
            .post(
                setPathParameters('/api/study-designer/studies/:studyId/activities/:id/actions/move', request as any),
                request
            )
            .then(response => response.data);
    },

    assignConfig(
        request: AssignAmConfigPayload & ActivityPathParameters & { activityId: string }
    ): Promise<RevisionedCommandResponse<CommandResponse>> {
        return axios
            .post(
                setPathParameters(
                    '/api/study-designer/studies/:studyId/activities/:activityId/actions/assign-config',
                    request as any
                ),
                request
            )
            .then(response => response.data);
    },
    clone: cqrs<Activity, ActivityPathParameters>(
        '/api/study-designer/studies/:studyId/activities',
        toActivity
    ).clone(),
    removeTimepoints(
        request: ActivityPathParameters & { activityId: string }
    ): Promise<RevisionedCommandResponse<CommandResponse>> {
        return axios
            .post(
                setPathParameters(
                    '/api/study-designer/studies/:studyId/activities/:activityId/actions/remove-timepoints',
                    request as any
                ),
                request
            )
            .then(response => response.data);
    },
};
