import { Epoch, toEpoch } from '@faro/study-designer-model';
import { cqrs } from '@faro/http-clients';
import { StudyDesignQueryParameter, StudyPathParameter } from '../parameter';

export type EpochPathParameters = StudyPathParameter;
export type EpochQueryParameters = StudyDesignQueryParameter;

export const epochClient = cqrs<Epoch, EpochPathParameters>(
    '/api/study-designer/studies/:studyId/epochs',
    toEpoch
).writableEntityClient<EpochQueryParameters>();
