import { cqrs } from '@faro/http-clients';
import { BiomedicalNamedObject, toRequiredBiomedicalNamedObject } from '@faro/study-designer-model';

/**
 * Client responsible for managing Disease Area
 */
export const diseaseAreaClient = cqrs<BiomedicalNamedObject>(
    '/api/study-designer/enumerated-values/disease-areas',
    toRequiredBiomedicalNamedObject
).listableEntityClient();
