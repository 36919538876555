import React, { createRef } from 'react';
import { FormIcon, TableIcon } from '@faro/icons';

export interface Section {
    id: string;
    ref: React.RefObject<HTMLDivElement>;
    label: string;
    icon: React.ReactElement;
}

export const spacePageSectionsData: Section[] = [
    {
        id: 'general-information',
        ref: createRef<HTMLDivElement>(),
        label: 'General Information',
        icon: <FormIcon />,
    },
    {
        id: 'study-definitions',
        ref: createRef<HTMLDivElement>(),
        label: 'Study Definitions',
        icon: <TableIcon />,
    },
];
