import type { DefaultError, QueryKey } from '@tanstack/query-core';
import {
    QueryClient,
    useQuery,
    useQueryClient,
    UseQueryOptions,
    UseQueryResult,
    WithRequired,
} from '@tanstack/react-query';
import { useEffect } from 'react';
import { filter } from 'rxjs/operators';
import { ChangeEvent } from '@faro/realtime-services';
import { Observable } from 'rxjs';

export type UseRealtimeQueryOptions<
    TQueryFnData = unknown,
    TError = DefaultError,
    TData = TQueryFnData,
    TQueryKey extends QueryKey = QueryKey,
    TEvent extends ChangeEvent<any, any> = ChangeEvent<any, any>,
> = WithRequired<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'> & {
    events: Observable<TEvent[]>;
    refetchWhen?: (event: TEvent, state: TQueryFnData | undefined) => boolean;
};

export type UseRealtimeQueryResult<TData = unknown, TError = DefaultError> = UseQueryResult<TData, TError> & {
    /**
     * Use this to optimistically update a query result when making mutations
     * Should only be used within useRealtimeMutation onMutate callback option
     * @example
     * ```
     * useRealtimeMutation({
     *   onMutate: () => {
     *     return myOtherQuery.setData(optimisticUpdate);
     *   }
     * })
     * ```
     * @param data
     */
    setData: (data: TData) => OptimisticUpdateContext;

    /**
     * Cancels in-flight data read-requests
     */
    cancel(): void;
};

export type OptimisticUpdateContext = {
    /**
     * Called in useRealtimeMutation on error to rollback optimistic updates
     */
    rollback: () => void;
};

/**
 * Custom react hook used to fetch data that should automatically refetch when the queried data changes
 *
 * @param config The query configuration
 * @param localQueryClient Optional query client override
 */
export function useRealtimeQuery<
    TQueryFnData = unknown,
    TError = DefaultError,
    TData = TQueryFnData,
    TQueryKey extends QueryKey = QueryKey,
>(
    config: UseRealtimeQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    localQueryClient?: QueryClient
): UseRealtimeQueryResult<TData, TError> {
    const { events, refetchWhen, ...rest } = config;

    const contextQueryClient = useQueryClient();
    const queryClient = localQueryClient ?? contextQueryClient;

    const cancel = () => {
        queryClient.cancelQueries({ queryKey: config.queryKey });
    };

    // Function used to optimistically update query data
    const setData = (value: TData) => {
        const rollbackData = queryClient.getQueryData(config.queryKey);

        // Cancel in-flight read requests to prevent stale data from rendering
        cancel();

        // Optimistically update the query data
        queryClient.setQueryData<TData>(config.queryKey, value);

        const rollback = () => {
            queryClient.setQueryData(config.queryKey, rollbackData);
        };

        return { rollback };
    };

    useEffect(() => {
        if (refetchWhen == null) {
            return;
        }

        const subscription = events
            .pipe(
                filter(events => {
                    const state = queryClient.getQueryData<TQueryFnData>(config.queryKey);
                    return events.some(event => refetchWhen?.(event, state));
                })
            )
            .subscribe(() => {
                queryClient.invalidateQueries({ queryKey: config.queryKey });
            });
        return () => {
            subscription.unsubscribe();
        };
    }, [refetchWhen]);

    const queryResult = useQuery(
        {
            staleTime: Infinity,
            // Covered by change event listener running on interval
            refetchIntervalInBackground: false,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            refetchOnMount: 'always',
            ...rest,
        },
        queryClient
    );

    const realtimeQueryResult = {
        ...queryResult,
        setData,
        cancel,
    };

    return realtimeQueryResult;
}
