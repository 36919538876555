import { cqrs } from '@faro/http-clients';
import { TextSnippet, toTextSnippet } from '@faro/study-designer-model';
import axios from 'axios';
import { setPathParameters } from '@faro/http-clients';
import { TextSnippetQueryParameters } from './';

export type TextSnippetSearchTarget = 'byContent' | 'byName';

export const textSnippetClient = {
    ...cqrs<TextSnippet>(
        '/api/study-designer/text-snippets',
        toTextSnippet
    ).readableEntityClient<TextSnippetQueryParameters>(),

    listPaginated: cqrs<TextSnippet>(
        '/api/study-designer/text-snippets',
        toTextSnippet
    ).listPaginated<TextSnippetQueryParameters>(),

    create: (entity: Omit<TextSnippet, 'id'>): Promise<TextSnippet> =>
        axios
            .post<TextSnippet>('/api/study-designer/text-snippets', entity)
            .then(response => toTextSnippet(response.data)),

    update: (entity: TextSnippet): Promise<TextSnippet> =>
        axios
            .put<TextSnippet>(setPathParameters('/api/study-designer/text-snippets' + '/:id', entity as any), entity)
            .then(response => toTextSnippet(response.data)),

    delete: (entity: TextSnippet): Promise<void> =>
        axios.delete(setPathParameters('/api/study-designer/text-snippets' + '/:id', entity as any)),

    count: (searchPattern: string): Promise<number> =>
        axios
            .get<{ count: number }>('/api/study-designer/text-snippets/actions/count', {
                params: { searchPattern },
            })
            .then(response => {
                return response.data?.count;
            }),
};
