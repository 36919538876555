import { StudyStatusType } from '@faro/study-designer-model';
import React, { ComponentPropsWithoutRef, ComponentPropsWithRef, forwardRef, useRef, useState } from 'react';
import { composeRefs, FaroAvatar, FaroButton, FaroTable } from '@faro/design-system';
import { useStudySpacePermissions } from '../../../package/react-hooks/useStudySpacePermissions';
import styles from './StudyDefinitionTableRow.module.scss';
import StudyStatusListBox from '../../StudyStatusListBox/StudyStatusListBox';
import StudyDefinitionMenu from '../StudyDefinitionMenu/StudyDefinitionMenu';
import { StudyDefinitionTableRow } from '../StudyDefinitionTable.types';
import { Link, LinkProps } from 'react-router-dom';
import { useAnalytics, AnalyticsEventKeys, ProductTypes } from '@faro/app-context';

function getFormattedDateTime(date: Date): string {
    return date.toLocaleTimeString('en-us', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        timeZoneName: 'short',
    });
}
export interface StudyDefinitionTableRowCallbacks {
    onRowClick: (row: StudyDefinitionTableRow) => void;
    onStatusTypeUpdate: (row: StudyDefinitionTableRow, type: StudyStatusType) => Promise<any> | void;
    onExport: (row: StudyDefinitionTableRow) => Promise<any> | void;
    onClone: (row: StudyDefinitionTableRow) => Promise<any> | void;
    onRename?: (row: StudyDefinitionTableRow) => Promise<any> | void;
    onMove: (row: StudyDefinitionTableRow, toSpaceId: string) => Promise<any> | void;
    onDelete: (row: StudyDefinitionTableRow) => Promise<any> | void;
    cloneDisabled?: boolean;
}

export interface StudyDefinitionRowProps extends ComponentPropsWithRef<'tr'>, StudyDefinitionTableRowCallbacks {
    row: StudyDefinitionTableRow;
    onRowClick: (row: StudyDefinitionTableRow) => void;
    index: number;
}

export const StudyDefinitionRow = forwardRef<HTMLTableRowElement, StudyDefinitionRowProps>((props, ref) => {
    const {
        row,
        onRowClick,
        onStatusTypeUpdate,
        onExport,
        onClone,
        onRename,
        onMove,
        onDelete,
        cloneDisabled = false,
        className = '',
        index,
        ...rest
    } = props;

    const { trackEvent } = useAnalytics();

    const rowRef = useRef<HTMLTableRowElement | null>(null);

    const mergedRef = composeRefs(ref, rowRef);
    const studyDefinitionDescriptionRef = useRef<HTMLTableDataCellElement>(null);
    const permissions = useStudySpacePermissions();

    const [rowIsActive, setRowIsActive] = useState(false);

    const handleRowClick = () => {
        onRowClick?.(row);
    };

    const handleRowMouseDown = (event: React.MouseEvent<HTMLTableRowElement>) => {
        // Only trigger for row or study definition description
        if (event.target === rowRef.current || event.target === studyDefinitionDescriptionRef.current) {
            setRowIsActive(true);
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLTableRowElement>) => {
        if (event.target === event.currentTarget && (event.code === 'Enter' || event.code === 'Space')) {
            setRowIsActive(true);
            onRowClick?.(row);
            event.preventDefault();
        }
    };

    return (
        <>
            <tr
                tabIndex={0}
                onKeyDown={handleKeyDown}
                onKeyUp={() => setRowIsActive(false)}
                onMouseDown={handleRowMouseDown}
                onMouseUp={() => setRowIsActive(false)}
                onMouseLeave={() => setRowIsActive(false)}
                {...rest}
                ref={mergedRef}
                className={`
                    ${styles.row}
                    ${row.isDeleted ? styles.deleted : ''}
                    ${rowIsActive ? styles.activeRow : ''}                
                    ${className}
                `}
            >
                <FaroTable.Cell
                    ref={studyDefinitionDescriptionRef}
                    data-automation-id="title"
                    className={styles.titleCell}
                    onClick={handleRowClick}
                >
                    <Link to={`/studies/${row.id}`}>
                        <div className={styles.title} data-testid={`study-definition-title-${index}`}>
                            {row.name || 'Untitled'}
                        </div>
                    </Link>
                </FaroTable.Cell>
                <FaroTable.Cell data-automation-id="status" data-intercom-target="Change Study Definition Status">
                    {row.isDeleted ? (
                        <FaroButton
                            size="small"
                            className={`
                                ${styles.deletedButton}
                                `}
                            tabIndex={-1}
                        >
                            Deleted
                        </FaroButton>
                    ) : (
                        <StudyStatusListBox
                            readOnly={!permissions.editor && !permissions.studyDefinitionAdmin}
                            studyDefinitionId={row.id}
                            selectedStatusType={row.status.type}
                            onStatusTypeUpdate={(_, type) => onStatusTypeUpdate(row, type)}
                        />
                    )}
                </FaroTable.Cell>
                <FaroTable.Cell
                    data-automation-id="origin"
                    className={styles.originCell}
                    onClick={row.origin == null ? handleRowClick : undefined}
                >
                    {row.origin != null ? (
                        <ConditionalLink
                            shouldLink={!row.origin.isDeleted}
                            linkProps={{
                                to: `/studies/${row.origin.id}`,
                            }}
                            onClick={() =>
                                trackEvent(AnalyticsEventKeys.navigateToStudyDefinitionOriginLink, {
                                    product: ProductTypes.studySpace,
                                })
                            }
                            data-intercom-target="Study Definition Origin Link"
                        >
                            <div className={styles.origin}>{row.originLabel}</div>
                        </ConditionalLink>
                    ) : (
                        <></>
                    )}
                </FaroTable.Cell>
                <FaroTable.Cell className={styles.lastUpdatedCell} onClick={handleRowClick}>
                    {getFormattedDateTime(new Date(row.updated))}
                </FaroTable.Cell>
                <FaroTable.Cell onClick={handleRowClick}>
                    {row.lastUpdatedBy != null && <FaroAvatar user={row.lastUpdatedBy} />}
                </FaroTable.Cell>
                <FaroTable.Cell data-automation-id="rowControls" data-intercom-target="Study Definition Row Controls">
                    {row.isDeleted ? null : (
                        <StudyDefinitionMenu
                            studyDefinition={row}
                            onExport={onExport}
                            onClone={onClone}
                            onRename={onRename}
                            onMove={onMove}
                            onDelete={onDelete}
                            cloneDisabled={cloneDisabled}
                            index={index}
                        />
                    )}
                </FaroTable.Cell>
            </tr>
        </>
    );
});
interface ConditionalLinkProps extends ComponentPropsWithoutRef<any> {
    shouldLink: boolean;
    linkProps: LinkProps;
}
const ConditionalLink = ({ children, shouldLink, linkProps, ...rest }: ConditionalLinkProps) =>
    shouldLink ? (
        <Link {...linkProps} {...rest}>
            {children}
        </Link>
    ) : (
        <>{children}</>
    );
