import { Formik } from 'formik';
import { FaroButton, FaroDialog, FaroFormField, Option } from '@faro/design-system';
import { FaroSelectField } from '@faro/forms';
import React, { ReactNode } from 'react';
import { StudyDefinitionExportFormat } from '../service/StudyDefinitionExportService/StudyDefinitionExportService';
import { StudyDefinitionExportFormDisplayText } from './StudyDefinitionExportFormDisplayText/StudyDefinitionExportFormDisplayText';

interface StudyDefinitionExportFormProps {
    formatOptions: Option<StudyDefinitionExportFormat>[];
    initialValues: StudyDefinitionExportFormValues;
    isSubStudy: boolean;

    onSubmit(values: StudyDefinitionExportFormValues): Promise<any>;

    onCancel(): void;
}

export interface StudyDefinitionExportFormValues {
    format: StudyDefinitionExportFormat;
    isSubStudy: boolean;
}

/**
 * Get the header text based on whether a study definition or sub-study definition is being exported.
 *
 * @param isSubStudy Determine if this is study definition or sub-study definition being exported.
 * @returns ReactNode containing the appropriate header text to display.
 */
function getHeader(isSubStudy: boolean): ReactNode {
    const baseTitle = 'Export Study Definition';

    return isSubStudy ? (
        <StudyDefinitionExportFormDisplayText
            text="Export Substudy Definition"
            description="Only the selected substudy definition is exported"
        />
    ) : (
        baseTitle
    );
}

export function StudyDefinitionExportForm(props: StudyDefinitionExportFormProps): JSX.Element {
    const { formatOptions, initialValues, isSubStudy, onSubmit, onCancel } = props;

    const header = getHeader(isSubStudy);

    return (
        <FaroDialog>
            <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit}>
                {formikProps => {
                    const { submitForm, isSubmitting } = formikProps;
                    return (
                        <>
                            <FaroDialog.Header>{header}</FaroDialog.Header>
                            <FaroDialog.Body>
                                <FaroFormField>
                                    <FaroSelectField
                                        name="format"
                                        options={formatOptions}
                                        clearable={false}
                                        fullWidth
                                        data-automation-id="exportSelect"
                                        data-testid="exportSelect"
                                    />
                                </FaroFormField>
                            </FaroDialog.Body>
                            <FaroDialog.Controls>
                                <FaroButton
                                    onClick={() => {
                                        onCancel();
                                    }}
                                    appearance="inline"
                                    disabled={isSubmitting}
                                >
                                    Cancel
                                </FaroButton>
                                <FaroButton
                                    color="primary"
                                    onClick={async () => {
                                        await submitForm();
                                    }}
                                    loading={isSubmitting}
                                    data-testid="exportButton"
                                >
                                    Export
                                </FaroButton>
                            </FaroDialog.Controls>
                        </>
                    );
                }}
            </Formik>
        </FaroDialog>
    );
}
