import styles from './SpaceGeneralInformationTitleForm.module.scss';
import React from 'react';
import { FaroSkeleton } from '@faro/design-system';

export const SpaceGeneralInformationTitleFormSkeleton = (): JSX.Element => {
    return (
        <h1 className={styles.studyTitle}>
            <FaroSkeleton height="36px" />
        </h1>
    );
};
