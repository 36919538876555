import { ActivityGroup, toActivityGroup } from '@faro/study-designer-model';
import { cqrs } from '@faro/http-clients';
import { ScheduleOfActivitiesQueryParameter, StudyPathParameter } from '../parameter';

export type ActivityGroupPathParameters = StudyPathParameter;
export type ActivityGroupQueryParameters = ScheduleOfActivitiesQueryParameter;

/**
 * Client responsible for accessing the backend entity services
 */
export const activityGroupClient = cqrs<ActivityGroup, ActivityGroupPathParameters>(
    '/api/study-designer/studies/:studyId/activity-groups',
    toActivityGroup
).writableEntityClient<ActivityGroupQueryParameters>();
