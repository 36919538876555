import { ValidateFileSelectionResult } from './ValidateFileSelectionResult';

/**
 * Validate the selected file(s) supplied by the user:
 * * The user must supply at least one file
 * * If `allowMultiple` is `false`, only one file can be supplied
 * * All files must have one of the allowed file `extensions`
 *
 * @param allowMultiple `boolean` When `true`, the user can select multiple files.  Otherwise, they can only select a
 * single file.
 * @param extensions `string[]` a collection of acceptable file extensions.
 * @param fileList `FileList` containing the `File`s supplied by the user.  May be `null` or `undefined` depending on
 * the source (either a `DragEvent`'s `DataTransfer` or an `input`'s `target`).
 * @retuns `SelectedFileValidationResult` containing the selected `File`s if validation was successful or an error
 * message describing the validation failure
 * @see https://developer.mozilla.org/en-US/docs/Web/API/FileList
 * @see https://developer.mozilla.org/en-US/docs/Web/API/File
 */
export function validateFileSelection(
    allowMultiple: boolean,
    extensions: string[],
    fileList?: FileList | null
): ValidateFileSelectionResult {
    if (fileList == null || fileList.length === 0) {
        return { files: [], errorMessage: 'No study definition file(s) provided' };
    }

    if (!allowMultiple && fileList.length > 1) {
        return { files: [], errorMessage: 'Only one definition may be imported at a time.' };
    }

    const files = Array.from(fileList);

    const allSelectedFileExtensionsAreAllowed = files
        .map(file => file.name.split('.'))
        .every(fileNameParts => {
            // Handle if file name is something like foo.bar.imp.json
            const extension = `.${fileNameParts[fileNameParts.length - 1]}`;

            return extensions.includes(extension);
        });

    if (!allSelectedFileExtensionsAreAllowed) {
        return {
            files: [],
            errorMessage: `Only definitions with the following extension${
                extensions.length > 1 ? 's' : ''
            } may be imported: ${extensions.join(', ')}`,
        };
    }

    return { files };
}
