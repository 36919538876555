import { RealtimeDataHookFactory } from '../package/realtime-query';
import { ChangeEvent } from '@faro/realtime-services';
import { useContext } from 'react';
import { ServiceContext } from './ServiceContext';

type StudyScopeParameters = { studyId: string };

export const studyDataHookFactory = new RealtimeDataHookFactory<ChangeEvent, StudyScopeParameters>({
    scopeName: 'study',
    getScopeId: (params: StudyScopeParameters) => params.studyId,
    getChangeEventService: () => {
        const { changeEventService } = useContext(ServiceContext);
        return changeEventService;
    },
});
