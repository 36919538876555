import React, { Context } from 'react';

/**
 * This context functions as our service registry, allowing consuming components to access services.
 */
export interface AppContextValue {
    /**
     * Produces a promise error response handler that instructs the application to display the
     * error in the manner of its choosing.
     * Today this will result in a toast error message being displayed.
     *
     * @param message The high-level error message. e.g. "Failed to update schedule"
     */
    showErrorHandler(message: string): (error: any) => Promise<never>;

    /**
     * Shows an error message as a toast notification
     * @param message The error message
     * @param error The error source
     */
    showErrorNotification(message: string, error?: any): void;
}

const AppContext: Context<AppContextValue> = React.createContext(<AppContextValue>{});

export default AppContext;
