import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { cacheForeverQueryOptions } from '../package/react-query';
import { FeatureFlag } from '@faro/study-designer-model';
import { useServiceContext } from '../service';

export function useFeatureFlagsQuery(): UseQueryResult<FeatureFlag[]> {
    const { featureFlagService } = useServiceContext();
    return useQuery({
        ...cacheForeverQueryOptions,
        queryKey: ['featureFlags'],
        queryFn: () => featureFlagService.loadFeatureFlags(),
    });
}
