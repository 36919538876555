import {
    StudyContentDocumentRequest,
    SystemConfiguration,
    toSystemConfiguration,
} from '@faro/document-management-model';
import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { toLower } from 'lodash';

interface StudyContentDocumentQuery {
    timeZone?: string;
}
/**
 * Implementations of this interface are responsible for working with comments API
 */
export interface DocumentManagementService {
    /**
     * Export current study as Docx
     * @param studyContentDocument The study document info request
     * @param studyContentDocumentQuery The study document info query
     */
    exportDocxDocument(
        studyContentDocument?: StudyContentDocumentRequest,
        studyContentDocumentQuery?: StudyContentDocumentQuery
    ): Promise<AxiosResponse>;

    /**
     * Fetch document management configuration.
     * Parser uses this configuration to render the document HTML
     */
    getConfiguration(): Promise<SystemConfiguration>;
}

export class DefaultDocumentManagementService implements DocumentManagementService {
    exportDocxDocument(
        studyContentDocument: StudyContentDocumentRequest,
        studyContentDocumentQuery?: StudyContentDocumentQuery
    ): Promise<AxiosResponse<Blob>> {
        return axios
            .post(`/api/document-management/documents/export/docx/file`, studyContentDocument, {
                responseType: 'blob',
                params: studyContentDocumentQuery,
            })
            .catch(async (error: AxiosError) => {
                // NOTE: since responseType is 'blob' above, any thrown AxiosError also has a 'blob' response payload
                // This requires conversion to a JSON payload if we want Problem detail support.
                if (
                    !error.isAxiosError ||
                    !toLower(error.response?.headers['content-type'] || '').startsWith('application/problem+json') ||
                    error.config?.responseType !== 'blob'
                ) {
                    throw error;
                }

                const responseJson = JSON.parse(await (error.response?.data as Blob).text());
                throw new AxiosError(
                    error.message,
                    error.code,
                    { ...error.config, responseType: 'json' } as InternalAxiosRequestConfig,
                    error.request,
                    { ...error.response, data: responseJson } as AxiosResponse
                );
            });
    }

    getConfiguration(): Promise<SystemConfiguration> {
        return axios.get('/api/document-management/system/config').then(response => {
            return toSystemConfiguration(response.data);
        });
    }
}
