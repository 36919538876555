import { ActivityTimeCost, toActivityTimeCost } from '@faro/study-designer-model';
import { cqrs } from '@faro/http-clients';
import { ScheduleOfActivitiesQueryParameter, StudyPathParameter, VariantQueryParameter } from '../parameter';

export type ActivityTimeCostPathParameters = StudyPathParameter;
export type ActivityTimeCostQueryParameters = ScheduleOfActivitiesQueryParameter & VariantQueryParameter;

/**
 * Client responsible for accessing {@link ActivityTimeCost}
 */
export const activityTimeCostClient = cqrs<ActivityTimeCost, ActivityTimeCostPathParameters>(
    '/api/study-designer/studies/:studyId/activity-time-costs',
    toActivityTimeCost
).listableEntityClient<ActivityTimeCostQueryParameters>();
