import React, { ComponentPropsWithRef, forwardRef, useState } from 'react';
import { FaroTable } from '@faro/design-system';
import styles from './StudyDefinitionTable.module.scss';
import { StudyDefinitionTableSkeleton } from './StudyDefinitionTable.skeleton';
import {
    StudyDefinitionRow,
    StudyDefinitionTableRowCallbacks,
} from './StudyDefinitionTableRow/StudyDefinitionTableRow';
import { StudyDefinitionTableRow } from './StudyDefinitionTable.types';
import useSortableTable from '../../package/react-hooks/useSortableTable';

export interface StudyDefinitionTableProps extends ComponentPropsWithRef<'table'>, StudyDefinitionTableRowCallbacks {
    rows: StudyDefinitionTableRow[];
}

const StudyDefinitionTable = forwardRef<HTMLTableElement, StudyDefinitionTableProps>((props, ref) => {
    const {
        rows,
        onRowClick,
        onStatusTypeUpdate,
        onExport,
        onClone,
        onRename,
        onMove,
        onDelete,
        className = '',
        ...rest
    } = props;

    const { items: sortedRows, sortDataBy, sortIconBy } = useSortableTable(rows);

    const [cloning, setCloning] = useState(false);

    const onCloneInternal = async (row: StudyDefinitionTableRow) => {
        setCloning(true);
        try {
            await onClone?.(row);
        } finally {
            setCloning(false);
        }
    };

    return (
        <FaroTable
            {...rest}
            ref={ref}
            className={`
                ${styles.table}
                ${className}
            `}
            appearance="bordered-minimally-interactive"
            data-automation-id="studySpaceStudyDefinitionsTable"
            data-testid="studySpaceStudyDefinitionsTable"
        >
            <thead>
                <tr>
                    <th onClick={() => sortDataBy(['name'])} data-testid="studyDefinitionTitleButton">
                        <div className={styles.headerContent}>Study Definition Title {sortIconBy('name')}</div>
                    </th>
                    <th onClick={() => sortDataBy(['status.type', 'isDeleted'])}>
                        <div className={styles.headerContent}>Status {sortIconBy('status.type')}</div>
                    </th>
                    <th onClick={() => sortDataBy(['originLabel'])}>
                        <div className={styles.headerContent}>Origin {sortIconBy('originLabel')}</div>
                    </th>
                    <th onClick={() => sortDataBy(['updated'])}>
                        <div className={styles.headerContent}>Last Updated {sortIconBy('updated')}</div>
                    </th>
                    <th></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {sortedRows.map((row: StudyDefinitionTableRow, index: number) => (
                    <StudyDefinitionRow
                        key={row.id}
                        index={index}
                        row={row}
                        onRowClick={onRowClick}
                        onStatusTypeUpdate={onStatusTypeUpdate}
                        onExport={onExport}
                        onClone={onCloneInternal}
                        onRename={onRename}
                        onMove={onMove}
                        onDelete={onDelete}
                        cloneDisabled={cloning}
                        data-automation-role="studyDefinitionRow"
                        data-intercom-target="Select Study Definition"
                        data-testid={`studyDefinitionRow-${index}`}
                    />
                ))}
            </tbody>
        </FaroTable>
    );
});

const Default = {
    ...StudyDefinitionTable,
    Skeleton: StudyDefinitionTableSkeleton,
} as React.ForwardRefExoticComponent<StudyDefinitionTableProps & React.RefAttributes<HTMLTableElement>> & {
    Skeleton: typeof StudyDefinitionTableSkeleton;
};

export default Default;
