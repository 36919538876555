import { StudySpace } from '@faro/study-space-service-model';
import { cqrs } from '@faro/http-clients';

const clientMethodFactory = cqrs<StudySpace>('/api/study-designer/study-spaces');
class StudySpaceClient {
    get = clientMethodFactory.get();
    list = clientMethodFactory.list();
}

export const studySpaceClient = new StudySpaceClient();
