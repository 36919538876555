import { StudyAttributes, StudyAttributesEntity } from '@faro/study-space-service-model';
import { StudyPhase } from '@faro/study-designer-model';

export function toStudyAttributesEntity(attributes: StudyAttributes): StudyAttributesEntity {
    return {
        program: attributes.program,
        type: attributes.type,
        diseaseArea: attributes.diseaseArea,
        otherDiseaseAreaIds: attributes.otherDiseaseAreaIds,
        therapeuticArea: attributes.therapeuticArea,
        protocolTitle: attributes.protocolTitle,
        protocolTitleShort: attributes.protocolTitleShort,
        protocolTitleAcronym: attributes.protocolTitleAcronym,
        phaseIds: attributes.phases?.map((p: StudyPhase) => p.id) || [],
        protocolNumber: attributes.protocolNumber,
        contractResearchOrganizationVendor: attributes.contractResearchOrganizationVendor,
        centralLab: attributes.centralLab,
        electronicDataCaptureVendor: attributes.electronicDataCaptureVendor,
        additionalInformation: attributes.additionalInformation,
        studyRegistryId: attributes.studyRegistryId,
    };
}
