import { Endpoint, Estimand, Objective, StudyEntityModification, toObjective } from '@faro/study-designer-model';
import { cqrs } from '@faro/http-clients';
import { StudyPathParameter } from '../parameter';

export type ObjectivePathParameters = StudyPathParameter;

const factory = cqrs<Objective, ObjectivePathParameters>(
    '/api/study-designer/studies/:studyId/objectives',
    toObjective
);

export const objectiveClient = {
    ...factory.writableEntityClient(),
    clone: factory.clone(),
    move: factory.move(),
    modify: factory.modify<StudyEntityModification<Objective | Estimand | Endpoint>>(),
};
