import { AuditEvent, toAuditEvent } from '@faro/study-designer-model';
import axios from 'axios';

export type TextSnippetSearchTarget = 'byContent' | 'byName';

export const auditEventClient = {
    create: (entity: Omit<AuditEvent, 'id'>): Promise<AuditEvent> =>
        axios
            .post<AuditEvent>('/api/study-designer/audit-events', entity)
            .then(response => toAuditEvent(response.data)),
};
