import { Schema, toSchema } from '@faro/study-designer-model';
import { cqrs } from '@faro/http-clients';
import { StudyDesignQueryParameter, StudyPathParameter } from '../parameter';

export type SchemaPathParameters = StudyPathParameter;
export type SchemaQueryParameters = StudyDesignQueryParameter;

/**
 * Client responsible for accessing the backend entity services
 */

const cqrsClientFactory = cqrs<Schema, SchemaPathParameters>('/api/study-designer/studies/:studyId/schemas', toSchema);

export const schemaClient = {
    ...cqrsClientFactory.writableEntityClient(),
};
