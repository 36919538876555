import { studyDataHookFactory } from '../studyDataHookFactory';
import { ActivityScheduleRule, StudyEntityType } from '@faro/study-designer-model';
import { activityScheduleRuleClient } from './ActivityScheduleRuleClient';

const factory = studyDataHookFactory.entity<ActivityScheduleRule>(StudyEntityType.ActivityScheduleRule);

export const useActivityScheduleRules = factory.list(activityScheduleRuleClient.list);
export const useInsertActivityScheduleRules = factory.mutate(activityScheduleRuleClient.createBatch);
export const useUpdateActivityScheduleRules = factory.mutate(activityScheduleRuleClient.update);
export const useDeleteActivityScheduleRule = factory.mutate(activityScheduleRuleClient.delete);
