import { FaroOverlay, FaroOverlayPlacement, FaroOverlayProps, FaroTooltip, isBlank } from '@faro/design-system';
import { reduce } from 'faro-common-utils';
import { debounce, isEqual } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import styles from './TooltipPlugin.module.scss';

const defaultDelayInMilliseconds = 750;
const mouseMoveDebounceTime = 100;
const scrollDebounceTime = 500;
const mutationDebounceTime = 2000;
const ignoreAttribute = 'data-truncation-tooltip-off';
const placementAttribute = 'data-truncation-tooltip-placement';
const invertedAttribute = 'data-truncation-tooltip-inverted';
const elementsRequiringIntrospection = ['button', 'label'];

export interface TruncationTooltipLauncherProps
    extends Pick<
        FaroOverlayProps,
        'placement' | 'allowedPlacements' | 'container' | 'strategy' | 'onOpenChange' | 'onOpen' | 'onClose'
    > {
    /**
     * The time to delay the tooltip opening in milliseconds
     *
     * @default 750
     */
    readonly delay?: number;
}

/**
 * Represents a mouse point
 */
interface Point {
    readonly x: number;
    readonly y: number;
}

/**
 * Reference to a setTimeout
 */
interface TimeoutRef {
    readonly element: HTMLElement;
    readonly timeout: any;
}

/**
 * Reference to an element that a tooltip will be rendered for
 */
interface ElementRef {
    readonly key: string;
    readonly element: HTMLElement;

    /**
     * In the case of button or label there may be multiple nested elements with truncation to show tooltips for
     */
    readonly textContents: string[];
}

/**
 * The current configuration state of the element
 */
interface ElementState {
    readonly ignore: boolean;
    readonly inverted: boolean;
    readonly placement?: FaroOverlayPlacement;
    readonly visible: boolean;
    readonly value: string;
}

/**
 * True if the given element has the attribute true until the top most parent
 * @param element The element to test
 */
const attributeTrueUntilTopMostParent = (element: HTMLElement | null, attribute: string): boolean => {
    let currentElement = element;
    let attributeTrueUntilTopMostParent = false;

    while (currentElement) {
        const hasAttribute = currentElement.getAttribute(attribute);

        if (hasAttribute === 'true') {
            attributeTrueUntilTopMostParent = true;
        } else if (hasAttribute === 'false') {
            attributeTrueUntilTopMostParent = false;
        }

        currentElement = currentElement.parentElement;
    }
    return attributeTrueUntilTopMostParent;
};

/**
 * Parses the current configuration state of the element
 * @param element The element to parse the state from
 */
function getElementState(element: HTMLElement): ElementState {
    const computedStyle = window.getComputedStyle(element);
    return {
        ignore:
            parseBooleanAttributeValue(element.getAttribute(ignoreAttribute)) ||
            attributeTrueUntilTopMostParent(element, ignoreAttribute),
        inverted:
            parseBooleanAttributeValue(element.getAttribute(invertedAttribute)) ||
            attributeTrueUntilTopMostParent(element, invertedAttribute),
        placement: (element.getAttribute(placementAttribute) as any) || undefined,
        visible:
            computedStyle.opacity !== '0' && computedStyle.display !== 'none' && computedStyle.visibility !== 'hidden',
        value: (element as any).value,
    };
}

/**
 * True if the given element has truncated content
 * @param element The element to test for truncated content
 * @param threshold The threshold (in pixels) of overflow required to pass before counting overflow as truncating overflow
 */
function hasTruncation(element: HTMLElement, threshold = 0): boolean {
    const overflow = Math.abs(element.offsetWidth - element.scrollWidth);
    return overflow > threshold;
}

/**
 * True if a tooltip should be shown for the given element
 * @param element The element to test
 */
function showTooltip(element: HTMLElement): boolean {
    return (
        hasTruncation(element) &&
        // Don't show tooltips when the element explicitly suppresses them
        !parseBooleanAttributeValue(element.getAttribute(ignoreAttribute)) &&
        // Don't show tooltips when the parent explicitly suppresses them
        !attributeTrueUntilTopMostParent(element, ignoreAttribute) &&
        // Only show tooltips for elements styled with ellipsis (not overflow hidden)
        window.getComputedStyle(element).getPropertyValue('text-overflow') === 'ellipsis'
    );
}

/**
 * Finds all descendant elements that should show a tooltip
 * @param element The root element
 */
function findDescendantElementsWithTooltips(element: HTMLElement): HTMLElement[] {
    return reduce(
        element,
        x => Array.from(x.children) as HTMLElement[],
        (tooltipElements, element) => {
            if (showTooltip(element)) {
                tooltipElements.push(element);
            }
            return tooltipElements;
        },
        [] as HTMLElement[]
    );
}

/**
 * HTML utility method that parses attribute values into their corresponding boolean value
 *
 * @example Attribute declarations that evaluate to true:
 * <div my-attribute="true"/>
 * <div my-attribute/>
 * <div my-attribute=""/>
 * Anything else will be evaluated to false.
 *
 * @param value The attribute value to parse.
 */
function parseBooleanAttributeValue(value: string | null): boolean {
    return value != null && (value === '' || value === 'true');
}

/**
 * @internal Function used to determine if changes have happened in the collection of element references
 * @param keyed Array of keyed items
 */
function checksum<T extends { key: string }>(keyed: T[]): string {
    return keyed.map(x => x.key).join(',');
}

/**
 * Component responsible for rendering tooltips when hovering elements in the application.
 * By default this plugin opens tooltips for truncated text.
 * Later, this could be expanded to open tooltips for other situations as well.
 *
 * To avoid opening a tooltip for truncated text, individual components can add the attribute
 * data-truncation-tooltip-off.
 * @example <div data-truncation-tooltip-off />
 *
 * To customize the placement of a truncation tooltip, add the data-truncation-tooltip-placement attribute
 * @example <div data-truncation-tooltip-placement="right" />
 *
 * TODO
 *  1. Add support for tooltip open/close animations
 *  2. Add support for keyboard-focused truncation tooltip opening
 */
function TooltipPlugin(props: TruncationTooltipLauncherProps): JSX.Element {
    const { delay = defaultDelayInMilliseconds, ...rest } = props;

    // Holds references to elements that tooltips should actively be rendered for
    const [overlayElementRefs, setOverlayElementRefs] = useState<ElementRef[]>([]);

    // Reference to the current delay timeout
    const timeoutRef = useRef<TimeoutRef | undefined>();

    // Using a ref here so that we don't need to call the useEffect when the tooltips change
    const overlayElementRefsRef = useRef<ElementRef[]>(overlayElementRefs);
    overlayElementRefsRef.current = overlayElementRefs;

    // This helps us fail-fast when tracking the element being hovered
    const previousElementRef = useRef<{ element: HTMLElement; state: ElementState } | null>(null);

    // Tracks the current mouse position
    const mousePositionRef = useRef<Point>({ x: -1, y: -1 });

    useEffect(() => {
        function checkForTruncatedTextUnderPoint(point: Point): void {
            const { x, y } = point;

            // The leaf-most element being moused over and all its ancestors
            const element = document.elementFromPoint(x, y) as HTMLElement;

            // Only re-process if the leaf element under the mouse has changed
            const elementState = (element != null ? getElementState(element) : undefined) as ElementState;
            if (
                previousElementRef.current?.element === element &&
                isEqual(previousElementRef.current?.state, elementState)
            ) {
                return;
            }
            previousElementRef.current = element != null ? { element, state: elementState } : null;

            const elementRefs = overlayElementRefsRef.current;

            // In the case of labels and buttons we need to introspect for truncated elements because
            // document.elementsFromPoint does not return elements nested within these element types
            const descendantTooltipElements = elementsRequiringIntrospection.includes(element?.tagName.toLowerCase())
                ? findDescendantElementsWithTooltips(element)
                : [];

            const elements = document.elementsFromPoint(x, y) as HTMLElement[];

            // If there are descendants requiring a tooltip, use the root element (button/label) as the element ref
            const closestTooltipElement =
                descendantTooltipElements.length > 0 ? element : elements.find(x => showTooltip(x));

            // We already are tracking this element so we can exit
            if (elementRefs.some(x => x.element === closestTooltipElement)) {
                return;
            }

            // Clear timeout if the hovered element changes during timeout
            if (timeoutRef.current != null && timeoutRef.current?.element !== closestTooltipElement) {
                clearTimeout(timeoutRef.current?.timeout);
                timeoutRef.current = undefined;
            }

            // Remove tooltips when the hovered element changes
            if (elements.length === 0 || closestTooltipElement == null) {
                setOverlayElementRefs([]);
                return;
            }

            // Close all tooltips that do not contain the current truncated element
            const activeElementRefs = elementRefs.filter(x => x.element.contains(closestTooltipElement));
            if (checksum(activeElementRefs) !== checksum(elementRefs)) {
                setOverlayElementRefs(activeElementRefs);
            }

            clearTimeout(timeoutRef.current?.timeout);
            // Start a timeout for showing a tooltip after the set delay
            const timeout = setTimeout(() => {
                // If the element had descendants requiring tooltips use those
                // otherwise use the referenced element's text content
                const textContents = (
                    descendantTooltipElements.length > 0
                        ? descendantTooltipElements.map(x => x.innerText || (x as any).value || '')
                        : [closestTooltipElement.innerText || (closestTooltipElement as any).value || '']
                ).filter(x => !isBlank(x));

                // Push the element to the rendering list so that we render a tooltip for it
                const elementRef: ElementRef = {
                    key: uuid(),
                    element: closestTooltipElement,
                    textContents,
                };
                setOverlayElementRefs([elementRef]);
            }, delay);

            // Track the current timeout for cancellation
            timeoutRef.current = {
                element: closestTooltipElement,
                timeout,
            };
        }

        const checkForTruncatedTextUnderPointDebounced = debounce((point: Point) => {
            checkForTruncatedTextUnderPoint(point);
        }, 50);

        function onMouseMove(event: MouseEvent): void {
            mousePositionRef.current = { x: event.clientX, y: event.clientY };
            checkForTruncatedTextUnderPointDebounced(event);
        }

        function onScroll(): void {
            checkForTruncatedTextUnderPointDebounced(mousePositionRef.current);
        }

        function onMutation(): void {
            checkForTruncatedTextUnderPointDebounced(mousePositionRef.current);
        }

        function onAnimationStart(): void {
            // TODO: have animations push out debounced calls but do not call because of an animation
            // checkForTruncatedTextUnderPointDebounced(mousePositionRef.current);
        }

        const onMouseMoveDebounced = debounce(onMouseMove, mouseMoveDebounceTime);
        const onScrollDebounced = debounce(onScroll, scrollDebounceTime);
        const onMutationDebounced = debounce(onMutation, mutationDebounceTime);

        // The mutation observer catches in-place content changes that happen without mouse motion.
        // An example is when the content beneath your mouse changes after a click
        const mutationObserver = new MutationObserver(onMutationDebounced);
        mutationObserver.observe(document.body, {
            // Listen to all DOM changes involving scroll dimensions
            childList: true,
            subtree: true,
            attributes: true,
            attributeFilter: ['scrollWidth', 'scrollHeight'],
        });

        document.body.addEventListener('mousemove', onMouseMoveDebounced, { passive: true });
        document.body.addEventListener('animationstart', onAnimationStart, { passive: true });
        window.addEventListener('scroll', onScrollDebounced, { passive: true, capture: true });

        return () => {
            mutationObserver.disconnect();
            document.body.removeEventListener('mousemove', onMouseMoveDebounced);
            document.body.removeEventListener('animationstart', onAnimationStart);
            window.removeEventListener('scroll', onScrollDebounced, { capture: true });
        };
    }, [delay]);

    return useMemo(
        () => (
            <>
                {overlayElementRefs.map(elementRef => {
                    const { key, element, textContents } = elementRef;

                    // If the placement attribute is not declared, use whatever the default tooltip placement is
                    const placement = (element.getAttribute(placementAttribute) as any) || undefined;
                    const inverted =
                        parseBooleanAttributeValue(element.getAttribute(invertedAttribute)) ||
                        attributeTrueUntilTopMostParent(element, invertedAttribute);

                    return (
                        <FaroOverlay
                            className={styles.overlay}
                            container={document.body}
                            {...rest}
                            key={key}
                            anchorElement={element}
                            placement={placement}
                            open
                        >
                            {textContents.length > 0 ? (
                                <FaroTooltip centerContent inverted={inverted}>
                                    {textContents.map((textContent, index) => (
                                        <div key={index}>{textContent}</div>
                                    ))}
                                </FaroTooltip>
                            ) : null}
                        </FaroOverlay>
                    );
                })}
            </>
        ),
        // Only re-render when the tooltip containing elements change
        [checksum(overlayElementRefs)]
    );
}

export default TooltipPlugin;
