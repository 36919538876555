import { cqrs } from '@faro/http-clients';
import { BiomedicalNamedObject, toRequiredBiomedicalNamedObject } from '@faro/study-designer-model';

/**
 * Client responsible for managing Therapeutic Area
 */
export const therapeuticAreaClient = cqrs<BiomedicalNamedObject>(
    '/api/study-designer/enumerated-values/therapeutic-areas',
    toRequiredBiomedicalNamedObject
).listableEntityClient();
