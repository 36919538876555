import { exportClient, ExportPathParameters, ExportQueryParameters } from './ExportClient';
import { download } from '../../support/download';
import { getFilenameFromContentDisposition } from '../../support/getFilenameFromContentDisposition';

export class ExportService {
    async exportJson(query: ExportPathParameters & Omit<ExportQueryParameters, 'download'>): Promise<void> {
        const { headers, data } =
            (await exportClient.exportJSON({
                ...query,
                download: true,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            })) || {};
        const fileName = getFilenameFromContentDisposition(headers['content-disposition'] || '', 'json');
        const dataAsString = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(data));
        download(dataAsString, fileName);
    }

    async exportFaroStandardJsonFormat(
        query: ExportPathParameters & Omit<ExportQueryParameters, 'download' | 'revision'>
    ): Promise<void> {
        const { headers, data } =
            (await exportClient.exportFaroStandardJsonFormat({
                ...query,
                download: true,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            })) || {};
        if ('errorMessage' in data) {
            throw new Error(data.errorMessage);
        }
        const fileName = getFilenameFromContentDisposition(headers['content-disposition'] || '', 'json');
        const dataAsString = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(data));
        download(dataAsString, fileName);
    }
}
