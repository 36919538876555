import { EpochGroup, toEpochGroup } from '@faro/study-designer-model';
import { cqrs } from '@faro/http-clients';
import { SchemaQueryParameter, StudyPathParameter } from '../parameter';

export type EpochGroupPathParameters = StudyPathParameter;
export type EpochGroupQueryParameters = SchemaQueryParameter;

/**
 * Client responsible for accessing the backend entity services
 */
export const epochGroupClient = cqrs<EpochGroup, EpochGroupPathParameters>(
    '/api/study-designer/studies/:studyId/epoch-groups',
    toEpochGroup
).writableEntityClient<EpochGroupQueryParameters>();
