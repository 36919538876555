import { ActivityFootnote, toActivityFootnote } from '@faro/study-designer-model';
import { cqrs } from '@faro/http-clients';
import { ScheduleOfActivitiesQueryParameter, StudyPathParameter, VariantQueryParameter } from '../parameter';

export type ActivityFootnotePathParameters = StudyPathParameter;
export type ActivityFootnoteQueryParameters = ScheduleOfActivitiesQueryParameter & VariantQueryParameter;

/**
 * Client responsible for accessing the backend entity services
 */
export const activityFootnoteClient = cqrs<ActivityFootnote, ActivityFootnotePathParameters>(
    '/api/study-designer/studies/:studyId/footnotes',
    toActivityFootnote
).writableEntityClient<ActivityFootnoteQueryParameters>();
