import { Intervention, toIntervention } from '@faro/study-designer-model';
import { cqrs } from '@faro/http-clients';
import { StudyPathParameter } from '../parameter';

export type InterventionPathParameters = StudyPathParameter;

export const interventionClient = cqrs<Intervention, InterventionPathParameters>(
    '/api/study-designer/studies/:studyId/interventions',
    toIntervention
).writableEntityClient();
