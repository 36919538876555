import { DoseUnit, toDoseUnit } from '@faro/study-designer-model';
import { cqrs } from '@faro/http-clients';

/**
 * Client responsible for managing intervention dose units
 */
export const doseUnitClient = cqrs<DoseUnit>(
    '/api/study-designer/interventions/doses/units',
    toDoseUnit
).listableEntityClient();
