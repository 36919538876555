import { cqrs } from '@faro/http-clients';
import {
    AssessmentMeasurementSystemOption,
    toAssessmentMeasurementSystemOption,
    toSystemOptionsByAssessmentMeasurementId,
    SystemOptionsByAssessmentMeasurementId,
} from '@faro/study-designer-model';
import { StudyPathParameter } from '../parameter';

export interface StudyAssessmentMeasurementSystemOptionsParameters extends StudyPathParameter {
    id: string;
}

export const studyAssessmentMeasurementSystemOptionsClient = {
    ...cqrs<AssessmentMeasurementSystemOption, StudyAssessmentMeasurementSystemOptionsParameters>(
        '/api/study-designer/studies/:studyId/assessment-measurements/:id/system-options',
        toAssessmentMeasurementSystemOption
    ).readableEntityClient(),

    listAllStudyAssessmentMeasurementSystemOptions: cqrs<SystemOptionsByAssessmentMeasurementId, StudyPathParameter>(
        '/api/study-designer/studies/:studyId/assessment-measurements/system-options',
        toSystemOptionsByAssessmentMeasurementId
    ).list<StudyPathParameter>(),
};
