import { AuditEvent } from '@faro/study-designer-model';
import { auditEventClient } from './AuditEventClient';

/**
 * Service responsible for working with {@link AuditEvent}s.
 */
export class AuditEventService {
    readonly create: (entity: Omit<AuditEvent, 'id'>) => Promise<AuditEvent>;

    constructor() {
        this.create = auditEventClient.create;
    }
}
