import { Country, toCountry } from '@faro/study-designer-model';
import { cqrs } from '@faro/http-clients';

/**
 * Client responsible for managing Countries
 */
export const countryClient = cqrs<Country>(
    '/api/study-designer/enumerated-values/countries',
    toCountry
).listableEntityClient();
