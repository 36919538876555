import { ObjectiveGroupSequence, toObjectiveGroupSequence } from '@faro/study-designer-model';
import { cqrs } from '@faro/http-clients';
import { StudyPathParameter } from '../parameter';

export type ObjectiveGroupSequencePathParameters = StudyPathParameter;

export const objectiveGroupSequenceClient = cqrs<ObjectiveGroupSequence, ObjectiveGroupSequencePathParameters>(
    '/api/study-designer/studies/:studyId/objective-group-sequences',
    toObjectiveGroupSequence
).readableEntityClient();
