import { ObjectiveGroup, toObjectiveGroup } from '@faro/study-designer-model';
import { cqrs } from '@faro/http-clients';
import { StudyPathParameter } from '../parameter';

export type ObjectiveGroupPathParameters = StudyPathParameter;

const factory = cqrs<ObjectiveGroup, ObjectiveGroupPathParameters>(
    '/api/study-designer/studies/:studyId/objective-groups',
    toObjectiveGroup
);

export const objectiveGroupClient = {
    ...factory.writableEntityClient(),
    clone: factory.clone(),
    move: factory.move(),
};
