import {
    AllocationActivity,
    CommandResponse,
    RevisionedCommandResponse,
    StudyEntityModification,
    toAllocationActivity,
} from '@faro/study-designer-model';
import { cqrs, setPathParameters } from '@faro/http-clients';
import axios, { AxiosResponse } from 'axios';
import { SchemaQueryParameter, StudyPathParameter } from '../parameter';

export type AllocationActivityPathParameters = StudyPathParameter;
export type AllocationActivityQueryParameters = SchemaQueryParameter;

/**
 * Client responsible for accessing {@link AllocationActivity}s
 */
export const allocationActivityClient = {
    ...cqrs<AllocationActivity, AllocationActivityPathParameters>(
        '/api/study-designer/studies/:studyId/allocation-activities',
        toAllocationActivity
    ).writableEntityClient<AllocationActivityQueryParameters>(),

    modify: (entityReference: StudyEntityModification): Promise<RevisionedCommandResponse<CommandResponse>> =>
        axios
            .post<StudyEntityModification, AxiosResponse<RevisionedCommandResponse<CommandResponse>>>(
                setPathParameters(
                    '/api/study-designer/studies/:studyId/allocation-activities/actions/modify',
                    entityReference as any
                ),
                entityReference
            )
            .then(response => response.data),
};
