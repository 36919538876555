import { DraggableProvided } from '@hello-pangea/dnd';
import { ComponentProps, Ref } from 'react';
import { removeTranslationX } from '../removeTranslationX';
import { tableRowDraggableStyle, TableRowDraggableStyleOptions } from './tableRowDraggableStyle';

/**
 * Creates the props you need to apply to a draggable table row given the {@link DraggableProvided}
 * @param provided The react-beautiful-dnd-provided draggable props
 * @param container The scroll container used to calculate the row styles
 * @param options Configuration options for styling
 */
export function tableRowDraggableProps(
    provided: DraggableProvided,
    container: HTMLElement | null,
    options: TableRowDraggableStyleOptions = {}
): Omit<ComponentProps<'tr'>, 'ref'> & { ref: Ref<HTMLTableRowElement> } {
    return {
        ...provided.draggableProps,
        ref: provided.innerRef,
        style: {
            ...provided.draggableProps.style,
            ...removeTranslationX(provided.draggableProps.style),
            ...tableRowDraggableStyle(container, options),
        } as any,
    };
}
