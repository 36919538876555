import React from 'react';
import { createRoot } from 'react-dom/client';
/**
 * NOTE that import order is important, the shared-ui CSS MUST be imported prior
 * to App to apply modular styles AFTER library styles
 */
import '@faro/design-system/styles.css';
import '@faro/app-context/style.css';
import './style/styles.scss';
import App from './App';

const rootElement = document.getElementById('root') as HTMLElement;
const root = createRoot(rootElement);
root.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>
);
