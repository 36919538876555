import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { FaroSelectProps, Option, useDebounce } from '@faro/design-system';

export type FaroSelectSearchProps<Type = any> = Pick<
    FaroSelectProps<Type>,
    'searchEnabled' | 'onSearch' | 'loading' | 'options' | 'error' | 'renderSelectedValue'
>;

export type FaroSelectState<Type = any> = Partial<Pick<FaroSelectProps<Type>, 'loading' | 'error' | 'options'>>;

/**
 * Configurations for how how and when a search should be made
 */
export interface SearchOptions {
    /**
     * The minimum amount of characters required to run a search
     * @default 0
     */
    minimumCharacters?: number;
    /**
     * The debounce time to use
     * @default 0
     */
    debounceTime?: number;
    /**
     * dependencies to watch to retrigger options
     * @default []
     */
    dependencies?: any[];
}

/**
 * Custom hook used to simplify setup of search-enabled {@link FaroSelect} components.
 *
 * @example <code>
 *   function MyComponent(): JSX.Element {
 *     const mySelectProps = useSearchEnabledSelect(
 *       search => myEntityService.list(search)
 *          .then(entities => entities.map(entity => ({
 *              key: entity.id,
 *              label: entity.name,
 *              value: entity.id
 *          }))
 *       { debounceTime: 250 }
 *     );
 *
 *     return (
 *       <FaroSelect {...mySelectProps}/>
 *     );
 *   }
 * </code>
 *
 * @param searchMethod The <strong>static</strong> search method
 * @param renderSelectedValue The method used to render the values separately from the options
 * @param options Extra options used to configure when/how the search method is called
 */
export function useSearchEnabledSelect<R>(
    searchMethod: (search: string) => Option<R>[],
    renderSelectedValue: (value: R | undefined, index: number, onDelete?: (event: any) => void) => ReactNode,
    options: SearchOptions = {}
): FaroSelectProps<R> {
    const { minimumCharacters = 0, debounceTime = 0, dependencies = [] } = options;
    const [search, setSearch] = useState('');
    const [state, setStateInternal] = useState<FaroSelectState>({ loading: false });

    // Use a reference to the state so that we do not have to include state as a dependency of our effect
    const stateRef = useRef({});

    // Memoize the search method as this need not change over the course of the component's lifetime
    const memoizedSearchMethod = useCallback(searchMethod, [...dependencies]);
    const debouncedSearch = useDebounce(search, debounceTime);
    const trimmedSearch = debouncedSearch.trim();

    useEffect(() => {
        const setState = (value: FaroSelectState) => {
            setStateInternal(value);
            stateRef.current = value;
        };

        // Apply minimum character constraint
        if (trimmedSearch.length < minimumCharacters) {
            // Clear state when the minimum character count is not exceeded
            setState({ loading: false });
            return;
        }

        setState({ ...stateRef.current, loading: true });

        try {
            const options = memoizedSearchMethod(debouncedSearch);
            setState({ loading: false, options: options as any });
        } catch {
            setState({ ...stateRef.current, loading: false, error: 'Search failed' });
        }
    }, [debouncedSearch, ...dependencies]);

    return {
        searchEnabled: true,
        onSearch: setSearch,
        renderSelectedValue,
        ...state,
    };
}
