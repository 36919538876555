import { NamedObject } from '@faro/study-designer-model';
import { cqrs } from '@faro/http-clients';
import { StudyPathParameter } from '../parameter';

export type ObjectiveTypePathParameters = StudyPathParameter;

export const objectiveTypeClient = cqrs<NamedObject, ObjectiveTypePathParameters>(
    '/api/study-designer/studies/:studyId/objective-types',
    // faro-common-model toNamedObject can return undefined so this defines a custom mapper
    (value: any) =>
        ({
            id: value.id,
            name: value.name,
        }) as NamedObject
).listableEntityClient();
