import { SystemDefinedObject, toRequiredSystemDefinedObject } from '@faro/study-designer-model';
import { cqrs } from '@faro/http-clients';

/**
 * Client responsible for managing intervention use
 */
export const interventionUseClient = cqrs<SystemDefinedObject>(
    '/api/study-designer/interventions/uses',
    toRequiredSystemDefinedObject
).listableEntityClient();
