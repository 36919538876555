import { IntraDaySchedule, toIntraDaySchedule } from '@faro/study-designer-model';
import { cqrs } from '@faro/http-clients';
import { IntraDayScheduleClientPathParams } from './IntraDaySchedule.types';
import { ScheduleOfActivitiesQueryParameter, VariantQueryParameter } from '../parameter';

export type IntraDayScheduleQueryParameters = VariantQueryParameter & ScheduleOfActivitiesQueryParameter;

/**
 * Client responsible for managing Intra Day Schedules
 */
export const intraDayScheduleClient = cqrs<IntraDaySchedule, IntraDayScheduleClientPathParams>(
    '/api/study-designer/studies/:studyId/intra-day-schedules',
    toIntraDaySchedule
).writableEntityClient();
