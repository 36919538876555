import React, { forwardRef } from 'react';
import { FaroOverlayModal, FaroOverlayModalProps, FaroOverlayRef } from '@faro/design-system';
import StudyDefinitionFileImportDialog, {
    StudyDefinitionFileImportFormValues,
} from '../StudyDefinitionFileImportDialog/StudyDefinitionFileImportDialog';

/**
 * Configuration properties for the {@link StudyDefinitionFileImportDialogModal} component.
 */
export interface StudyDefinitionFileImportDialogModalProps extends Omit<FaroOverlayModalProps, 'ref'> {
    /**
     * The acceptable file extension(s) for the study definition file to import.  Each extension should include the leading
     * `.`, (e.g. `.json` or `.png`).
     */
    readonly extensions: string[];

    /**
     * The version of the currently supported "import" schema.  The study definition file a user is attempting to import
     * must be compatible with this version.
     */
    readonly version?: string;

    /**
     * Handler for importing a file.
     *
     * @param formValues {@link StudyDefinitionFileImportFormValues.ts} representing the file(s) that were imported by the
     * user.
     * @returns Promise<any> The result of the import operation.
     */
    onImport(formValues: StudyDefinitionFileImportFormValues): Promise<any>;
}

export const StudyDefinitionFileImportDialogModal = forwardRef<
    HTMLDivElement,
    StudyDefinitionFileImportDialogModalProps
>((props, ref) => {
    const { extensions, version, onImport, ...rest } = props;

    const overlayRef = rest.overlayRef as FaroOverlayRef;

    function onClose(): void {
        overlayRef?.close();
    }

    return (
        <FaroOverlayModal ref={ref} showCloseButton {...rest}>
            <StudyDefinitionFileImportDialog
                extensions={extensions}
                version={version}
                onImport={onImport}
                onClose={onClose}
            />
        </FaroOverlayModal>
    );
});
