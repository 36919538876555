import {
    ActivityScheduleRule,
    CommandResponse,
    RevisionedCommandResponse,
    ScheduledDay,
    StudyEntityModification,
    toActivityScheduleRule,
} from '@faro/study-designer-model';
import { cqrs, EntityCreateResponse, setPathParameters } from '@faro/http-clients';
import { ScheduleOfActivitiesQueryParameter, StudyPathParameter, VariantQueryParameter } from '../parameter';
import axios, { AxiosResponse } from 'axios';

export type ActivityScheduleRulePathParameters = StudyPathParameter;
export type ActivityScheduleRuleQueryParameters = ScheduleOfActivitiesQueryParameter & VariantQueryParameter;

const cqrsClientFactory = cqrs<ActivityScheduleRule, ActivityScheduleRulePathParameters>(
    '/api/study-designer/studies/:studyId/activity-schedule-rules',
    toActivityScheduleRule
);
export const activityScheduleRuleClient = {
    ...cqrsClientFactory.writableEntityClient<ActivityScheduleRuleQueryParameters>(),
    create: (_entity: Omit<Partial<ActivityScheduleRule>, 'id'>): Promise<EntityCreateResponse> => {
        throw new Error('Unsupported operation: Create ActivityScheduleRule');
    },
    createBatch: (
        entityReference: StudyEntityModification<ActivityScheduleRule>
    ): Promise<RevisionedCommandResponse<CommandResponse>> =>
        axios
            .post<
                StudyEntityModification,
                AxiosResponse<RevisionedCommandResponse<CommandResponse>>
            >(setPathParameters('/api/study-designer/studies/:studyId/activity-schedule-rules/actions/create-batch', entityReference as any), entityReference)
            .then(response => response.data),
};
