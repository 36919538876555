import { studyDataHookFactory } from '../studyDataHookFactory';
import { StudyEntityType, SystemOptionsByAssessmentMeasurementId } from '@faro/study-designer-model';
import { studyAssessmentMeasurementSystemOptionsClient } from './StudyAssessmentMeasurementSystemOptionsClient';

const factory = studyDataHookFactory.entity<SystemOptionsByAssessmentMeasurementId>(
    'StudyAssessmentMeasurementSystemOption'
);

export const useStudyAssessmentMeasurementSystemOptions = factory.list(
    studyAssessmentMeasurementSystemOptionsClient.listAllStudyAssessmentMeasurementSystemOptions,
    [StudyEntityType.StudyAssessmentMeasurement]
);
