import { useUser } from '@faro/auth';
import { useMemo } from 'react';
export const useStudySpacePermissions = (): {
    viewer: boolean;
    editor: boolean;
    studyDefinitionAdmin: boolean;
} => {
    const user = useUser();
    return useMemo(() => {
        const roles = user.roles || '';
        return {
            editor: roles.includes('EDITOR'),
            viewer: roles.includes('VIEWER'),
            studyDefinitionAdmin: roles.includes('STUDY-DEFINITION-ADMIN'),
        };
    }, [user?.roles?.join('')]);
};
