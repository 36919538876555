import { useMemo } from 'react';
import { useUser } from '@faro/auth';
import { AppPermissions, getAppPermissions } from './getAppPermissions';
import { isEmpty } from 'lodash';

/**
 * Use this hook to retrieve the permissions for the current user.
 */
export function useAppPermissions(): AppPermissions {
    const user = useUser();
    return useMemo(() => {
        return !isEmpty(user?.roles) ? getAppPermissions(user) : emptyPermissions;
    }, [user?.roles?.join('')]);
}

const emptyPermission = { read: false, write: false };
const emptyPermissions = {
    study: emptyPermission,
    dynamicFieldDefinition: emptyPermission,
    textSnippet: emptyPermission,
    user: emptyPermission,
    studyStatus: emptyPermission,
    exportInsights: emptyPermission,
};
