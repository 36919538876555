import { cqrs } from '@faro/http-clients';
import { StudyRevision, StudyRevisionEvent, toStudyRevision, toStudyRevisionEvent } from '@faro/study-designer-model';
import { StudyPathParameter } from '../parameter';
import { PaginatedQuery } from '../Pagination/Pagination.types';
import { setPathParameters } from '@faro/http-clients';
import axios, { AxiosResponse } from 'axios';

export type StudyRevisionPathParameters = StudyPathParameter;

export interface StudyRevisionQueryParameters extends StudyPathParameter, PaginatedQuery {
    readonly includeSystemGeneratedEvents?: boolean;
}

export interface StudyRevisionListEventParameters extends StudyPathParameter {
    readonly revisionId: string;
}

export const studyRevisionClient = {
    ...cqrs<StudyRevision, StudyRevisionPathParameters>(
        '/api/study-designer/studies/:studyId/revisions',
        toStudyRevision as any /*StudyRevision doesn't include studyId*/
    ).readableEntityClient<StudyRevisionQueryParameters>(),

    listPaginated: cqrs<StudyRevision, StudyRevisionPathParameters>(
        '/api/study-designer/studies/:studyId/revisions',
        toStudyRevision
    ).listPaginated<StudyRevisionQueryParameters>(),

    update: (parameters: StudyRevision): Promise<StudyRevision> => {
        const url: string = setPathParameters('/api/study-designer/studies/:studyId/revisions/:id', parameters as any);

        const promise: Promise<AxiosResponse<StudyRevision>> = axios.put<StudyRevision>(url, parameters);

        return promise
            .then((response: AxiosResponse<StudyRevision>): StudyRevision => response.data)
            .then(data =>
                toStudyRevision({
                    ...data,
                    created: new Date(Date.parse(data.created as any as string)),
                })
            );
    },
    listEvents: (parameters: StudyRevisionListEventParameters): Promise<StudyRevisionEvent[]> => {
        const url: string = setPathParameters(
            '/api/study-designer/studies/:studyId/revisions/:revisionId/events',
            parameters as any
        );

        return axios
            .get<StudyRevisionEvent[]>(url)
            .then((response: AxiosResponse<StudyRevisionEvent[]>): StudyRevisionEvent[] => response.data)
            .then((data: StudyRevisionEvent[]) =>
                data.map(d =>
                    toStudyRevisionEvent({
                        ...d,
                        created: new Date(Date.parse(d.created as any as string)),
                    })
                )
            );
    },
};
