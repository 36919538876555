import React, { ComponentPropsWithRef } from 'react';
import styles from './SpaceGeneralInformation.module.scss';
import { SpaceGeneralInformationFormSkeleton } from './SpaceGeneralInformationForm/SpaceGeneralInformationForm.skeleton';
import { SpaceGeneralInformationTitleFormSkeleton } from './SpaceGeneralInformationTitleForm/SpaceGeneralInformationTitleForm.skeleton';

export const SpaceGeneralInformationSkeleton = (props: ComponentPropsWithRef<'div'>): JSX.Element => {
    const { className = '', ...rest } = props;
    return (
        <div
            className={`
            ${styles.form}
            ${className}
        `}
            {...rest}
        >
            <header>
                <SpaceGeneralInformationTitleFormSkeleton />
            </header>
            <SpaceGeneralInformationFormSkeleton />
        </div>
    );
};
