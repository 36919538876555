import styles from './FilterPopover.module.scss';
import React, { ReactNode } from 'react';
import { FaroButton, FaroPopover, FaroLabel, FaroOverlayProps } from '@faro/design-system';
import { Form, Formik } from 'formik';
import { FaroToggleField } from '@faro/forms';
import { Filters } from './FilterPopover.types';
import { isEqual, pickBy } from 'lodash';

export interface FilterPopoverProps extends Omit<FaroOverlayProps, 'children'> {
    setFilters: (f: Filters) => void;
    filters: Filters;
    initialFilterValues: Filters;
    onClose: () => void;
}

const FilterPopover = (props: FilterPopoverProps): ReactNode => {
    const { className = '', setFilters, filters, initialFilterValues, onClose, ...rest } = props;

    const submitFilters = async (values: Filters) => {
        const newFilters: Filters = pickBy(values, (v, k) => !isEqual((initialFilterValues as any)[k], v));
        setFilters(newFilters);
    };
    return (
        <FaroPopover className={`${className} `} {...rest}>
            <Formik className={className} initialValues={filters} onSubmit={submitFilters} enableReinitialize={true}>
                {({ submitForm, values, setValues }) => {
                    return (
                        <Form>
                            <FaroPopover.Header>Filters</FaroPopover.Header>
                            <FaroPopover.Body className={styles.popoverBody}>
                                <FaroToggleField
                                    name={'includeDeleted'}
                                    value={values.includeDeleted}
                                    className={styles.toggle}
                                />
                                <FaroLabel className={styles.filterLabel}>Show deleted definitions</FaroLabel>
                            </FaroPopover.Body>
                            <FaroPopover.Footer className={styles.footer}>
                                <FaroButton
                                    appearance="inline"
                                    data-automation-id="buttonCancel"
                                    onClick={() => {
                                        setValues({});
                                        submitForm();
                                        onClose();
                                    }}
                                >
                                    Reset Filters
                                </FaroButton>
                                <FaroButton
                                    className={styles.cancelButton}
                                    appearance="solid"
                                    data-automation-id="buttonCancel"
                                    onClick={() => {
                                        onClose();
                                    }}
                                >
                                    Cancel
                                </FaroButton>
                                <FaroButton
                                    disabled={false}
                                    loading={false}
                                    color="primary"
                                    onClick={async () => {
                                        submitForm();
                                        onClose();
                                    }}
                                >
                                    Apply
                                </FaroButton>
                            </FaroPopover.Footer>
                        </Form>
                    );
                }}
            </Formik>
        </FaroPopover>
    );
};

export default FilterPopover;
