import React, { ForwardedRef } from 'react';
import { FaroButton, FaroButtonProps, FaroMenu, FaroOverlayTrigger } from '@faro/design-system';
import { DocumentNewIcon, ImportIcon, PlusIcon } from '@faro/icons';
import styles from './CreateOrImportStudyDefinitionButton.module.scss';

export interface CreateOrImportStudyDefinitionButtonProps extends FaroButtonProps {
    onImport(): void;

    onCreateStudyDefinition(): Promise<void>;
}

function CreateOrImportStudyDefinitionButton(
    props: CreateOrImportStudyDefinitionButtonProps,
    ref: ForwardedRef<HTMLDivElement>
): JSX.Element {
    const { onImport, onCreateStudyDefinition, ...rest } = props;

    return (
        <FaroOverlayTrigger
            strategy="fixed"
            placement="bottom-end"
            openOnClick
            overlay={
                <FaroMenu ref={ref}>
                    <FaroMenu.Item
                        left={<DocumentNewIcon />}
                        onClick={() => onCreateStudyDefinition()}
                        data-automation-id="createStudyDefinitionMenuItem"
                    >
                        Create New
                    </FaroMenu.Item>
                    <FaroMenu.Item
                        left={<ImportIcon />}
                        onClick={() => onImport()}
                        data-intercom-target="Import Study Definition"
                        data-automation-id="importStudyDefinitionMenuItem"
                    >
                        Import
                    </FaroMenu.Item>
                </FaroMenu>
            }
        >
            <FaroButton color="primary" data-automation-id="addStudyDefinitionButton" {...rest}>
                <PlusIcon className={styles.plusIcon} />
                Add
            </FaroButton>
        </FaroOverlayTrigger>
    );
}

export default CreateOrImportStudyDefinitionButton;
