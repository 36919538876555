import { FaroIconButton, FaroTextInput } from '@faro/design-system';
import { GridViewIcon, SearchIcon } from '@faro/icons';
import React from 'react';
import StudySpaceTableSkeleton from '../../../component/StudySpaceTable/StudySpaceTableSkeleton/StudySpaceTableSkeleton';
import styles from '../SpaceListPage.module.scss';
import skeletonStyles from './SpaceListPageSkeleton.module.scss';

export function SpaceListPageSkeleton(): React.JSX.Element {
    return (
        <main className={`${styles.root} ${skeletonStyles.skeletonFilter}`}>
            <header>
                <h1>Study Spaces</h1>
                <div className={styles.spacer} />
                <FaroIconButton icon={<GridViewIcon />} />
            </header>

            <div className={styles.filter}>
                <FaroTextInput
                    disabled={true}
                    appearance="outlined"
                    fullWidth
                    icon={<SearchIcon />}
                    iconPosition={'left'}
                />
            </div>

            <section className={styles.studySpacesSection}>
                <StudySpaceTableSkeleton />
            </section>
        </main>
    );
}
