import { cqrs } from '@faro/http-clients';
import { AssessmentMeasurementFilterOptions, ClassTypeOption, TherapeuticAreaOption } from './types';

export function mapFilterOptions(options: any): AssessmentMeasurementFilterOptions {
    const classType = options.classType?.map(({ id, name }: ClassTypeOption) => ({
        key: id,
        label: name,
        value: id,
    }));

    const therapeuticArea = options.therapeuticArea?.map(({ id, name }: TherapeuticAreaOption) => ({
        key: id,
        label: name,
        value: id,
    }));

    return { therapeuticArea, classType };
}

/**
 * Client responsible for managing allocation activity type
 */
export const assessmentMeasurementOptionsClient = cqrs<AssessmentMeasurementFilterOptions>(
    '/api/study-designer/assessment-measurements/options',
    mapFilterOptions
).optionsClient();
