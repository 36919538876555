import { createResourceQuery } from '@faro/resource-query';
import { ServiceContextValue } from '../../context';
import { AxiosError } from 'axios';
import { TherapeuticArea, StudyPhase, DiseaseArea } from '../../service';

export interface GeneralInformationData {
    therapeuticAreas: TherapeuticArea[];
    diseaseAreas: DiseaseArea[];
    studyPhases: StudyPhase[];
}

export interface GeneralInformationResolverArgs {
    context: ServiceContextValue;
}

const getGeneralInformationData = async ({ context }: GeneralInformationResolverArgs) => {
    const { therapeuticAreasService, diseaseAreasService, studyPhasesService } = context;
    const [therapeuticAreas, diseaseAreas, studyPhases] = await Promise.all([
        therapeuticAreasService.list(),
        diseaseAreasService.list(),
        studyPhasesService.list(),
    ]);
    return {
        therapeuticAreas,
        diseaseAreas,
        studyPhases,
    };
};

export const useGeneralInformationData = createResourceQuery<
    GeneralInformationData,
    undefined,
    ServiceContextValue,
    AxiosError
>({
    id: 'generalInformationData',
    resolve: getGeneralInformationData,
    useQueryOptions: {
        staleTime: 0,
    },
});
