import React, { useCallback, useMemo, useState } from 'react';
import { ArrowDownIcon, ArrowUpIcon } from '@faro/icons';
import { get, isEqual } from 'lodash';
import { isBlank } from '@faro/design-system';

type SortDirection = 'asc' | 'desc';

export interface SortConfig {
    keys: string[];
    direction: SortDirection;
    sortFunction?: (a: any, b: any) => number;
}

interface SortedData<T> {
    items: T[];
    sortDataBy: (keys: string[]) => void;
    sortConfig?: SortConfig;
    sortIconBy: (keys: string) => JSX.Element | null;
}

/*
 * Use this hook to sort data in a static table
 * @param items The data to sort
 * @param config The initial sort configuration
 */
export default function useSortableTable<T extends Record<string, any>>(
    items: T[],
    config?: SortConfig
): SortedData<T> {
    const [sortConfig, setSortConfig] = useState(config);

    const { keys, direction, sortFunction } = sortConfig || { keys: [], direction: 'asc' };

    const sum = (a: number, b: number) => a + b;

    const defaultSortFunction = useCallback(
        <T,>(a: T, b: T) => {
            return keys
                .map((key: string) => {
                    const keyA = get(a, key);
                    const keyB = get(b, key);

                    // Handle empty/null values
                    if (isBlank(keyA)) return direction === 'asc' ? -1 : 1;
                    if (isBlank(keyB)) return direction === 'asc' ? 1 : -1;

                    // Use localeCompare for case-insensitive comparison
                    return (
                        keyA.toString().localeCompare(keyB.toString(), undefined, { sensitivity: 'base' }) *
                        (direction === 'asc' ? 1 : -1)
                    );
                })
                .reduce(sum, 0);
        },
        [keys, direction]
    );

    const sortedItems = useMemo(() => {
        const sortableItems = [...items];
        if (sortConfig != null) {
            sortableItems.sort(sortFunction || defaultSortFunction<T>);
        }
        return sortableItems;
    }, [items, sortConfig]);

    const sortDataBy = (keys: string[]) => {
        let direction: SortDirection = 'asc';
        if (sortConfig && isEqual(sortConfig.keys, keys) && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ keys, direction });
    };

    const sortIconBy = (key: string): JSX.Element => {
        if (sortConfig && sortConfig.keys.includes(key)) {
            const SortIcon = sortConfig.direction === 'asc' ? ArrowUpIcon : ArrowDownIcon;
            const dataTestID = `sortIcon-${key}`;
            return <SortIcon data-testid={dataTestID}/>;
        }
        return <></>;
    };

    return { items: sortedItems, sortDataBy, sortConfig, sortIconBy };
}
