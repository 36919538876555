import { SystemDefinedObject, toRequiredSystemDefinedObject } from '@faro/study-designer-model';
import { cqrs } from '@faro/http-clients';

/**
 * Client responsible for managing blinding schema data access
 */
export const blindingSchemaClient = cqrs<SystemDefinedObject>(
    '/api/study-designer/enumerated-values/blinding-schemas',
    toRequiredSystemDefinedObject
).listableEntityClient();
