import React, { useContext } from 'react';
import { ChangeEventService } from '@faro/realtime-services';
import { ActivityTimeCostService } from './ActivityTimeCost/ActivityTimeCostService';
import { ActivityFootnoteService } from './ActivityFootnote/ActivityFootnoteService';
import { ActivityService } from './Activity/ActivityService';
import { ActivityGroupService } from './ActivityGroup/ActivityGroupService';
import { ActivityScheduleRuleService } from './ActivityScheduleRule/ActivityScheduleRuleService';
import { AllocationActivityService } from './AllocationActivity/AllocationActivityService';
import { AllocationRuleService } from './AllocationRule/AllocationRuleService';
import { AllocationRuleAttributesService } from './AllocationRuleAttributes/AllocationRuleAttributesService';
import { BiomedicalAssessmentMeasurementService } from './BiomedicalAssessmentMeasurement/BiomedicalAssessmentMeasurementService';
import { AssessmentMeasurementSystemOptionsService } from './AssessmentMeasurementSystemOptions/AssessmentMeasurementSystemOptionsService';
import { StudyAssessmentMeasurementSystemOptionsService } from './StudyAssessmentMeasurementSystemOptions/StudyAssessmentMeasurementSystemOptionsService';
import { AssessmentMeasurementOptionsService } from './AssessmentMeasurementOptions/AssessmentMeasurementOptionsService';
import { AssessmentMeasurementVersionService } from './AssessmentMeasurementVersion/AssessmentMeasurementVersionService';
import { BlindingSchemaService } from './BlindingSchema/BlindingSchemaService';
import { ClassTypeService } from './ClassType/ClassTypeService';
import { CountryService } from './Country/CountryService';
import { CycleService } from './Cycle/CycleService';
import { DiseaseAreaService } from './DiseaseArea/DiseaseAreaService';
import { DynamicFieldService } from './DynamicField/DynamicFieldService';
import { DynamicFieldDefinitionService } from './DynamicFieldDefinition/DynamicFieldDefinitionService';
import { EndpointService } from './Endpoint/EndpointService';
import { EpochGroupService } from './EpochGroup/EpochGroupService';
import { EpochTypeService } from './EpochType/EpochTypeService';
import { EstimandService } from './Estimand/EstimandService';
import { FeatureFlagService } from './FeatureFlag/FeatureFlagService';
import { DoseFormulationService } from './DoseFormulation/DoseFormulationService';
import { DoseRelativeUnitService } from './DoseRelativeUnit/DoseRelativeUnitService';
import { DoseUnitService } from './DoseUnit/DoseUnitService';
import { InterventionGroupService } from './InterventionGroup/InterventionGroupService';
import { InterventionGroupSequenceService } from './InterventionGroupSequence/InterventionGroupSequenceService';
import { InterventionManufacturerService } from './InterventionManufacturer/InterventionManufacturerService';
import { InterventionRouteOfAdministrationService } from './InterventionRouteOfAdministration/InterventionRouteOfAdministrationService';
import { VariantService } from './Variant/VariantService';
import { UserInfoService } from './UserInfo/UserInfoService';
import { UserCommentService } from './UserComment/UserCommentService';
import { TimeUnitService } from './TimeUnit/TimeUnitService';
import { TherapeuticAreaService } from './TherapeuticArea/TherapeuticAreaService';
import { TextSnippetService } from './TextSnippet/TextSnippetService';
import { TextSnippetOptionsService } from './TextSnippetOptions/TextSnippetOptionsService';
import { SystemConfigService } from './SystemConfig/SystemConfigService';
import { SubStudyService } from './SubStudy/SubStudyService';
import { StudyService } from './Study/StudyService';
import { StudyRevisionService } from './StudyRevision/StudyRevisionService';
import { StudyPopulationService } from './StudyPopulation/StudyPopulationService';
import { StudyPhaseService } from './StudyPhase/StudyPhaseService';
import { InterventionService } from './Intervention/InterventionService';
import { StudyEpochService } from './StudyEpoch/StudyEpochService';
import { StudyDesignService } from './StudyDesign/StudyDesignService';
import { StudyContributorService } from './StudyContributor/StudyContributorService';
import { StudyAssessmentMeasurementService } from './StudyAssessmentMeasurement/StudyAssessmentMeasurementService';
import { StageService } from './Stage/StageService';
import { StageGroupService } from './StageGroup/StageGroupService';
import { SectionService } from './Section/SectionService';
import { ScheduledDayService } from './ScheduledDay/ScheduledDayService';
import { ScheduleOfActivitiesService } from './ScheduleOfActivities/ScheduleOfActivitiesService';
import { ObjectiveTypeService } from './ObjectiveType/ObjectiveTypeService';
import { ObjectiveService } from './Objective/ObjectiveService';
import { ObjectiveGroupService } from './ObjectiveGroup/ObjectiveGroupService';
import { ObjectiveGroupSequenceService } from './ObjectiveGroupSequence/ObjectiveGroupSequenceService';
import { LocationService } from './Location/LocationService';
import { IntraDayScheduleService } from './IntraDaySchedule/IntraDayScheduleService';
import { InterventionUseService } from './InterventionUse/InterventionUseService';
import { InterventionSourcingService } from './InterventionSourcing/InterventionSourcingService';
import { InsightDayService } from './InsightDay/InsightDayService';
import { AssessmentMeasurementMetadataService } from './AssessmentMeasurementMetadata/AssessmentMeasurementMetadataService';
import { InsightsExportService } from './InsightsExportService/InsightsExportService';
import { AssessmentMeasurementConfigurationService } from './AssessmentMeasurementConfiguration/AssessmentMeasurementConfigurationService';
import { SchemaService } from './Schema/SchemaService';

/**
 * This context functions as our service registry, allowing consuming components to access services.
 */
export interface ServiceContextValue {
    activityFootnoteService: ActivityFootnoteService;
    activityGroupService: ActivityGroupService;
    activityScheduleRuleService: ActivityScheduleRuleService;
    activityService: ActivityService;
    activityTimeCostService: ActivityTimeCostService;
    allocationActivityService: AllocationActivityService;
    allocationRuleAttributesService: AllocationRuleAttributesService;
    allocationRuleService: AllocationRuleService;
    assessmentMeasurementOptionsService: AssessmentMeasurementOptionsService;
    assessmentMeasurementService: BiomedicalAssessmentMeasurementService;
    assessmentMeasurementConfigurationService: AssessmentMeasurementConfigurationService;
    assessmentMeasurementMetadataService: AssessmentMeasurementMetadataService;
    assessmentMeasurementSystemOptionsService: AssessmentMeasurementSystemOptionsService;
    assessmentMeasurementVersionService: AssessmentMeasurementVersionService;
    blindingSchemaService: BlindingSchemaService;
    changeEventService: ChangeEventService;
    classTypeService: ClassTypeService;
    countryService: CountryService;
    cycleService: CycleService;
    diseaseAreaService: DiseaseAreaService;
    dynamicFieldDefinitionService: DynamicFieldDefinitionService;
    dynamicFieldService: DynamicFieldService;
    endpointService: EndpointService;
    epochGroupService: EpochGroupService;
    epochTypeService: EpochTypeService;
    estimandService: EstimandService;
    featureFlagService: FeatureFlagService;
    interventionDoseFormulationService: DoseFormulationService;
    interventionDoseRelativeUnitService: DoseRelativeUnitService;
    interventionDoseUnitService: DoseUnitService;
    interventionGroupSequenceService: InterventionGroupSequenceService;
    interventionGroupService: InterventionGroupService;
    interventionManufacturerService: InterventionManufacturerService;
    interventionRouteOfAdministrationService: InterventionRouteOfAdministrationService;
    interventionSourcingService: InterventionSourcingService;
    interventionUseService: InterventionUseService;
    intraDayScheduleService: IntraDayScheduleService;
    locationService: LocationService;
    objectiveGroupSequenceService: ObjectiveGroupSequenceService;
    objectiveGroupService: ObjectiveGroupService;
    objectiveService: ObjectiveService;
    objectiveTypeService: ObjectiveTypeService;
    scheduleOfActivitiesService: ScheduleOfActivitiesService;
    scheduledDayService: ScheduledDayService;
    schemaService: SchemaService;
    sectionService: SectionService;
    stageGroupService: StageGroupService;
    stageService: StageService;
    studyAssessmentMeasurementService: StudyAssessmentMeasurementService;
    studyAssessmentMeasurementSystemOptionsService: StudyAssessmentMeasurementSystemOptionsService;
    studyContributorService: StudyContributorService;
    studyDesignService: StudyDesignService;
    studyEpochService: StudyEpochService;
    studyInterventionService: InterventionService;
    studyPhaseService: StudyPhaseService;
    studyPopulationService: StudyPopulationService;
    studyRevisionService: StudyRevisionService;
    studyService: StudyService;
    subStudyService: SubStudyService;
    systemConfigService: SystemConfigService;
    textSnippetOptionsService: TextSnippetOptionsService;
    textSnippetService: TextSnippetService;
    therapeuticAreaService: TherapeuticAreaService;
    timeUnitService: TimeUnitService;
    userCommentService: UserCommentService;
    userInfoService: UserInfoService;
    variantService: VariantService;
    insightDayService: InsightDayService;
    insightsExportService: InsightsExportService;
}

export const ServiceContext = React.createContext({} as ServiceContextValue);

export function useServiceContext(): ServiceContextValue {
    return useContext(ServiceContext);
}
