import { ScheduledDay, toScheduledDay } from '@faro/study-designer-model';
import { cqrs, EntityCreateResponse } from '@faro/http-clients';
import { ScheduleOfActivitiesQueryParameter, StudyPathParameter, VariantQueryParameter } from '../parameter';
import { Identified } from '@faro/realtime-services';

export type ScheduledDayPathParameters = StudyPathParameter;
export type ScheduledDayQueryParameters = ScheduleOfActivitiesQueryParameter &
    VariantQueryParameter & {
        /**
         * Comma delimited list of {@link ScheduledDayType}.
         */
        types?: string;
    };

const factory = cqrs<ScheduledDay, ScheduledDayPathParameters>(
    '/api/study-designer/studies/:studyId/scheduled-days',
    toScheduledDay
);

export type ScheduledDayDuplicateRequest = {
    studyId: string;
    id: string;
    days: { [key: string]: number[] } | number[];
};

/**
 * Client responsible for accessing the backend entity services
 */
export const scheduledDayClient = {
    ...factory.writableEntityClient<ScheduledDayQueryParameters>(),
    clone: factory.clone() as unknown as (params: ScheduledDayDuplicateRequest) => Promise<EntityCreateResponse>,

    create: (_entity: Omit<Partial<ScheduledDay>, 'id'>): Promise<EntityCreateResponse> => {
        throw new Error('Unsupported operation: Create ScheduledDay');
    },
};
