import type { DefaultError, QueryClient } from '@tanstack/query-core';
import { useMutation, UseMutationOptions, UseMutationResult, WithRequired } from '@tanstack/react-query';
import { ChangeEventService } from '@faro/realtime-services';

export type UseRealtimeMutationOptions<
    TData = unknown,
    TError = DefaultError,
    TVariables = void,
    TContext = unknown,
> = WithRequired<Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'scope'>, 'mutationFn'> & {
    scope: string;
    service: Pick<ChangeEventService<any>, 'pause' | 'unpause'>;
};

/**
 * Custom react hook used to mutate data.
 * This hook will automatically pause change event listening while mutations are underway
 * to prevent race conditions associated with concurrent writes
 *
 * @param options The mutation configuration
 * @param localQueryClient Optional query client override
 */
export function useRealtimeMutation<TData = unknown, TError = DefaultError, TVariables = void, TContext = unknown>(
    options: UseRealtimeMutationOptions<TData, TError, TVariables, TContext>,
    localQueryClient?: QueryClient
): UseMutationResult<TData, TError, TVariables, TContext> {
    const { scope, service, ...rest } = options;

    return useMutation(
        {
            ...rest,
            // This instructs react-query to queue mutations on the given key
            // In practice we should use the ID for the top-level entity ID we are mutating (study/space/etc...)
            scope: { id: scope },
            onMutate: async (...args) => {
                // If onMutate has optimistic updates we want those to go through before waiting on the change event service
                // TODO: move change event fetching to query so we can cancel it & refetch it instead of pause/unpausing
                const response = await options?.onMutate?.(...args);
                await service.pause();
                return response;
            },
            onSettled: (...args) => {
                // Trigger change event refresh (unpause())
                service.unpause();
                return options?.onSettled?.(...args);
            },
        },
        localQueryClient
    );
}
