import { SystemDefinedObject, toRequiredSystemDefinedObject } from '@faro/study-designer-model';
import { cqrs } from '@faro/http-clients';

/**
 * Client responsible for managing intervention sourcing
 */
export const interventionSourcingClient = cqrs<SystemDefinedObject>(
    '/api/study-designer/interventions/sourcing',
    toRequiredSystemDefinedObject
).listableEntityClient();
