import { cqrs } from '@faro/http-clients';
import { TextSnippetFilterOptions } from './types';
import { namedObjectToOption } from '../../util/namedObjectToOption';
import { mapValues } from 'lodash';
import { SystemDefinedObject } from '@faro/study-designer-model';

function formatFilterOptions(options: {
    [key in keyof TextSnippetFilterOptions]: SystemDefinedObject[];
}): TextSnippetFilterOptions {
    return mapValues(options, categories => categories.map(namedObjectToOption));
}

/**
 * Client responsible for managing allocation activity type
 */
export const textSnippetOptionsClient = cqrs<TextSnippetFilterOptions>(
    '/api/study-designer/text-snippets/options',
    formatFilterOptions
).optionsClient();
