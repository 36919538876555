import React, { ComponentProps, ReactNode } from 'react';
import styles from './StudyDefinitionExportFormDisplayText.module.scss';

/**
 * An interface for configuring the {@link StudyDefinitionExportFormDisplayText} component.
 */
interface StudyDefinitionExportFormDisplayTextProps extends ComponentProps<'div'> {
    /**
     * The text that will be displayed in the select list when an item is selected.  In the dropdown list, this will be
     * the main display text a user can choose.  In the form's header, this will be the main title of the form.
     */
    readonly text: ReactNode;

    /**
     * Additional information describing the content of the `text` field.  In the dropdown list, this will be displayed
     * below the label.  If description is not provided, nothing will appear beneath the text.
     */
    readonly description?: ReactNode;
}

export function StudyDefinitionExportFormDisplayText(props: StudyDefinitionExportFormDisplayTextProps): JSX.Element {
    const { text, description, ...rest } = props;

    return (
        <div {...rest}>
            <div>{text}</div>
            {description && <div className={styles.description}>{description}</div>}
        </div>
    );
}
