import styles from './StudyDefinitionTableControls.module.scss';
import React, { forwardRef, ComponentPropsWithRef, useCallback, useRef, useState } from 'react';
import CreateOrImportStudyDefinitionButton from '../../../../../component/CreateOrImportStudyDefinitionButton/CreateOrImportStudyDefinitionButton';
import { FaroButton, FaroOverlay, FaroTextInput } from '@faro/design-system';
import { PlusIcon, SearchIcon } from '@faro/icons';
import { FilterButton } from '@faro/app-context';
import { Filters } from '../FilterPopover/FilterPopover.types';
import FilterPopover from '../FilterPopover/FilterPopover';

export interface StudyDefinitionTableControlsProps extends ComponentPropsWithRef<'div'> {
    canImport: boolean;
    onCreateStudyDefinition: () => Promise<void>;
    setImportModalOpen: (open: boolean) => void;
    spaceId: string;
    creatingStudyDefinition: boolean;
    readonly?: boolean;
    searchText: string;
    setSearchText: (searchText: string) => void;
    showSearch?: boolean;
    initialFilterValues: Filters;
    filters: Filters;
    setFilters: (f: Filters) => void;
}

const StudyDefinitionTableControls = forwardRef<HTMLDivElement, StudyDefinitionTableControlsProps>((props, ref) => {
    const {
        className = '',
        canImport,
        showSearch = true,
        onCreateStudyDefinition,
        setImportModalOpen,
        creatingStudyDefinition,
        readonly,
        searchText,
        setSearchText,
        filters,
        initialFilterValues,
        setFilters,
    } = props;

    const [filterOverlayOpen, setFilterOverlayOpen] = useState<boolean>(false);

    const onFilterButtonClick = useCallback(() => {
        if (!filterOverlayOpen) {
            setFilterOverlayOpen(!filterOverlayOpen);
        }
    }, [filterOverlayOpen, filterOverlayOpen]);

    const anchorElement = useRef<HTMLButtonElement>(null);

    return (
        <div ref={ref} className={className}>
            <div className={styles.titleBar}>
                <h2 className={styles.formHeading} data-testid="studyDefinitionsTitle">
                    Study Definitions
                </h2>
                {canImport && (
                    <CreateOrImportStudyDefinitionButton
                        onCreateStudyDefinition={onCreateStudyDefinition}
                        onImport={() => setImportModalOpen(true)}
                        loading={creatingStudyDefinition}
                    />
                )}
                {!readonly && (
                    <FaroButton
                        color="primary"
                        className={`${canImport ? styles.hideCreateStudyDefinitionButton : ''}`}
                        icon={<PlusIcon />}
                        onClick={onCreateStudyDefinition}
                        loading={creatingStudyDefinition}
                        data-automation-id="createStudyDefinitionButton"
                        data-testid="createStudyDefinitionButton"
                    >
                        Create
                    </FaroButton>
                )}
            </div>
            {showSearch && (
                <div className={styles.filter}>
                    <FilterButton
                        {...{
                            filters,
                            initialFilterValues: initialFilterValues,
                            onClick: onFilterButtonClick,
                            anchorRef: anchorElement,
                            onReset: () => setFilters(initialFilterValues),
                        }}
                    />
                    <FaroOverlay
                        open={filterOverlayOpen}
                        onClose={() => setFilterOverlayOpen(false)}
                        anchorElement={anchorElement?.current}
                        placement="bottom-start"
                    >
                        <FilterPopover
                            setFilters={setFilters}
                            filters={filters}
                            initialFilterValues={initialFilterValues}
                            onClose={() => setFilterOverlayOpen(false)}
                        />
                    </FaroOverlay>
                    <FaroTextInput
                        data-automation-id="searchStudyDefinitionsInput"
                        data-testid="filterStudyDefinitionsInput"
                        data-intercom-target="Filter Study Definition"
                        appearance="outlined"
                        fullWidth
                        placeholder="Filter by title, status or origin"
                        icon={<SearchIcon />}
                        iconPosition={'left'}
                        value={searchText}
                        onChange={(value: string | undefined) => {
                            setSearchText(value || '');
                        }}
                    />
                </div>
            )}
        </div>
    );
});

export default StudyDefinitionTableControls;
