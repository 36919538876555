import { Option } from '@faro/design-system';
import { NamedObject } from '@faro/study-designer-model';

/**
 * Maps a NamedObject to a display option
 * @param value The enum reference
 */
export function namedObjectToOption<T extends NamedObject>(value: T): Option<T> {
    return {
        key: value.id,
        value,
        label: value.name,
    };
}
