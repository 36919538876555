/**
 * Creates a filter predicate that returns true if the processed value includes the given string
 * It also returns true if the given search is null or empty
 * @param on The field to search on for the given object type being filtered
 * @param search The term to search with
 */
export function caseInsensitiveIncludesStringFilter<T = string>(
    on: (value: T) => string,
    search: string | undefined
): (value: T) => boolean {
    const normalizedSearch = normalizeString(search);
    if (normalizedSearch.length === 0) {
        return () => true;
    }
    return value => normalizeString(on(value)).includes(normalizedSearch);
}

export function normalizeString(value?: string): string {
    return (value || '').trim().toLowerCase();
}
