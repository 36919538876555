import { cqrs } from '@faro/http-clients';
import { Study } from '@faro/study-designer-model';
import { StudyPathParameter } from '../parameter';

export type SubStudyPathParameters = StudyPathParameter;

export type SubStudy = Study & {
    sourceStudyId: string;
};

export const subStudyClient = {
    ...cqrs<SubStudy, SubStudyPathParameters>(
        '/api/study-designer/studies/:studyId/sub-studies'
    ).writableEntityClient(),
    create: cqrs<SubStudy, SubStudyPathParameters>('/api/study-designer/studies/:studyId/sub-studies').create(),
};
