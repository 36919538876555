import { StudyDesignQueryParameter, StudyPathParameter } from '../parameter';
import { cqrs } from '@faro/http-clients';
import { Cycle, toCycle } from '@faro/study-designer-model';

export type CyclePathParameter = StudyPathParameter;
export type CycleQueryParameters = StudyDesignQueryParameter;

/**
 * Client managing API for the {@link Cycle} entity
 */
export const cycleClient = cqrs<Cycle, CyclePathParameter>(
    '/api/study-designer/studies/:studyId/cycles',
    toCycle
).writableEntityClient<CycleQueryParameters>();
