import { Identifiable } from 'faro-common-model';
import {
    CommandResponse,
    InterventionGroup,
    toInterventionGroup,
    RevisionedCommandResponse,
} from '@faro/study-designer-model';
import { cqrs } from '@faro/http-clients';
import { StudyPathParameter } from '../parameter';
import axios, { AxiosResponse } from 'axios';
import { setPathParameters } from '@faro/http-clients';

export type InterventionGroupPathParameters = StudyPathParameter;

/**
 * Client responsible for accessing the backend entity services
 */
export const interventionGroupClient = {
    ...cqrs<InterventionGroup, InterventionGroupPathParameters>(
        '/api/study-designer/studies/:studyId/intervention-groups',
        toInterventionGroup
    ).writableEntityClient<InterventionGroupPathParameters>(),

    createGroup: cqrs<InterventionGroup & { sequenceNumber: number }, InterventionGroupPathParameters>(
        '/api/study-designer/studies/:studyId/intervention-groups/actions/create',
        toInterventionGroup as any
    ).create(),

    move: (
        moveCommand: InterventionGroupPathParameters & Identifiable & { sequenceNumber: number }
    ): Promise<RevisionedCommandResponse<CommandResponse>> =>
        axios
            .post<
                InterventionGroupPathParameters & Identifiable & { sequenceNumber: number },
                AxiosResponse<RevisionedCommandResponse<CommandResponse>>
            >(
                setPathParameters(
                    '/api/study-designer/studies/:studyId/intervention-groups/:id/actions/move',
                    moveCommand as any
                ),
                {
                    sequenceNumber: moveCommand.sequenceNumber,
                }
            )
            .then(response => response.data),
};
