import { createResourceQuery } from '@faro/resource-query';
import { ServiceContextValue } from '../../context/Service/ServiceContext';
import { StudyDefinitionReference, StudySpace } from '@faro/study-space-service-model';
import { StudyEntityType } from '@faro/study-designer-model';

interface SpaceMenuData {
    studyDefinitionReferences: StudyDefinitionReference[];
}
type SpaceMenuDataQuery = { spaceId: StudySpace['id'] };
interface ResolverArgs {
    context: ServiceContextValue;
    query: SpaceMenuDataQuery;
}
export const useSpacesMenuData = createResourceQuery<
    SpaceMenuData,
    SpaceMenuDataQuery,
    ServiceContextValue,
    Error,
    ResolverArgs
>({
    resolve: async ({ context, query }: ResolverArgs) => {
        const { studyDefinitionReferenceService } = context;
        const studyDefinitionReferences = await studyDefinitionReferenceService.list(query);
        return {
            studyDefinitionReferences,
        };
    },
    queryKeys: [StudyEntityType.Study],
    id: 'spacesPageData',
});
