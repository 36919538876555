import React, { useEffect } from 'react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { isBlank } from '../../util/isBlank';

const ReactQueryDevtoolsProduction = React.lazy(() =>
    import('@tanstack/react-query-devtools/build/modern/production.js').then(x => ({ default: x.ReactQueryDevtools }))
);

function isDevelopmentEnvironment(): boolean {
    const NODE_ENV = import.meta.env.MODE;
    return isBlank(NODE_ENV) || NODE_ENV === 'development';
}

function ConsoleLaunchedReactQueryDevtools(): JSX.Element | null {
    const [showDevtools, setShowDevtools] = React.useState(false);

    useEffect(() => {
        (window as any).toggleReactQueryDevtools = () => {
            setShowDevtools(value => !value);
        };
    }, []);

    if (!showDevtools) {
        return null;
    }

    if (isDevelopmentEnvironment()) {
        return <ReactQueryDevtools initialIsOpen />;
    }

    return (
        <React.Suspense fallback={null}>
            <ReactQueryDevtoolsProduction />
        </React.Suspense>
    );
}

export default ConsoleLaunchedReactQueryDevtools;
