import { forwardRef, useContext } from 'react';
import { FaroButton, FaroDialog, FaroOverlayModal, FaroOverlayModalProps, FaroOverlayRef } from '@faro/design-system';
import styles from './StudyDefinitionMoveModal.module.scss';
import { FaroSelectField } from '@faro/forms';
import { Formik } from 'formik';
import { ServiceContext } from '../../../context';
import { useStudyDefinitionMoveModalData } from './useStudyDefinitionMoveModalData';

export interface StudyDefinitionMoveModalProps extends Omit<FaroOverlayModalProps, 'ref' | 'showCloseButton'> {
    fromSpaceId: string;
    onMove: (toSpaceId: string) => Promise<any> | void;
}

export interface StudyDefinitionMoveFormValues {
    spaceId: string;
}

const StudyDefinitionMoveModal = forwardRef<HTMLDivElement, StudyDefinitionMoveModalProps>((props, ref) => {
    const { onMove, fromSpaceId, ...rest } = props;
    const overlayRef = rest.overlayRef as FaroOverlayRef;

    function onSubmit(values: StudyDefinitionMoveFormValues): void {
        onMove(values.spaceId);
    }

    const initialValues = { spaceId: fromSpaceId };

    const context = useContext(ServiceContext);

    const [resource] = useStudyDefinitionMoveModalData({ context, query: undefined });
    const spacesOptions = resource.data?.spaces.map(space => ({
        label: space.title || 'Untitled',
        value: space.id,
    }));
    if (resource.status === 'loading' || resource.data == null) {
        return null;
    }

    return (
        <FaroOverlayModal ref={ref} {...rest}>
            <FaroDialog data-automation-id="moveStudyDefinitionModal">
                <Formik initialValues={initialValues} onSubmit={onSubmit}>
                    {({ submitForm, dirty }) => {
                        return (
                            <>
                                <FaroDialog.Header>Move</FaroDialog.Header>
                                <FaroDialog.Body>
                                    Choose a study space to move this definition to.
                                    <FaroSelectField
                                        name="spaceId"
                                        options={spacesOptions}
                                        className={styles.select}
                                        clearable={false}
                                        fullWidth
                                        data-automation-id="selectStudySpace"
                                        data-testid="selectStudySpace"
                                    />
                                </FaroDialog.Body>
                                <FaroDialog.Controls>
                                    <FaroButton
                                        appearance="inline"
                                        onClick={() => {
                                            overlayRef.close();
                                        }}
                                        data-automation-id="cancelButton"
                                    >
                                        Cancel
                                    </FaroButton>
                                    <FaroButton
                                        color="primary"
                                        onClick={() => {
                                            submitForm();
                                            overlayRef.close();
                                        }}
                                        disabled={!dirty || !spacesOptions?.length || spacesOptions.length <= 1}
                                        data-automation-id="moveButton"
                                        data-testid="moveButton"
                                    >
                                        Move
                                    </FaroButton>
                                </FaroDialog.Controls>
                            </>
                        );
                    }}
                </Formik>
            </FaroDialog>
        </FaroOverlayModal>
    );
});

export default StudyDefinitionMoveModal;
