import { RefObject, useMemo, useRef } from 'react';
import { DragDropContextProps, DropResult } from '@hello-pangea/dnd';
import { lockTableCellWidths, unlockTableCellWidths } from './lockTableCellWidths';

export interface TableDragDropContext {
    readonly draggingRef: RefObject<boolean>;
    readonly callbacks: Pick<DragDropContextProps, 'onBeforeCapture' | 'onDragStart' | 'onDragEnd'>;
}

/**
 * Custom hook used to provide the standard drag-drop behavior for an {@link DragDropContext} used around a table
 * @param tableElement The html table element reference
 * @param onRowMove Called when a row is moved
 */
export function useTableDragDrop(
    tableElement: HTMLTableElement | null,
    onRowMove: (from: number, to: number) => void
): TableDragDropContext {
    const draggingRef = useRef(false);

    const tableElementRef = useRef(tableElement);
    tableElementRef.current = tableElement;

    const callbacksRef = useRef({ onRowMove });
    callbacksRef.current.onRowMove = onRowMove;

    return useMemo(() => {
        function onBeforeCapture(): void {
            const element = tableElementRef.current;
            if (element != null) {
                lockTableCellWidths(element);
            }
        }

        function onDragStart(): void {
            draggingRef.current = true;
        }

        function onDragEnd(result: DropResult): void {
            draggingRef.current = false;

            const element = tableElementRef.current;
            if (element != null) {
                unlockTableCellWidths(element);
            }

            const { source, destination } = result;
            if (destination != null && destination.index !== source.index) {
                callbacksRef.current.onRowMove(source.index, destination.index);
            }
        }
        return {
            draggingRef,
            callbacks: {
                onBeforeCapture,
                onDragStart,
                onDragEnd,
            },
        };
    }, []);
}
