import { cqrs } from '@faro/http-clients';
import { FeatureFlag, toFeatureFlag } from '@faro/study-designer-model';

export type FeatureFlagQueryParameters = {
    readonly ids?: string;
};

export const featureFlagClient = cqrs<FeatureFlag>(
    '/api/study-designer/feature-flags',
    toFeatureFlag
).listableEntityClient<FeatureFlagQueryParameters>();
