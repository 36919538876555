import { UserInfo } from '@faro/user-management-model';
import { Permissions } from './Permissions';

export interface AppPermissions {
    study: Permissions;
    dynamicFieldDefinition: Permissions;
    textSnippet: Permissions;
    user: Permissions;
    studyStatus: Permissions;
    exportInsights: Permissions;
}

const permissionsByRole = new Map<string, string[]>([
    ['EDITOR', ['study:read', 'study:write']],
    ['VIEWER', ['study:read']],
    ['DYNAMIC-FIELD-DEF-ADMIN', ['dynamic-field-definition:write']],
    ['SNIPPET-ADMIN', ['snippet:write']],
    ['USER-ADMIN', ['user:write']],
    ['STUDY-DEFINITION-ADMIN', ['study-status:write', 'export-insights:write']],
]);

export function getAppPermissions(user: UserInfo): AppPermissions {
    const permissions = (user.roles || []).reduce((permissions: Set<string>, role: string) => {
        const rolePermissions = permissionsByRole.get(role);
        rolePermissions?.forEach(permission => {
            permissions.add(permission);
        });
        return permissions;
    }, new Set<string>());

    return {
        study: {
            write: permissions.has('study:write'),
            read: permissions.has('study:read'),
        },
        dynamicFieldDefinition: {
            write: permissions.has('dynamic-field-definition:write'),
            read: permissions.has('dynamic-field-definition:read') || permissions.has('dynamic-field-definition:write'),
        },
        textSnippet: {
            write: permissions.has('snippet:write'),
            read: permissions.has('snippet:read') || permissions.has('snippet:write'),
        },
        user: {
            write: permissions.has('user:write'),
            read: permissions.has('user:read') || permissions.has('user:write'),
        },
        studyStatus: {
            write: permissions.has('study-status:write'),
            read: permissions.has('study-status:read') || permissions.has('study-status:write'),
        },
        exportInsights: {
            write: permissions.has('export-insights:write'),
            read: permissions.has('export-insights:read') || permissions.has('export-insights:write'),
        },
    };
}
