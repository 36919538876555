import { FaroSkeleton, FaroTable } from '@faro/design-system';
import styles from '../StudySpaceTable.module.scss';

const StudySpaceTableSkeleton = (): JSX.Element => {
    return (
        <FaroTable className={styles.table} appearance="bordered-minimally-interactive">
            <thead>
                <tr>
                    <th>
                        <div className={styles.headerContent}>
                            <FaroSkeleton />
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                {Array.from({ length: 4 }, (_, i) => {
                    return (
                        <tr key={i}>
                            <FaroTable.Cell>
                                <FaroSkeleton />
                            </FaroTable.Cell>
                        </tr>
                    );
                })}
            </tbody>
        </FaroTable>
    );
};

export default StudySpaceTableSkeleton;
