import React, { forwardRef } from 'react';
import { FaroSkeleton, FaroTable, FaroTableProps } from '@faro/design-system';
import styles from './StudyDefinitionTable.module.scss';

export const StudyDefinitionTableSkeleton = forwardRef<HTMLTableElement, FaroTableProps>((props, ref) => {
    const { className = '', ...rest } = props;
    return (
        <FaroTable
            {...rest}
            ref={ref}
            className={`
                ${styles.table}
                ${className}
            `}
            appearance="bordered-minimally-interactive"
        >
            <thead>
                <tr>
                    <th>
                        <FaroSkeleton height="lg" />
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <FaroSkeleton height="lg" />
                    </td>
                </tr>
                <tr>
                    <td>
                        <FaroSkeleton height="lg" />
                    </td>
                </tr>
                <tr>
                    <td>
                        <FaroSkeleton height="lg" />
                    </td>
                </tr>
            </tbody>
        </FaroTable>
    );
});
