import {
    DynamicField,
    DynamicFieldResolution,
    toDynamicField,
    toDynamicFieldResolution,
} from '@faro/study-designer-model';
import { cqrs } from '@faro/http-clients';
import { StudyPathParameter } from '../parameter';

export type DynamicFieldPathParameters = StudyPathParameter;
export type DynamicFieldQueryParameters = {
    readonly search?: string;
    // NOTE: ids are comma separated ids of the dynamic fields
    readonly ids?: string;
    // NOTE: definitionIds are comma separated ids of the dynamic field definitions
    readonly definitionIds?: string;
    readonly entityReferenceIds?: string;
};

export type DynamicFieldResolutionQueryParameters = {
    studyId: string;
    ids: string;
};

/**
 * Client responsible for accessing the backend entity services
 */
export const dynamicFieldClient = {
    ...cqrs<DynamicField, DynamicFieldPathParameters>(
        '/api/study-designer/studies/:studyId/dynamic-fields',
        toDynamicField
    ).writableEntityClient<DynamicFieldQueryParameters>(),

    resolve: cqrs<DynamicFieldResolution, DynamicFieldPathParameters>(
        '/api/study-designer/studies/:studyId/dynamic-fields/actions/resolve',
        toDynamicFieldResolution
    ).list<DynamicFieldResolutionQueryParameters>(),
};
