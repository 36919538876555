import { StudyAssessmentMeasurement, toStudyAssessmentMeasurement } from '@faro/study-designer-model';
import { cqrs } from '@faro/http-clients';
import { StudyPathParameter } from '../parameter';
import batchApis from '../../util/batchApis';
import { AssessmentMeasurementQueryParameters } from '../BiomedicalAssessmentMeasurement/BiomedicalAssessmentMeasurementClient';

export type StudyAssessmentMeasurementsPathParameters = StudyPathParameter;

export interface StudyAssessmentMeasurementPaginatedQueryParameters
    extends Omit<AssessmentMeasurementQueryParameters, 'ids' | 'includeDeprecatedForStudyId'> {
    studyId: string;
}

/**
 * An interface that describes the available query parameters for the service that returns {@link StudyAssessmentMeasurement}s
 * for a specified study definition.
 */
export interface StudyAssessmentMeasurementsQueryParameters extends StudyAssessmentMeasurementsPathParameters {
    /**
     * A comma-separated list of the `oid`s for the {@link StudyAssessmentMeasurement} instances to retrieve.
     *
     * **NOTE:** These `ids` are not assessment/measurement identifiers; they are the unique key in the
     * `study_assessment_measurement` view.
     */
    readonly ids?: string;

    /**
     * When `true`, {@link StudyAssessmentMeasurement} instances that have been deprecated will be included in the
     * response.
     */
    readonly includeDeprecated?: boolean;

    /**
     * A comma-separated list of the assessment/measurement identifiers for the {@link StudyAssessmentMeasurement}s
     * in this study definition.
     *
     * **NOTE:** These are  the unique identifiers for the assessment/measurements, e.g. the ids that come from the
     * biomedical library.
     */
    readonly assessmentMeasurementIds?: string;
}

const studyAssessmentMeasurementClientBase = cqrs<
    StudyAssessmentMeasurement,
    StudyAssessmentMeasurementsPathParameters
>(
    '/api/study-designer/studies/:studyId/assessment-measurements',
    toStudyAssessmentMeasurement
).writableEntityClient<StudyAssessmentMeasurementsQueryParameters>();

/**
 * Client responsible for accessing the backend entity services for study assessment measurements
 */
export const studyAssessmentMeasurementClient = {
    ...studyAssessmentMeasurementClientBase,
    list: (query: StudyAssessmentMeasurementsQueryParameters): Promise<StudyAssessmentMeasurement[]> =>
        batchApis<StudyAssessmentMeasurementsQueryParameters, StudyAssessmentMeasurement>(
            query,
            studyAssessmentMeasurementClientBase.list,
            {
                property: 'ids',
                flattenResponses: responses => responses?.flat(),
            }
        ),
    listPaginated: cqrs<StudyAssessmentMeasurement>(
        '/api/study-designer/studies/:studyId/assessment-measurements/actions/search/paginated',
        toStudyAssessmentMeasurement
    ).listPaginated<StudyAssessmentMeasurementPaginatedQueryParameters>(),
};
