import { DraggableProvided } from '@hello-pangea/dnd';
import { RefObject } from 'react';

/**
 * TODO Remove if/when https://github.com/atlassian/react-beautiful-dnd/issues/1662 is resolved.
 *
 * Force complete the drop action by manually firing a CSS drop transformation end
 * if the drop animation has not completed within the allotted time.
 *
 * @param draggableProvided A reference to the draggable
 * @param draggingRef Reference holding information about whether or not a drag is currently active
 */
export function forceDrop(draggableProvided: DraggableProvided, draggingRef: RefObject<boolean>): void {
    if (typeof draggableProvided.draggableProps.onTransitionEnd === 'function') {
        setTimeout(() => {
            if (!draggingRef.current) return;
            // Manually trigger a transition completion
            draggableProvided.draggableProps.onTransitionEnd?.({
                propertyName: 'transform',
            } as any);
        }, 0.33);
    }
}
