import {
    ActivitySubGroup,
    CommandResponse,
    RevisionedCommandResponse,
    toActivitySubGroup,
} from '@faro/study-designer-model';
import { cqrs, setPathParameters } from '@faro/http-clients';
import { ScheduleOfActivitiesQueryParameter, StudyPathParameter } from '../parameter';
import axios from 'axios';

export type ActivitySubGroupPathParameters = StudyPathParameter;
export type ActivitySubGroupQueryParameters = ScheduleOfActivitiesQueryParameter;

/**
 * Client responsible for accessing the backend entity services
 */
export const activitySubGroupClient = {
    ...cqrs<ActivitySubGroup, ActivitySubGroupPathParameters>(
        '/api/study-designer/studies/:studyId/activity-subgroups',
        toActivitySubGroup
    ).writableEntityClient<ActivitySubGroupQueryParameters>(),

    move(
        request: ActivitySubGroupPathParameters & {
            id: string;
            placementType: 'within' | 'before' | 'after';
            relativeEntityId: string;
        }
    ): Promise<RevisionedCommandResponse<CommandResponse>> {
        return axios
            .post(
                setPathParameters(
                    '/api/study-designer/studies/:studyId/activity-subgroups/:id/actions/move',
                    request as any
                ),
                request
            )
            .then(response => response.data);
    },
};
