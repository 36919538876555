import { toVariant, Variant } from '@faro/study-designer-model';
import { cqrs } from '@faro/http-clients';
import { PopulationQueryParameter, StudyPathParameter } from '../parameter';

export type VariantPathParameters = StudyPathParameter;
export type VariantQueryParameters = PopulationQueryParameter & {
    readonly parentId?: string;
    readonly types?: string[];
};

/**
 * Client responsible for accessing the backend variant entity services
 */
export const variantClient = cqrs<Variant, VariantPathParameters>(
    '/api/study-designer/studies/:studyId/variants',
    toVariant
).readableEntityClient<VariantQueryParameters>();
