import { studyDataHookFactory } from '../studyDataHookFactory';
import { ActivitySubGroup, StudyEntityType } from '@faro/study-designer-model';
import { activitySubGroupClient } from './ActivitySubGroupClient';

const factory = studyDataHookFactory.entity<ActivitySubGroup>(StudyEntityType.ActivitySubGroup);

export const useActivitySubGroups = factory.list(activitySubGroupClient.list);
export const useActivitySubGroupInsert = factory.mutate(activitySubGroupClient.create);
export const useActivitySubGroupUpdate = factory.mutate(activitySubGroupClient.update);
export const useActivitySubGroupDelete = factory.mutate(activitySubGroupClient.delete);
