import { SystemDefinedObject, toRequiredSystemDefinedObject } from '@faro/study-designer-model';
import { cqrs } from '@faro/http-clients';

/**
 * Client responsible for managing intervention dose relative unit
 */
export const doseRelativeUnitClient = cqrs<SystemDefinedObject>(
    '/api/study-designer/interventions/doses/relative-units',
    toRequiredSystemDefinedObject
).listableEntityClient();
