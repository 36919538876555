import type { Identified } from '@faro/realtime-services';
import { EntityCreateResponse, cqrs, setPathParameters } from '@faro/http-clients';
import {
    EntityCommandCreateResponse,
    RevisionedCommandResponse,
    Study,
    StudyStatus,
    toStudy,
} from '@faro/study-designer-model';
import axios, { AxiosResponse } from 'axios';

export type StudyQueryParameters = {
    readonly includeDeleted?: boolean;
};

export interface StudyStatusUpdate extends StudyStatus {
    id: string;
}

export const studyClient = {
    ...cqrs<Study>('/api/study-designer/studies', toStudy).writableEntityClient<StudyQueryParameters>(),

    clone: cqrs<Study, Identified>('/api/study-designer/studies/:id/actions/clone').create(),

    updateStatus: (entity: Omit<Partial<Identified> & StudyStatusUpdate, 'id'>): Promise<EntityCreateResponse> => {
        const path = setPathParameters('/api/study-designer/studies/:id/actions/update-status', entity as any);
        const { id, ...body } = entity as Identified & StudyStatusUpdate;
        return axios
            .post<
                Omit<Partial<Identified> & StudyStatusUpdate, 'id'>,
                AxiosResponse<RevisionedCommandResponse<EntityCommandCreateResponse>>
            >(path, body)
            .then(response => ({
                id: response.data.entityId,
                revision: response.data.revision,
            }));
    },
};
