import { InsightDay, toInsightDay } from '@faro/study-designer-model';
import { cqrs, setPathParameters } from '@faro/http-clients';
import { ScheduleOfActivitiesQueryParameter, StudyPathParameter, VariantQueryParameter } from '../parameter';
import axios, { AxiosResponse } from 'axios';

export type InsightDayPathParameters = StudyPathParameter;
export type ExportInsightsDataQueryParameters = ScheduleOfActivitiesQueryParameter &
    VariantQueryParameter & {
        overlayId?: string;
        revision?: number;
        timeZone?: string;
    };

/**
 * Client responsible for accessing {@link InsightDay}
 */
export const insightDayClient = {
    ...cqrs<InsightDay, InsightDayPathParameters>(
        '/api/study-designer/studies/:studyId/insight-days',
        toInsightDay
    ).listableEntityClient<InsightDayPathParameters & ExportInsightsDataQueryParameters>(),

    export: (query: ExportInsightsDataQueryParameters): Promise<AxiosResponse<any, any>> => {
        return axios.get(setPathParameters('/api/study-designer/studies/:studyId/insight-days/export', query as any), {
            params: { ...query },
            headers: { accept: 'text/csv' },
            responseType: 'text',
        });
    },
};
