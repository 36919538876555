import { ComponentPropsWithRef, forwardRef } from 'react';
import { FaroButton, FaroMenu, FaroOverlayTrigger } from '@faro/design-system';
import { ChevronDownIcon } from '@faro/icons';
import styles from './StudyStatusListBox.module.scss';
import { StudyStatusType } from '@faro/study-designer-model';
import { useStudySpacePermissions } from '../../package/react-hooks/useStudySpacePermissions';

export interface StudyStatusListBoxProps extends ComponentPropsWithRef<'div'> {
    readonly readOnly?: boolean;
    /**
     * Study Definition Id
     */
    readonly studyDefinitionId: string;

    /**
     * Currently selected study definition status type
     */
    readonly selectedStatusType: StudyStatusType;

    /**
     * update study state
     * @param statusType
     */
    onStatusTypeUpdate: (studyDefinitionId: string, statusType: StudyStatusType) => void;
}

interface StatusOptions {
    type: StudyStatusType;
    label: string;
    color: string;
}

const StudyStatusListBox = forwardRef<HTMLDivElement, StudyStatusListBoxProps>((props, ref) => {
    const { studyDefinitionId, readOnly, selectedStatusType, onStatusTypeUpdate, ...rest } = props;
    const permissions = useStudySpacePermissions();
    const statusOptions: StatusOptions[] = [
        {
            type: 'editable',
            label: 'Editable',
            color: 'info',
        },
        {
            type: 'read-only',
            label: 'Read only',
            color: 'warning',
        },
        {
            type: 'locked',
            label: 'Locked',
            color: 'disabled',
        },
    ];

    const selectedStatus = statusOptions.find(s => s.type === selectedStatusType);

    if (typeof selectedStatus === 'undefined') {
        return (
            <FaroButton size="small" disabled>
                Invalid Status
            </FaroButton>
        );
    }

    const options = statusOptions
        .filter(status => status.type !== selectedStatusType)
        .map(status => (
            <FaroMenu.Item
                key={status.type}
                onClick={() => onStatusTypeUpdate(studyDefinitionId, status.type)}
                disabled={readOnly}
                data-automation-id={`transition-to-${status.type}`}
            >
                Transition to
                <FaroButton
                    size="small"
                    className={`${styles.listBoxButton} ${(styles as any)[status.color]}`}
                    tabIndex={-1}
                >
                    {status.label}
                </FaroButton>
            </FaroMenu.Item>
        ));

    return (
        <div {...rest} ref={ref} className={styles.listBox}>
            <FaroOverlayTrigger
                strategy="fixed"
                openOnClick
                overlay={
                    <FaroMenu className={styles.listBoxMenu} data-automation-id="statusListMenu">
                        {options}
                    </FaroMenu>
                }
                placement={'bottom-start'}
            >
                <FaroButton
                    icon={<ChevronDownIcon />}
                    iconRight
                    size="small"
                    className={`${styles.listBoxButton} ${(styles as any)[selectedStatus.color]}`}
                    disabled={readOnly || (selectedStatus.type === 'locked' && !permissions.studyDefinitionAdmin)}
                >
                    {selectedStatus.label}
                </FaroButton>
            </FaroOverlayTrigger>
        </div>
    );
});

export default StudyStatusListBox;
