export interface TableRowDraggableStyleOptions {
    /**
     * If true, the leftmost column will be considered a gutter column and will be calculated out of the drag display
     * @default true
     */
    leftGutter?: boolean;
    /**
     * If true, the rightmost column will be considered a gutter column and will be calculated out of the drag display
     * @default true
     */
    rightGutter?: boolean;
}

/**
 * Computes measurements needed to properly render a dragged table row in the react-beautiful-dnd framework
 * @param scrollContainer The scroll container
 * @param options The measurement options
 */
export function tableRowDraggableStyle(
    scrollContainer?: HTMLElement | null,
    options: TableRowDraggableStyleOptions = {}
): {
    '--scroll-left': string;
    '--clip-inset-left': string;
    '--clip-inset-right': string;
} {
    /*
     * This handles the sticky column while user is dragging the table rows
     * --scroll-left is a CSS var that will set the 'left' property of the sticky columns
     * --clip-inset-left and --clip-inset-right hides the rows when dragging
     */
    const left = (scrollContainer?.scrollLeft || 0) + 1;

    // Measure sticky cells
    const { leftGutter = true, rightGutter = true } = options;
    const insetLeft = leftGutter
        ? left +
          ((
              scrollContainer?.querySelector('tbody > tr:first-child > :first-child') as HTMLElement
          )?.getBoundingClientRect().width || 0)
        : left;

    const rightGutterWidth = rightGutter
        ? (
              scrollContainer?.querySelector('tbody > tr:first-child > :last-child') as HTMLElement
          )?.getBoundingClientRect().width || 0
        : 0;

    return {
        '--scroll-left': `${left}px`,
        '--clip-inset-left': `${insetLeft - 1}px`,
        '--clip-inset-right': `${
            scrollContainer == null
                ? rightGutterWidth - 1
                : Math.max(
                      0,
                      scrollContainer.scrollWidth -
                          scrollContainer.clientWidth -
                          scrollContainer.scrollLeft +
                          rightGutterWidth -
                          1
                  )
        }px`,
    };
}
