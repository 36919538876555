import { AssessmentMeasurementMetadata, toAssessmentMeasurementMetadata } from '@faro/study-designer-model';
import { cqrs } from '@faro/http-clients';
import batchApis from '../../util/batchApis';

export interface AssessmentMeasurementMetadataQueryParameters {
    /**
     * Restricts the search results just to these ids
     */
    readonly ids?: string;
}

/**
 * Client responsible for managing assessment measurement
 */
export const assessmentMeasurementMetadataClientBase = cqrs<AssessmentMeasurementMetadata>(
    '/api/study-designer/assessment-measurements-metadata',
    toAssessmentMeasurementMetadata
).readableEntityClient<AssessmentMeasurementMetadataQueryParameters>();

export const assessmentMeasurementMetadataClient = {
    ...assessmentMeasurementMetadataClientBase,
    list: (
        query: AssessmentMeasurementMetadataQueryParameters,
        signal?: AbortSignal
    ): Promise<AssessmentMeasurementMetadata[]> =>
        batchApis<AssessmentMeasurementMetadataQueryParameters, AssessmentMeasurementMetadata>(
            query,
            assessmentMeasurementMetadataClientBase.list,
            {
                property: 'ids',
                flattenResponses: responses => responses?.flat(),
            },
            signal
        ),
};
