import { InterventionGroupSequence, toInterventionGroupSequence } from '@faro/study-designer-model';
import { cqrs } from '@faro/http-clients';
import { PopulationQueryParameter, StudyPathParameter } from '../parameter';

export type InterventionGroupSequencePathParameters = StudyPathParameter;
export type InterventionGroupSequenceQueryParameters = PopulationQueryParameter;

/**
 * Client responsible for accessing the backend entity services
 */
export const interventionGroupSequenceClient = cqrs<InterventionGroupSequence, InterventionGroupSequencePathParameters>(
    '/api/study-designer/studies/:studyId/intervention-group-sequences',
    toInterventionGroupSequence
).listableEntityClient<InterventionGroupSequenceQueryParameters>();
