import { Endpoint, toEndpoint } from '@faro/study-designer-model';
import { cqrs } from '@faro/http-clients';
import { StudyPathParameter } from '../parameter';

export type EndpointPathParameters = StudyPathParameter;

const factory = cqrs<Endpoint, EndpointPathParameters>('/api/study-designer/studies/:studyId/endpoints', toEndpoint);

export const endpointClient = {
    ...factory.writableEntityClient(),
    clone: factory.clone(),
    move: factory.move(),
};
