import React, { ComponentPropsWithoutRef } from 'react';
import { NamedObject, SystemDefinedObject } from '@faro/study-designer-model';
import { FaroHighlightable, Option } from '@faro/design-system';
import { namedObjectToOption } from './namedObjectToOption';
import { isBlank } from './isBlank';

/**
 * Creates the select options for a named object select where the options may be searched
 * @param search the search string entered by the user
 * @param values All available options for the select
 * @param value The current field value
 */
export function transformSearchToOptions(
    search: string,
    values: SystemDefinedObject[],
    value: NamedObject | undefined,
    className: string
): Option<NamedObject>[] & ComponentPropsWithoutRef<'div'> {
    const options: Option<NamedObject>[] = values.map(namedObjectToOption);
    const normalizedSearch = search.trim().toLowerCase();

    // If the current input value isn't listed, list it
    const optionsWithCreatedOption =
        value != null &&
        !options.some(x => x.value.name === value?.name) &&
        value?.name.toLowerCase().includes(normalizedSearch)
            ? [...options, namedObjectToOption(value as SystemDefinedObject)]
            : options;

    return optionsWithCreatedOption.map(option => ({
        ...option,
        label: !isBlank(search) ? (
            <div className={className ? className : ''}>
                <FaroHighlightable text={option.label as string} search={search} />
            </div>
        ) : (
            option.label
        ),
    }));
}
