import React, { forwardRef } from 'react';
import { FaroOverlayModal, FaroOverlayModalProps, FaroOverlayRef, Option } from '@faro/design-system';
import { StudyDefinitionExportForm, StudyDefinitionExportFormValues } from './StudyDefinitionExportForm';
import { AnalyticsEventKeys, ProductTypes, useAnalytics, useAppContext, usePageOverlay } from '@faro/app-context';
import { StudyDefinitionReference } from '../type/StudyDefinitionReference';
import { StudyDefinitionExportFormat } from '../service/StudyDefinitionExportService/StudyDefinitionExportService';
import { useLocation, useNavigate } from 'react-router-dom';
import { DocumentScrapingStudyDefinitionExportService } from '../service/StudyDefinitionExportService/DocumentScrapingStudyDefinitionExportService';
import { useQueryClient } from '@tanstack/react-query';
import { FeatureFlag } from '@faro/study-designer-model';
import { StudyDefinitionExportFormDisplayText } from './StudyDefinitionExportFormDisplayText/StudyDefinitionExportFormDisplayText';
import { useAppPermissions } from '../../auth';
import { useUser } from '@faro/auth';

/**
 * An interface for configuring the initial state of the Document Export Popover form.
 */
export interface StudyDefinitionExportFormModalProps extends Omit<FaroOverlayModalProps, 'ref'> {
    studyDefinition: StudyDefinitionReference;
    isSubStudy?: boolean;

    onExportSubmit?(values: StudyDefinitionExportFormValues): void;
}

const exportDocxOption: Option<StudyDefinitionExportFormat> = {
    key: 'docx',
    label: (
        <StudyDefinitionExportFormDisplayText
            text="Microsoft Word (.docx)"
            description="Only the master study definition is exported"
        />
    ),
    value: 'docx',
};

const exportDocxSubStudyOption: Option<StudyDefinitionExportFormat> = {
    key: 'docx',
    label: (
        <StudyDefinitionExportFormDisplayText
            text="Microsoft Word (.docx)"
            description="Only the substudy definition is exported"
        />
    ),
    value: 'docx',
};

const legacyJsonFormatOption: Option<StudyDefinitionExportFormat> = {
    key: 'json',
    label: (
        <StudyDefinitionExportFormDisplayText
            text="Faro Standard Format (.json)"
            description={<>Export for downstream systems</>}
        />
    ),
    value: 'json',
};

const faroStandardFormatOption: Option<StudyDefinitionExportFormat> = {
    key: 'faro-standard-json-format',
    label: (
        <StudyDefinitionExportFormDisplayText
            text="Faro Import Format (.json)"
            description={
                <>
                    Includes master study definition &middot; may include substudy definitions; file for Internal Import
                </>
            }
        />
    ),
    value: 'faro-standard-json-format',
};

const faroStandardSubStudyFormatOption: Option<StudyDefinitionExportFormat> = {
    key: 'faro-standard-json-format',
    label: (
        <StudyDefinitionExportFormDisplayText
            text="Faro Import Format (.json)"
            description={<>Includes substudy definition; file for Internal Import</>}
        />
    ),
    value: 'faro-standard-json-format',
};

const jsonFormats: StudyDefinitionExportFormat[] = ['json', 'faro-standard-json-format'];

/**
 * Get the appropriate format options based user permissions.
 * If a user has the `STUDY-DEFINITION-ADMIN` role, they are allowed to use the "Faro Standard Format"
 * export option.
 *
 * @param isStudyDefinitionAdmin Indicate whether the logged-in user has the `STUDY-DEFINITION-ADMIN` role
 * @returns `Option<StudyDefinitionExportFormat>[]` with the appropriate format options.
 */
function getExportFormatOptions(
    isStudyDefinitionAdmin: boolean,
    isSubStudy: boolean
): Option<StudyDefinitionExportFormat>[] {
    const baseOptions = [isSubStudy ? exportDocxSubStudyOption : exportDocxOption, legacyJsonFormatOption];
    if (!isStudyDefinitionAdmin) {
        return baseOptions;
    }

    return [...baseOptions, isSubStudy ? faroStandardSubStudyFormatOption : faroStandardFormatOption];
}

/**
 * Read the {@link FeatureFlag}s from the query cache and get the value/setting of the specified feature flag identifier.
 *
 * This component needs to read from the query cache instead of the `featureFlagService` from the `ServiceContext`
 * because it is used in multiple modules (i.e. study designer and study spaces).  Each `ServiceContext` is different,
 * so when this component walks up its component tree to find the `ServiceContext`, it cannot find the `ServiceContext`
 * it expects (e.g. this component references the study designer's `ServiceContext`, but study spaces is not built with
 * that `StudyContext`, so this component finds... nothing).
 *
 * The query cache (managed by `react-query`) is shared across modules.  The feature flags are loaded into the cache
 * early on in the application's lifecycle, meaning it's safe to query the cache and get the feature flags.
 */
function getFeatureFlagsFromQueryCache(): { [flag: string]: boolean } {
    const queryClient = useQueryClient();
    const featureFlagCache = queryClient.getQueryData<FeatureFlag[]>(['featureFlags']);
    return (featureFlagCache || []).reduce(
        (flags, flag) => {
            if (flag.enabled) {
                flags[flag.id] = true;
            }
            return flags;
        },
        {} as { [flag: string]: boolean }
    );
}

export const StudyDefinitionExportFormModal = forwardRef<HTMLDivElement, StudyDefinitionExportFormModalProps>(
    (props, ref) => {
        const { studyDefinition, isSubStudy = false, onExportSubmit, ...rest } = props;

        const { trackEvent } = useAnalytics();

        const user = useUser();
        const location = useLocation();
        const previousRoute = `${location.pathname}${location.search}${location.hash}`;

        const navigate = useNavigate();
        const featureFlags = getFeatureFlagsFromQueryCache();
        const exportService = new DocumentScrapingStudyDefinitionExportService({
            navigate,
            previousRoute,
            featureFlags,
        });

        const permissions = useAppPermissions();
        const isStudyDefinitionAdmin = permissions.study.write && permissions.exportInsights.write;

        const overlayRef = rest.overlayRef as FaroOverlayRef;

        const { showErrorNotification } = useAppContext();
        const { openOverlayThenDelay, delayThenCloseOverlay } = usePageOverlay();
        const formatOptions = getExportFormatOptions(isStudyDefinitionAdmin, isSubStudy);

        const initialValues: StudyDefinitionExportFormValues = {
            format: formatOptions[0]!.value as StudyDefinitionExportFormat,
            isSubStudy,
        };

        function onCancel(): void {
            overlayRef?.close();
        }

        async function onSubmit(values: StudyDefinitionExportFormValues): Promise<void> {
            onExportSubmit?.(values);
            overlayRef?.close();

            const { format, isSubStudy } = values;
            const showOverlay = !jsonFormats.includes(format);
            if (showOverlay) {
                await openOverlayThenDelay(750);
            }
            try {
                await exportService.export(user, studyDefinition, format, isSubStudy).then(() => {
                    trackExport(format, isSubStudy);
                });
            } catch (error) {
                showErrorNotification(`Failed to export study definition as ${format}`, error);
            } finally {
                if (showOverlay) {
                    await delayThenCloseOverlay(0);
                }
            }
        }

        function trackExport(format: StudyDefinitionExportFormat, isSubStudy: boolean): void {
            if (format === 'docx') {
                const wordEvent = isSubStudy
                    ? AnalyticsEventKeys.exportSubStudyDefinitionWord
                    : AnalyticsEventKeys.exportStudyDefinitionWord;
                trackEvent(wordEvent, {
                    product: ProductTypes.studyDesigner,
                });
            } else if (format === 'json') {
                const jsonEvent = isSubStudy
                    ? AnalyticsEventKeys.exportSubStudyDefinitionStandardJson
                    : AnalyticsEventKeys.exportStudyDefinitionStandardJson;
                trackEvent(jsonEvent, {
                    product: ProductTypes.studyDesigner,
                });
            } else if (format === 'faro-standard-json-format') {
                const jsonEvent = isSubStudy
                    ? AnalyticsEventKeys.exportSubStudyDefinitionImportJson
                    : AnalyticsEventKeys.exportStudyDefinitionImportJson;
                trackEvent(jsonEvent, {
                    product: ProductTypes.studyDesigner,
                });
            }
        }

        return (
            <FaroOverlayModal showCloseButton ref={ref} {...rest}>
                <StudyDefinitionExportForm
                    formatOptions={formatOptions}
                    initialValues={initialValues}
                    isSubStudy={isSubStudy}
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                />
            </FaroOverlayModal>
        );
    }
);
