import { ScheduleOfActivities, toScheduleOfActivities } from '@faro/study-designer-model';
import { cqrs } from '@faro/http-clients';
import { StudyDesignQueryParameter, StudyPathParameter } from '../parameter';

export type ScheduleOfActivitiesPathParameters = StudyPathParameter;
export type ScheduleOfActivitiesQueryParameters = StudyDesignQueryParameter;

/**
 * Client responsible for accessing the backend entity services
 */

const cqrsClientFactory = cqrs<ScheduleOfActivities, ScheduleOfActivitiesPathParameters>(
    '/api/study-designer/studies/:studyId/schedule-of-activities',
    toScheduleOfActivities
);

export const scheduleOfActivitiesClient = {
    ...cqrsClientFactory.writableEntityClient(),
    clone: cqrsClientFactory.clone(),
    move: cqrsClientFactory.move(),
};
