import { createResourceQuery } from '@faro/resource-query';
import { ServiceContextValue } from '../../../context';
import { StudySpace } from '@faro/study-space-service-model';
import { AxiosError } from 'axios';
import { studySpaceEntityType } from '../../../service';

interface StudyDefinitionMenuData {
    spaces: StudySpace[];
}

export interface StudyDefinitionMenuResolverArgs {
    context: ServiceContextValue;
}

export const useStudyDefinitionMoveModalData = createResourceQuery<
    StudyDefinitionMenuData,
    undefined,
    ServiceContextValue,
    AxiosError
>({
    id: 'studyDefinitionMenuData',
    resolve: async ({ context }: StudyDefinitionMenuResolverArgs) => {
        const { studySpaceService } = context;
        const [spaces] = await Promise.all([studySpaceService.list()]);
        return {
            spaces,
        };
    },
    queryKeys: [studySpaceEntityType],
});
