/**
 * Content disposition can contain a couple of file names, escaped and non-escaped, in case of special chars used,
 * Picking the un-escaped one
 * Example of content-disposition:
 * attachment; filename="sample_tenant.Test LTerm Follow?.TestStudyId.0.610e3284.docx"; filename*=UTF-8''sample_tenant.Test%20LTerm%20Follow%E2%87%A1.TestStudyId.0.610e3284.docx
 * @param contentDisposition
 * @param ext
 * @param defaultFilename
 */
export function getFilenameFromContentDisposition(
    contentDisposition: string | null,
    ext: string = 'docx',
    defaultFilename = 'study'
): string {
    if (contentDisposition == null) return `${defaultFilename}.${ext}`;
    const start =
        contentDisposition.indexOf('filename="') === -1
            ? contentDisposition.indexOf('filename=') + 'filename='.length
            : contentDisposition.indexOf('filename="') + 'filename="'.length;

    const end = contentDisposition.indexOf(ext);
    if (start != -1 && end != -1) {
        const name = contentDisposition.substring(start, end + ext.length);
        return name || `${defaultFilename}.${ext}`;
    }
    return contentDisposition;
}
