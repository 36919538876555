import { NavigateFunction } from 'react-router-dom';
import { StudyDefinitionReference } from '../../type/StudyDefinitionReference';
import axios from 'axios';
import { DocumentMimeType, StudyContentDocumentRequest, toSystemConfiguration } from '@faro/document-management-model';
import { getFilenameFromContentDisposition } from '../../support/getFilenameFromContentDisposition';
import { Parser } from '@faro/document-management-parser';
import { StudyDefinitionExportFormat, StudyDefinitionExportService } from './StudyDefinitionExportService';
import { download } from '../../support/download';
import { DefaultDocumentManagementService } from '../DocumentManagement';
import { ExportService } from '../Export';
import { AuditEvent, StudyRevision } from '@faro/study-designer-model';
import { studyRevisionClient } from '../../../../service/StudyRevision/StudyRevisionClient';
import { isEmpty } from 'lodash';
import { UserInfo } from '@faro/user-management-model';
import { AuditEventService } from '../../../../service';

export interface DocumentScrapingStudyDefinitionExportServiceOptions {
    navigate: NavigateFunction;
    previousRoute: string;
    featureFlags?: { [flag: string]: boolean };
}

export class DocumentScrapingStudyDefinitionExportService implements StudyDefinitionExportService {
    private readonly jsonExportService = new ExportService();
    private readonly documentExportService = new DefaultDocumentManagementService();
    private readonly auditEventService = new AuditEventService();

    constructor(private readonly options: DocumentScrapingStudyDefinitionExportServiceOptions) {}

    async export(
        user: UserInfo,
        studyDefinition: StudyDefinitionReference,
        format: StudyDefinitionExportFormat,
        isSubStudy: boolean
    ): Promise<void> {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        const revisionResponse = await studyRevisionClient.listPaginated({
            studyId: studyDefinition.id,
            page: 1,
            size: 1,
            includeSystemGeneratedEvents: true,
        });
        const revision = revisionResponse.content[0] as StudyRevision;

        switch (format) {
            case 'json': {
                await this.jsonExportService.exportJson({
                    studyId: studyDefinition.id,
                    revision: revision.revision,
                });
                break;
            }
            case 'faro-standard-json-format': {
                await this.jsonExportService.exportFaroStandardJsonFormat({
                    studyId: studyDefinition.id,
                });
                break;
            }
            case 'docx': {
                const formData = await this.getDocumentAsFormData(studyDefinition, revision);
                const response = await this.documentExportService.exportDocxDocument(
                    formData as StudyContentDocumentRequest,
                    { timeZone }
                );
                const dataUrl = window.URL.createObjectURL(
                    new Blob([response.data], { type: DocumentMimeType.MS_WORD_DOCUMENT })
                );
                const fileName = getFilenameFromContentDisposition(response.headers['content-disposition'] || '');
                const auditEvent = {
                    type: 'study-designer-export',
                    body: {
                        userId: user.id,
                        studyId: studyDefinition.id,
                        studyRevision: revision.revision,
                        exportType: 'docx',
                        description: `Export ${isSubStudy ? 'substudy' : 'master study'} as docx by user: [${
                            user.id
                        }] studyId: [${studyDefinition.id}] revision: [${revision.revision}]`,
                    },
                } as AuditEvent;
                await this.auditEventService.create(auditEvent);
                download(dataUrl, fileName);
                break;
            }
        }
    }

    private async getDocumentAsFormData(
        studyDefinition: StudyDefinitionReference,
        revision: StudyRevision
    ): Promise<FormData> {
        try {
            const documentConfiguration = await axios
                .get('/api/document-management/system/config')
                .then(response => toSystemConfiguration(response.data));

            const { maxcolumns: maximumTableColumns = 50 } = documentConfiguration;

            const getStudyDefinitionSectionRoute = (studyDefinition: StudyDefinitionReference, sectionId?: string) => {
                return sectionId == null
                    ? `/studies/${studyDefinition.id}`
                    : `/studies/${studyDefinition.id}/elements/${sectionId}`;
            };

            const waitForSection = async (sectionId: string | undefined) => {
                const route = getStudyDefinitionSectionRoute(studyDefinition, sectionId);
                await this.navigateAndWaitForLoaded(route, {
                    timeout: 10 * 1000, // 10s (5s was timing out...)
                });
            };

            const parser = new Parser({
                featureFlags: this.options.featureFlags,
                maximumTableColumns,
                waitForSection,
            });

            const scrapedStudyDefinitionAsString = await parser.studyDefinitionElementsToDocumentHTML({
                studyId: studyDefinition.id,
                revisionDate: revision.created,
                revisionName: revision.attribute?.name,
            });

            const file = new Blob([scrapedStudyDefinitionAsString], { type: 'text' });
            const formData = new FormData();
            formData.append('file', file, 'html.txt');
            formData.append('studyId', studyDefinition.id);
            formData.append('revision', '' + (revision.revision || ''));
            if (!isEmpty(studyDefinition.name)) {
                formData.append('title', studyDefinition.name!);
            }
            if (!isEmpty(studyDefinition.number)) {
                formData.append('number', studyDefinition.number!);
            }
            return formData;
        } finally {
            await this.navigateAndWaitForLoaded(this.options.previousRoute, { throwOnTimeout: false });
        }
    }

    private async navigateAndWaitForLoaded(
        route: string,
        options?: { timeout?: number; throwOnTimeout?: boolean }
    ): Promise<any> {
        const baseHref = document.querySelector('base')?.getAttribute('href') || '/';
        const routeWithBaseHref = `${baseHref}${route.startsWith('/') ? route.substring(1) : route}`;

        if (location.pathname === routeWithBaseHref) {
            return;
        }

        const timeout = options?.timeout || 5000;
        const throwOnTimeout = options?.throwOnTimeout != null ? options.throwOnTimeout : true;

        const { navigate } = this.options;
        navigate(route, { replace: true });

        await Promise.race([
            new Promise<void>(resolve => {
                document.addEventListener(
                    'study-sections-loaded',
                    () => {
                        resolve();
                    },
                    { once: true }
                );
            }),
            new Promise<void>((resolve, reject) => {
                setTimeout(() => {
                    if (throwOnTimeout) {
                        reject(new Error(`Timed out while waiting for ${route} to load`));
                    } else {
                        resolve();
                    }
                }, timeout);
            }),
        ]);
    }
}
