import React, { Context, useContext } from 'react';
import {
    CommandService,
    StudySpaceService,
    StudySpaceChangeEventService,
    StudyPhasesService,
    DiseaseAreasService,
    TherapeuticAreasService,
    StudyDefinitionReferenceService,
} from '../../service';
import { PollingChangeEventService, StudyService } from '@faro/study-designer-services';
import { StudyDefinitionImportService } from '../../service/import/StudyDefinitionImportService';
import { UserInfoService } from '@faro/app-context';

/**
 * This context functions as our service registry, allowing consuming components to access services.
 */
export interface ServiceContextValue {
    readonly studySpaceChangeEventService: StudySpaceChangeEventService;
    readonly studyChangeEventService: PollingChangeEventService;
    readonly commandService: CommandService;
    readonly studySpaceService: StudySpaceService;
    readonly studyDefinitionReferenceService: StudyDefinitionReferenceService;
    readonly diseaseAreasService: DiseaseAreasService;
    readonly userInfoService: UserInfoService;
    readonly therapeuticAreasService: TherapeuticAreasService;
    readonly studyPhasesService: StudyPhasesService;
    readonly studyService: StudyService;
    readonly studyDefinitionImportService: StudyDefinitionImportService;
}

export const ServiceContext: Context<ServiceContextValue> = React.createContext(<ServiceContextValue>{});

export function useServiceContext(): ServiceContextValue {
    return useContext(ServiceContext);
}

export default ServiceContext;
