/**
 * Locks the width of each cell of a table
 * @param tableElement The table element to lock the cells widths for
 */
export function lockTableCellWidths(tableElement: HTMLTableElement): void {
    const columnSizers = Array.from(tableElement.querySelectorAll<HTMLElement>('tbody > tr:first-child > *'));
    const cells = Array.from(tableElement.querySelectorAll<HTMLElement>('th,td'));
    const columnWidths = columnSizers.map(element => element.getBoundingClientRect().width);

    tableElement.style.setProperty('table-layout', 'fixed');
    tableElement.style.setProperty('width', `${tableElement.getBoundingClientRect().width}px`);
    cells.forEach((element, index) => {
        element.style.setProperty('width', `${columnWidths[index % columnWidths.length]}px`);
    });
}

/**
 * Unlocks the cell widths of a table
 * @param tableElement The table to unlock the cell widths for
 */
export function unlockTableCellWidths(tableElement: HTMLTableElement): void {
    const cells = Array.from(tableElement.querySelectorAll<HTMLElement>('th,td'));

    tableElement.style.setProperty('table-layout', null);
    tableElement.style.setProperty('width', null);
    cells.forEach(element => {
        element.style.setProperty('width', null);
    });
}
