import { cqrs } from '@faro/http-clients';
import { PaginatedQuery } from '../Pagination/Pagination.types';
import { AssessmentMeasurement, toAssessmentMeasurement } from '@faro/study-designer-model';

export type AssessmentMeasurementSourceType = 'library' | 'inStudy';

export interface AssessmentMeasurementQueryParameters extends AssessmentMeasurementFilters, PaginatedQuery {
    /**
     * Restricts the search results just to these ids
     */
    readonly ids?: string;
    readonly searchable?: boolean;
    readonly searchPattern?: string;
    /**
     * Represents the source to search for the assessment measurement.
     */
    readonly source?: AssessmentMeasurementSourceType;
    /**
     * Include assessment measurements for the study id even if they are deprecated
     */
    readonly includeDeprecatedForStudyId?: string;
}

export interface AssessmentMeasurementFilters {
    readonly classType?: string[];
    readonly sampleType?: string[];
    readonly therapeuticArea?: string[];
    readonly includeDeprecated?: boolean;
    readonly mostCommon?: boolean;
    readonly system?: string[];
    /**
     * If {@code true} only those refs with updates available will be returned
     */
    readonly updatesOnly?: boolean;

    /**
     * If {@code true} only those refs with optional configurations be returned
     */
    readonly isOptionalOnly?: boolean;
}

/**
 * Client responsible for managing assessment measurement
 */
export const biomedicalAssessmentMeasurementClient = {
    ...cqrs<AssessmentMeasurement>(
        '/api/study-designer/assessment-measurements',
        toAssessmentMeasurement
    ).readableEntityClient<AssessmentMeasurementQueryParameters>(),
    listPaginated: cqrs<AssessmentMeasurement>(
        '/api/study-designer/assessment-measurements',
        toAssessmentMeasurement
    ).listPaginated<AssessmentMeasurementQueryParameters>(),
};
