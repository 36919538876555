import { studyDataHookFactory } from '../studyDataHookFactory';
import { ActivityGroup, StudyEntityType } from '@faro/study-designer-model';
import { activityGroupClient } from './ActivityGroupClient';

const factory = studyDataHookFactory.entity<ActivityGroup>(StudyEntityType.ActivityGroup);

export const useActivityGroups = factory.list(activityGroupClient.list);
export const useActivityGroupInsert = factory.mutate(activityGroupClient.create);
export const useActivityGroupUpdate = factory.mutate(activityGroupClient.update);
export const useActivityGroupDelete = factory.mutate(activityGroupClient.delete);
