import { studyDataHookFactory } from '../studyDataHookFactory';
import { ScheduledDay, StudyEntityType } from '@faro/study-designer-model';
import { scheduledDayClient } from './ScheduledDayClient';

const factory = studyDataHookFactory.entity<ScheduledDay>(StudyEntityType.ScheduledDay);

export const useScheduledDays = factory.list(scheduledDayClient.list);
export const useScheduledDayClone = factory.mutate(scheduledDayClient.clone);
export const useScheduledDayUpdate = factory.mutate(scheduledDayClient.update);
export const useScheduledDayDelete = factory.mutate(scheduledDayClient.delete);
