import React, { ReactNode } from 'react';
import styles from './SpacePageSideBar.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { spacePageSectionsData } from '../../spaces/:id/spacePageSectionsData';
import { FaroNavigationSectionLabel, FaroNavigationTree, scrollToElement } from '@faro/design-system';
import { usePageContext, withResolvedHref } from '@faro/app-context';

const NavigationTreeNodeWithResolvedHref = withResolvedHref(FaroNavigationTree.Node);

export interface SpacePageSideBarProps {
    loading?: boolean;
    outlet?: ReactNode;
}

function SpacePageSideBar(props: SpacePageSideBarProps): React.JSX.Element {
    const { outlet } = props;
    const navigate = useNavigate();
    const location = useLocation();
    const pathname = location.pathname;

    const { activeSectionId, setActiveSectionIdPauseScrollSpy } = usePageContext();

    return (
        <>
            {outlet != null ? (
                outlet
            ) : (
                <div className={styles.spacePageNavLinks}>
                    <FaroNavigationSectionLabel>Study Space</FaroNavigationSectionLabel>
                    <FaroNavigationTree fullWidth selection={activeSectionId}>
                        {spacePageSectionsData.map(section => (
                            <NavigationTreeNodeWithResolvedHref
                                key={section.id}
                                id={section.id}
                                icon={section.icon}
                                label={section.label}
                                onSelect={() => {
                                    navigate(`${pathname}#${section.id}`);
                                    setActiveSectionIdPauseScrollSpy(section.id);
                                    scrollToElement(document.querySelector<HTMLElement>(`section[id="${section.id}"]`));
                                }}
                                collapseDisabled
                                data-automation-id={section.id}
                                to={`${location.pathname}${location.search}${location.hash}`}
                            />
                        ))}
                    </FaroNavigationTree>
                </div>
            )}
        </>
    );
}

export default SpacePageSideBar;
