import { StudyPopulation, toStudyPopulation } from '@faro/study-designer-model';
import { cqrs } from '@faro/http-clients';
import { StudyPathParameter } from '../parameter';

export type StudyPopulationPathParameters = StudyPathParameter;

/**
 * Client responsible for accessing the backend entity services
 */
export const studyPopulationClient = cqrs<StudyPopulation, StudyPopulationPathParameters>(
    '/api/study-designer/studies/:studyId/study-populations',
    toStudyPopulation
).writableEntityClient();
