import { ScrollState } from '@faro/design-system';
import { TableRowDraggableStyleOptions } from '../react-beautiful-dnd';

/**
 * Applies current scroll state to a {@link FaroScrollFog}
 * @param scrollContainerElement The scrollable element
 * @param scrollState The scroll state
 * @param options Controls style settings for the left right gutters
 */
export function updateScrollFog(
    scrollContainerElement: HTMLElement,
    scrollState: ScrollState,
    options: TableRowDraggableStyleOptions = {}
): void {
    const root = scrollContainerElement?.parentElement as HTMLElement;
    if (root == null) {
        return;
    }
    const firstRow = scrollContainerElement.querySelector('tr');

    updateScrollFogSide(
        root,
        'left',
        scrollState.canScrollLeft,
        options.leftGutter ? (firstRow?.firstChild as HTMLElement)?.getBoundingClientRect().width : 0
    );

    updateScrollFogSide(
        root,
        'right',
        scrollState.canScrollRight,
        options.rightGutter ? (firstRow?.lastChild as HTMLElement)?.getBoundingClientRect().width : 0
    );
}

function updateScrollFogSide(
    element: HTMLElement,
    side: 'top' | 'right' | 'bottom' | 'left',
    show: boolean,
    offset: number = 0
): void {
    element.style.setProperty(`--faro-scroll-fog-inset-${side}`, `${offset}px`);
    element.style.setProperty(`--faro-scroll-fog-opacity-${side}`, String(show ? 1 : 0));
}
