import type { StudyPathParameter } from '../../../../service/parameter';
import axios from 'axios';
import { AxiosResponse } from 'axios';

export type ExportPathParameters = StudyPathParameter;

export interface ExportQueryParameters {
    download?: boolean;
    revision?: number;
    timeZone?: string;
}

export const exportClient = {
    exportJSON: (query: ExportPathParameters & ExportQueryParameters): Promise<AxiosResponse<any, any>> => {
        const { studyId } = query;
        const url = `/api/study-designer/studies/${studyId}/definition-data/export`;
        const params: ExportQueryParameters = query;
        return axios.get(url, { params });
    },
    exportFaroStandardJsonFormat: (
        query: ExportPathParameters & ExportQueryParameters
    ): Promise<AxiosResponse<any, any>> => {
        const { studyId } = query;
        const url = `/api/study-designer/studies/${studyId}/actions/export-study-definition`;

        return axios.get(url, { params: query });
    },
};
