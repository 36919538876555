import React, { PropsWithChildren } from 'react';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useAppContext } from '@faro/app-context';

export function AppQueryClientProvider(props: PropsWithChildren<unknown>): JSX.Element {
    const { showErrorNotification } = useAppContext();

    const queryClient = new QueryClient({
        queryCache: new QueryCache({
            onError: error => {
                console.error('An error occurred fetching data', error);
                showErrorNotification('Server communication failure', error);
            },
        }),
        mutationCache: new MutationCache({
            onError: error => {
                // TODO - this should be reviewed before 1.16 release as there are valid reasons for this to fail like
                //        a bad request.
                console.error('An error occurred saving your data', error);
                showErrorNotification('An error occurred saving your data', error);
            },
        }),
    });

    return <QueryClientProvider client={queryClient}>{props.children}</QueryClientProvider>;
}
