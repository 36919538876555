import { Activity, StudyEntityType } from '@faro/study-designer-model';
import { activityClient, ActivityPathParameters } from './ActivityClient';
import { studyDataHookFactory } from '../studyDataHookFactory';

const factory = studyDataHookFactory.entity<Activity, ActivityPathParameters>(StudyEntityType.Activity);

export const useActivities = factory.list(activityClient.list);
export const useActivityCreate = factory.mutate(activityClient.create);
export const useActivityUpdate = factory.mutate(activityClient.update);
export const useActivityDelete = factory.mutate(activityClient.delete);
export const useActivityClone = factory.mutate(activityClient.clone as any);
export const useActivityConfigurationUpdate = factory.mutate(activityClient.assignConfig);
export const useActivityClearTimepoints = factory.mutate(activityClient.removeTimepoints);
