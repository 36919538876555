import {
    AssessmentMeasurementConfiguration,
    AssessmentMeasurementConfigurationReference,
    CommandResponse,
    RevisionedCommandResponse,
    toAssessmentMeasurementConfiguration,
    toAssessmentMeasurementConfigurationReference,
} from '@faro/study-designer-model';
import { StudyPathParameter } from '../parameter';
import { cqrs, setPathParameters } from '@faro/http-clients';
import axios, { AxiosResponse } from 'axios';
import { Identifiable } from 'faro-common-model';

export interface AssessmentMeasurementConfigurationPathParameters extends StudyPathParameter {
    id?: string;
    studyAssessmentMeasurementId?: string;
}

export interface AssessmentMeasurementConfigurationQueryParameters {
    revision?: string;
    studyAssessmentMeasurementId?: string;
    isOptionalOnly?: boolean;
}

const cqrsClientFactory = cqrs<AssessmentMeasurementConfiguration, AssessmentMeasurementConfigurationPathParameters>(
    '/api/study-designer/studies/:studyId/assessment-measurement-configurations',
    toAssessmentMeasurementConfiguration
);

export const assessmentMeasurementConfigurationClient = {
    ...cqrsClientFactory.writableEntityClient<AssessmentMeasurementConfigurationQueryParameters>(),
    clone: cqrsClientFactory.clone(),
    move: cqrsClientFactory.move(),
    references: cqrs<AssessmentMeasurementConfigurationReference, AssessmentMeasurementConfigurationPathParameters>(
        '/api/study-designer/studies/:studyId/assessment-measurement-configurations/actions/get-references',
        toAssessmentMeasurementConfigurationReference
    ).list<AssessmentMeasurementConfigurationQueryParameters>(),
    studyCustomTags: cqrs<string, StudyPathParameter>(
        '/api/study-designer/studies/:studyId/assessment-measurement-configurations/actions/study-custom-tags'
    ).list<StudyPathParameter>(),
    reset: (
        resetCommand: AssessmentMeasurementConfigurationPathParameters & Identifiable
    ): Promise<RevisionedCommandResponse<CommandResponse>> =>
        axios
            .post<
                AssessmentMeasurementConfigurationPathParameters & Identifiable,
                AxiosResponse<RevisionedCommandResponse<CommandResponse>>
            >(setPathParameters('/api/study-designer/studies/:studyId/assessment-measurement-configurations/:id/actions/reset', resetCommand as any))
            .then(response => response.data),
};
