import React, { ChangeEvent, ComponentPropsWithRef, useState } from 'react';
import { FaroChip, FaroChooseFiles, FaroDialog, FaroDropZone, FaroSelectedFilesList } from '@faro/design-system';
import fileErrorSvg from './assets/file-error.svg';
import fileJsonSvg from './assets/file-json.svg';
import fileUploadSvg from './assets/file-upload.svg';
import styles from './StudyDefinitionFileImportDialog.module.scss';
import { Formik } from 'formik';
import { validateFileSelection } from './support/validateFileSelection';
import EntityFormControls from '../EntityFormControls/EntityFormControls';

/**
 * An interface representing the values managed by {@link Formik} forms.  When the form is submitted, these are the
 * values passed along by the form.
 */
export interface StudyDefinitionFileImportFormValues {
    /**
     * The {@link File}s selected by the user.
     */
    files: File[];
}

/**
 * Properties for configuring the {@link StudyDefinitionFileImportDialog} component.
 */
export interface StudyDefinitionFileImportProps extends Omit<ComponentPropsWithRef<'div'>, 'onDragOver' | 'onDrop'> {
    /**
     * A list of file extensions that can be selected by the user.  The extension should include the preceding `.`, e.g.
     * `.json` or `.png`.
     */
    readonly extensions: string[];

    /**
     * When `true`, the user can select multiple files.  Otherwise, the user can only select one file at a time.
     */
    readonly allowMultiple?: boolean;

    /**
     * Configuration for initializing the {@link Formik} form.  For this component, this will typically be an empty
     * {@link StudyDefinitionFileImportFormValues}.
     */
    readonly initialValues?: StudyDefinitionFileImportFormValues;

    /**
     * The version of the currently supported "import" schema.  The study definition file a user is attempting to import
     * must be compatible with this version.
     */
    readonly version?: string;

    /**
     * Handler for when the user drags file(s) over the {@link FaroDropZone}.
     *
     * @param e `DragEvent` containing metadata about the event.
     */
    onDragOver?(e: DragEvent): void;

    /**
     * Handler for when the user drops file(s) within the bounds of the {@link FaroDropZone}.  If provided, will be
     * called after the `onDropInternal` method has been called.
     *
     * @param e `DragEvent` containing metadata about the event.
     */
    onDrop?(e: DragEvent): void;

    /**
     * Handler for when the file `input` in the {@link FaroChooseFiles} component.  If provided, will be executed after
     * the `onChangeInternal` method has been called.
     *
     * @param e `ChangeEvent` containing metadata about the event.
     */
    onSelectedFileChange?(e: ChangeEvent<HTMLInputElement>): void;

    /**
     * Handler for when a user wants to remove a selected file from the list of selected files.
     *
     * @param index `number` the index of the file in the `values.files` array.
     */
    onRemove?(index: number): void;

    /**
     * Handler for submitting the form.
     */
    onImport(values: StudyDefinitionFileImportFormValues): void | Promise<any>;

    /**
     * Handler for closing this dialog.
     */
    onClose?(): void;
}

/**
 * Determine which file image to display in the drop zone.
 *
 * @param files The collection of `File`(s) the user has selected.
 * @param error The error message (if any).
 * @returns string representing the `src` attribute for an `img`  element.
 */
function getFileImageSource(files: File[], error?: string): string {
    if (error != null) {
        return fileErrorSvg;
    }

    return files.length === 0 ? fileUploadSvg : fileJsonSvg;
}

/**
 * A component allowing users to import a study definition file.
 *
 * @param props {@link StudyDefinitionFileImportProps} to configure this component.
 */
function StudyDefinitionFileImportDialog(props: StudyDefinitionFileImportProps): JSX.Element {
    const {
        extensions,
        version,
        allowMultiple = false,
        initialValues = { files: [] },
        onDragOver = () => {},
        onDrop = () => {},
        onSelectedFileChange,
        onRemove = () => {},
        onImport,
        onClose = () => {},
        ...rest
    } = props;

    const [error, setError] = useState<string | undefined>(undefined);

    return (
        <Formik initialValues={initialValues} onSubmit={onImport}>
            {({ submitForm, dirty, values, setFieldValue, isSubmitting }) => {
                /**
                 * Verify the file(s) are valid and set the form's state appropriately:
                 * * if the file(s) is/are valid, then add them to the form.
                 * * otherwise, set the form's error state and clear the form.
                 *
                 * @param fileList `FileList` of file(s) supplied by the user.
                 */
                function selectFiles(fileList?: FileList | null): void {
                    const { files, errorMessage } = validateFileSelection(allowMultiple, extensions, fileList);

                    setFieldValue('files', files);
                    setError(errorMessage);
                }

                function onDropInternal(e: DragEvent): void {
                    e.preventDefault();
                    e.stopPropagation();

                    selectFiles(e.dataTransfer?.files);

                    onDrop?.(e);
                }

                function onChangeInternal(e: ChangeEvent<HTMLInputElement>): void {
                    selectFiles(e.target.files);

                    onSelectedFileChange?.(e);
                }

                return (
                    <FaroDialog>
                        <FaroDialog.Header>
                            <div>
                                Import Study
                                <div className={styles.headerSubtext}>
                                    <span>{`Upload the ${extensions.join(', ')} file${
                                        extensions.length > 1 ? 's' : ''
                                    } from a previously exported Faro study definition within the current application version.`}</span>
                                    <span>{`${version != null ? `Export format version ${version}` : ''}`}</span>
                                </div>
                            </div>
                        </FaroDialog.Header>
                        <FaroDialog.Body>
                            <FaroDropZone
                                className={`${styles.dropZoneContainer} ${
                                    error == null ? '' : styles.dropZoneContainerError
                                }`}
                                extensions={extensions}
                                onDragOver={onDragOver}
                                onDrop={onDropInternal}
                                allowMultiple={allowMultiple}
                                {...rest}
                            >
                                <div className={styles.contentContainer}>
                                    <div className={`${error == null ? '' : styles.contentTextError}`}>
                                        {error == null
                                            ? `Drag and drop your file${allowMultiple ? 's' : ''} here`
                                            : error}
                                    </div>
                                    <div className={styles.contentSubtext}>
                                        Compatible formats{' '}
                                        {extensions.map(extension => (
                                            <FaroChip key={extension} color="base" className={styles.extensionChip}>
                                                {(extension.startsWith('.')
                                                    ? extension.substring(1)
                                                    : extension
                                                ).toUpperCase()}
                                            </FaroChip>
                                        ))}
                                    </div>
                                    <img
                                        className={styles.contentImage}
                                        src={getFileImageSource(values.files, error)}
                                        alt={`Upload a file with one of these extensions: ${extensions.join(', ')}`}
                                    />
                                </div>
                                <div className={styles.contentSeparator}>
                                    <span className={styles.line}></span>
                                    <div className={styles.text}>or</div>
                                    <span className={styles.line}></span>
                                </div>
                                <FaroChooseFiles
                                    name="files"
                                    extensions={extensions}
                                    allowMultiple={allowMultiple}
                                    buttonText="Select a file..."
                                    onChange={onChangeInternal}
                                    onFileInputClick={(e: any) => {
                                        // "blank out" the file input's target.value.  Otherwise, the user will not be
                                        // able to do something like: add file1.json -> remove file1.json -> add file1.json.
                                        e.target.value = '';
                                    }}
                                />
                                {values.files.length > 0 && (
                                    <>
                                        <h4>Selected File{values.files.length > 1 ? 's' : ''}</h4>
                                        <FaroSelectedFilesList
                                            className={styles.selectedFilesList}
                                            onRemove={(index: number) => {
                                                setFieldValue('files', values.files.toSpliced(index, 1));

                                                onRemove?.(index);
                                            }}
                                            files={values.files}
                                        />
                                    </>
                                )}
                            </FaroDropZone>
                        </FaroDialog.Body>
                        <FaroDialog.Controls>
                            <EntityFormControls
                                saving={isSubmitting}
                                saveDisabled={values.files.length === 0 || !dirty}
                                saveButtonLabel="Import"
                                onCancel={() => onClose()}
                                onSave={async () => {
                                    await submitForm();
                                    onClose();
                                }}
                            />
                        </FaroDialog.Controls>
                    </FaroDialog>
                );
            }}
        </Formik>
    );
}

export default StudyDefinitionFileImportDialog;
