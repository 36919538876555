import { CommandResponse, Stage, toStage, RevisionedCommandResponse } from '@faro/study-designer-model';
import { cqrs } from '@faro/http-clients';
import axios, { AxiosResponse } from 'axios';
import { setPathParameters } from '@faro/http-clients';
import { PopulationQueryParameter, StudyPathParameter } from '../parameter';

export type StagePathParameters = StudyPathParameter;
export type StageQueryParameters = PopulationQueryParameter;

/**
 * Client responsible for accessing the backend entity services
 */
export const stageClient = {
    ...cqrs<Stage, StagePathParameters>(
        '/api/study-designer/studies/:studyId/stages',
        toStage
    ).writableEntityClient<StageQueryParameters>(),

    createStage: cqrs<Stage & { sequenceNumber: number }, StagePathParameters>(
        '/api/study-designer/studies/:studyId/stages/actions/create',
        toStage as any
    ).create(),

    move: (moveCommand: Stage & { sequenceNumber: number }): Promise<RevisionedCommandResponse<CommandResponse>> =>
        axios
            .post<Stage & { sequenceNumber: number }, AxiosResponse<RevisionedCommandResponse<CommandResponse>>>(
                setPathParameters('/api/study-designer/studies/:studyId/stages/:id/actions/move', moveCommand as any),
                { sequenceNumber: moveCommand.sequenceNumber }
            )
            .then(response => response.data),

    reset: (resetCommand: Stage): Promise<RevisionedCommandResponse<CommandResponse>> =>
        axios
            .post<Stage & { sequenceNumber: number }, AxiosResponse<RevisionedCommandResponse<CommandResponse>>>(
                setPathParameters('/api/study-designer/studies/:studyId/stages/:id/actions/reset', resetCommand as any),
                { target: 'soa' }
            )
            .then(response => response.data),
};
