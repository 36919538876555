import styles from './SpaceGeneralInformationForm.module.scss';
import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { namedObjectToIdOption } from '../../../util/namedObjectToIdOption';
import {
    NamedObject,
    studyTitleAcronymMaxLength,
    studyTitleLongMaxLength,
    studyTitleShortMaxLength,
    SystemDefinedObject,
} from '@faro/study-designer-model';
import { FaroChip, FaroHorizontalField, FaroSelectProps, FaroTextAreaProps, Option } from '@faro/design-system';
import { caseInsensitiveIncludesStringFilter } from '../../../util/caseInsensitiveStringIncludesFilter';
import { transformSearchToOptions } from '../../../util/transformSearchToOptions';
import { useSearchEnabledSelect } from '../../../package/react-hooks/useSearchEnabledSelect';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { FaroSelectField, FaroTextAreaField, FormikDirtyChangeListener } from '@faro/forms';
import EntityFormControls from '../../EntityFormControls/EntityFormControls';
import { isEmpty, isEqual, mapValues, pickBy } from 'lodash';
import { toStudyAttributesEntity } from '../SpaceGeneralInformation.func';
import { AdditionalInformationField } from '../AdditionalInformationField/AdditionalInformationField';
import { StudyAttributesEntity } from '@faro/study-space-service-model';
import { useUnsavedChangesNavigationBlocker, useConcurrentEdit } from '@faro/app-context';

const studyTypeOptions = {
    options: [
        { key: 1, label: 'Interventional', value: 'interventional' },
        { key: 2, label: 'Observational', value: 'observational' },
    ],
};

const defaultTextAreaProps: FaroTextAreaProps = {
    appearance: 'inline-editable',
    className: `${styles.fieldInput}`,
    placeholder: 'Empty',
    minLines: 1,
    rows: 1,
};

const defaultSelectProps: FaroSelectProps = {
    appearance: 'inline-editable',
    className: `${styles.fieldInput}`,
    placeholder: 'Empty',
    clearable: true,
    fullWidth: true,
};

export interface SpaceGeneralInformationFormProps {
    readOnly?: boolean;
    onSubmit(
        valuesDelta: Partial<StudyAttributesEntity>,
        values: StudyAttributesEntity,
        { setSubmitting, resetForm }: FormikHelpers<StudyAttributesEntity>
    ): Promise<void>;
    studyAttributes: StudyAttributesEntity;
    therapeuticAreas: SystemDefinedObject[];
    diseaseAreas: SystemDefinedObject[];
    studyPhases: SystemDefinedObject[];
}

const SpaceGeneralInformationForm = (props: SpaceGeneralInformationFormProps): JSX.Element => {
    const { therapeuticAreas = [], diseaseAreas = [], studyPhases = [], readOnly, studyAttributes, onSubmit } = props;

    const initialValues = toStudyAttributesEntity(studyAttributes);

    const [valuesOnEditStart, setValuesOnEditStart] = useState(initialValues);
    const { enableReinitialize, setEnableReinitialize, enableReinitializeOnPropsChange, onDirtyChange } =
        useConcurrentEdit({
            initialValues,
        });

    const renderSelectedValueDefault = useCallback(
        (value: NamedObject | undefined, index: number, onDelete?: (event: any) => void) => (
            <FaroChip key={`selected-value-${value?.id || index}`} onDelete={onDelete}>
                {value?.name || ''}
            </FaroChip>
        ),
        []
    );

    const handleSubmit = (values: StudyAttributesEntity, helpers: FormikHelpers<StudyAttributesEntity>) => {
        const findChangedValues = (initialValues: any, newValues: any) => {
            const deletedValues = mapValues(
                pickBy(initialValues, (value, key) => !isEqual(value, newValues[key]) && newValues[key] == null),
                value => (Array.isArray(value) ? [] : null)
            );
            const newAttributeValues = pickBy(newValues, (value, key) => !isEqual(value, initialValues[key]));
            return Object.assign(deletedValues, newAttributeValues);
        };

        const valuesDelta = findChangedValues(valuesOnEditStart, values);

        onSubmit(valuesDelta, values, { ...helpers });
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            enableReinitialize={enableReinitialize}
            validateOnMount
        >
            {({ dirty, errors, resetForm, submitForm, values, isSubmitting }) => {
                return (
                    <Form className={styles.root} data-intercom-target="Study Space GI Form">
                        <section data-automation-id="study">
                            <h3 className={styles.sectionHeading}>Study</h3>
                            <FaroHorizontalField name="protocolNumber" label="Protocol Number">
                                <FaroTextAreaField
                                    data-automation-id={`protocolNumberTextAreaField`}
                                    maxLength={50}
                                    {...{
                                        ...defaultTextAreaProps,
                                        name: 'protocolNumber',
                                        value: values.protocolNumber,
                                        readOnly,
                                    }}
                                />
                            </FaroHorizontalField>
                            <FaroHorizontalField name="program" label="Program">
                                <FaroTextAreaField
                                    data-automation-id={`programTextAreaField`}
                                    maxLength={120}
                                    {...{
                                        ...defaultTextAreaProps,
                                        name: 'program',
                                        value: values.program,
                                        readOnly,
                                    }}
                                />
                            </FaroHorizontalField>
                            <FaroHorizontalField name="type" label="Type">
                                <FaroSelectField
                                    data-automation-id={`typeSelectField`}
                                    {...{
                                        ...defaultSelectProps,
                                        ...studyTypeOptions,
                                        name: 'type',
                                        readOnly,
                                    }}
                                />
                            </FaroHorizontalField>
                            <FaroHorizontalField name="diseaseArea" label="Primary Disease Area">
                                <DiseaseAreaSelect
                                    options={diseaseAreas}
                                    readOnly={readOnly}
                                    renderSelectedValue={renderSelectedValueDefault}
                                />
                            </FaroHorizontalField>
                            <FaroHorizontalField name="otherDiseaseAreas" label="Other Disease Areas">
                                <OtherDiseaseAreasSelect options={diseaseAreas} readOnly={readOnly} />
                            </FaroHorizontalField>
                            <FaroHorizontalField name="therapeuticArea" label="Primary Therapeutic Area">
                                <TherapeuticAreaSelect
                                    options={therapeuticAreas}
                                    readOnly={readOnly}
                                    renderSelectedValue={renderSelectedValueDefault}
                                />
                            </FaroHorizontalField>
                            <FaroHorizontalField name="phase" label="Phase">
                                <StudyPhaseSelect options={studyPhases} readOnly={readOnly} />
                            </FaroHorizontalField>
                            <FaroHorizontalField name="protocolTitle" label="Protocol Title">
                                <FaroTextAreaField
                                    data-automation-id={`protocolTitleTextAreaField`}
                                    {...{
                                        ...defaultTextAreaProps,
                                        name: 'protocolTitle',
                                        value: values.protocolTitle,
                                        readOnly,
                                    }}
                                    maxLength={studyTitleLongMaxLength}
                                />
                            </FaroHorizontalField>
                            <FaroHorizontalField name="protocolTitleShort" label="Protocol Title (Short)">
                                <FaroTextAreaField
                                    data-automation-id={`protocolTitleShortTextAreaField`}
                                    maxLength={studyTitleShortMaxLength}
                                    {...{
                                        ...defaultTextAreaProps,
                                        name: 'protocolTitleShort',
                                        value: values.protocolTitleShort,
                                        readOnly,
                                    }}
                                />
                            </FaroHorizontalField>
                            <FaroHorizontalField name="protocolTitleAcronym" label="Acronym">
                                <FaroTextAreaField
                                    data-automation-id={`protocolTitleAcronymTextAreaField`}
                                    maxLength={studyTitleAcronymMaxLength}
                                    {...{
                                        ...defaultTextAreaProps,
                                        name: 'protocolTitleAcronym',
                                        value: values.protocolTitleAcronym,
                                        readOnly,
                                    }}
                                />
                            </FaroHorizontalField>
                            <FaroHorizontalField name="studyRegistryId" label="Registry Identifier Number">
                                <FaroTextAreaField
                                    data-automation-id={`studyRegistryIdTextAreaField`}
                                    maxLength={25}
                                    {...{
                                        ...defaultTextAreaProps,
                                        name: 'studyRegistryId',
                                        value: values.studyRegistryId,
                                        readOnly,
                                    }}
                                />
                            </FaroHorizontalField>
                        </section>
                        <section data-automation-id="vendors">
                            <h3 className={styles.sectionHeading}>Vendors</h3>
                            <FaroHorizontalField
                                name="contractResearchOrganizationVendor"
                                label="Contract Research Organization"
                            >
                                <FaroTextAreaField
                                    data-automation-id={`contractResearchOrganizationVendorTextAreaField`}
                                    maxLength={120}
                                    {...{
                                        ...defaultTextAreaProps,
                                        name: 'contractResearchOrganizationVendor',
                                        value: values.contractResearchOrganizationVendor,
                                        readOnly,
                                    }}
                                />
                            </FaroHorizontalField>
                            <FaroHorizontalField name="centralLab" label="Central Lab">
                                <FaroTextAreaField
                                    data-automation-id={`centralLabTextAreaField`}
                                    maxLength={120}
                                    {...{
                                        ...defaultTextAreaProps,
                                        name: 'centralLab',
                                        value: values.centralLab,
                                        readOnly,
                                    }}
                                />
                            </FaroHorizontalField>
                            <FaroHorizontalField name="electronicDataCaptureVendor" label="Electronic Data Capture">
                                <FaroTextAreaField
                                    data-automation-id={`electronicDataCaptureVendorTextAreaField`}
                                    maxLength={120}
                                    {...{
                                        ...defaultTextAreaProps,
                                        name: 'electronicDataCaptureVendor',
                                        value: values.electronicDataCaptureVendor,
                                        readOnly,
                                    }}
                                />
                            </FaroHorizontalField>
                        </section>
                        <section data-automation-id="additionalInformation">
                            <h3 className={styles.sectionHeading}>Additional Information</h3>
                            <div className={styles.metadata}>
                                <AdditionalInformationField
                                    {...{
                                        value: values.additionalInformation,
                                        readOnly,
                                    }}
                                />
                            </div>
                        </section>
                        {!readOnly && dirty && (
                            <footer>
                                <EntityFormControls
                                    onCancel={() => {
                                        resetForm();
                                        setEnableReinitialize(true);
                                    }}
                                    onSave={async () => {
                                        enableReinitializeOnPropsChange();
                                        await submitForm();

                                        if (!isEmpty(errors)) {
                                            const firstInvalidInput = document.querySelector(
                                                '[data-invalid]'
                                            ) as HTMLElement;
                                            firstInvalidInput?.focus();
                                        }
                                    }}
                                    saving={isSubmitting}
                                    cancelButtonLabel="Discard"
                                    className={styles.generalInformationControls}
                                />
                            </footer>
                        )}
                        <FormikDirtyChangeListener
                            onDirtyChange={(dirty: boolean) => {
                                if (dirty) {
                                    setValuesOnEditStart(initialValues);
                                }
                                onDirtyChange(dirty);
                            }}
                        />
                        <UnsavedChangesListener />
                    </Form>
                );
            }}
        </Formik>
    );
};

interface TherapeuticAreaSelectProps {
    options: SystemDefinedObject[];
    readOnly?: boolean;
    renderSelectedValue: (value: NamedObject | undefined, index: number, onDelete?: (event: any) => void) => ReactNode;
}

function TherapeuticAreaSelect(props: TherapeuticAreaSelectProps): React.JSX.Element {
    const { options, readOnly, renderSelectedValue } = props;
    const { values } = useFormikContext<{ therapeuticArea?: SystemDefinedObject }>();

    const getTherapeuticAreaOptions = useCallback(
        (search: string) => {
            return transformSearchToOptions(
                search,
                options.filter(caseInsensitiveIncludesStringFilter(x => x.name, search)),
                values.therapeuticArea,
                styles.highlightableOption
            );
        },
        [options, values.therapeuticArea]
    );

    const therapeuticAreaSelectProps = useSearchEnabledSelect<NamedObject>(
        getTherapeuticAreaOptions,
        renderSelectedValue
    );

    return (
        <FaroSelectField
            data-automation-id={`therapeuticAreaSelectField`}
            {...{
                ...defaultSelectProps,
                ...therapeuticAreaSelectProps,
                name: 'therapeuticArea',
                readOnly,
            }}
        />
    );
}

interface DiseaseAreaSelectProps {
    options: SystemDefinedObject[];
    readOnly?: boolean;
    renderSelectedValue: (value: NamedObject | undefined, index: number, onDelete?: (event: any) => void) => ReactNode;
}

function DiseaseAreaSelect(props: DiseaseAreaSelectProps): React.JSX.Element {
    const { options, readOnly, renderSelectedValue } = props;
    const { values } = useFormikContext<{ diseaseArea?: SystemDefinedObject }>();

    const getDiseaseAreaOptions = useCallback(
        (search: string) => {
            return transformSearchToOptions(
                search,
                options.filter(caseInsensitiveIncludesStringFilter(x => x.name, search)),
                values.diseaseArea,
                styles.highlightableOption
            );
        },
        [options, values.diseaseArea]
    );

    const diseaseAreaSelectProps = useSearchEnabledSelect<NamedObject>(getDiseaseAreaOptions, renderSelectedValue);

    return (
        <FaroSelectField
            data-automation-id={`diseaseAreaSelectField`}
            {...{
                ...defaultSelectProps,
                ...diseaseAreaSelectProps,
                name: 'diseaseArea',
                readOnly,
            }}
        />
    );
}

interface OtherDiseaseAreasSelectProps {
    options: SystemDefinedObject[];
    readOnly?: boolean;
}

function OtherDiseaseAreasSelect(props: OtherDiseaseAreasSelectProps): React.JSX.Element {
    const { options, readOnly } = props;
    const diseaseAreasSelectOptions = useMemo(() => options?.map(namedObjectToIdOption) || [], [options]);

    const renderDiseaseAreaMultiSelectedValue = useCallback(
        (value: string | undefined, index: number, onDelete?: (event: any) => void): ReactNode => {
            const option = diseaseAreasSelectOptions.find(option => option.value == value);
            return (
                <FaroChip key={`-selected-value-${option?.key || index}`} onDelete={onDelete}>
                    {option?.label || ''}
                </FaroChip>
            );
        },
        [diseaseAreasSelectOptions]
    );

    const getDiseaseAreaSearchOptions = useCallback(
        (search: string): Option<string>[] => {
            return diseaseAreasSelectOptions.filter(
                caseInsensitiveIncludesStringFilter(item => item.label + '', search)
            );
        },
        [diseaseAreasSelectOptions]
    );

    const otherDiseaseAreaSelectProps = {
        ...useSearchEnabledSelect<string>(getDiseaseAreaSearchOptions, renderDiseaseAreaMultiSelectedValue, {
            dependencies: [diseaseAreasSelectOptions.length],
        }),
        multiSelect: true,
    };

    return (
        <FaroSelectField
            data-automation-id={`otherDiseaseAreasSelectField`}
            {...{
                ...defaultSelectProps,
                ...otherDiseaseAreaSelectProps,
                name: 'otherDiseaseAreaIds',
                readOnly,
            }}
        />
    );
}

interface StudyPhaseSelectProps {
    options: SystemDefinedObject[];
    readOnly?: boolean;
}

function StudyPhaseSelect(props: StudyPhaseSelectProps): React.JSX.Element {
    const { options, readOnly } = props;
    const studyPhaseSelectOptions = useMemo(() => options?.map(namedObjectToIdOption) || [], [options]);

    const renderStudyPhaseMultiSelectedValue = useCallback(
        (value: string | undefined, index: number, onDelete?: (event: any) => void): ReactNode => {
            const option = studyPhaseSelectOptions.find(option => option.value == value);
            return (
                <FaroChip key={`-selected-value-${option?.key || index}`} onDelete={onDelete}>
                    {option?.label || ''}
                </FaroChip>
            );
        },
        [studyPhaseSelectOptions]
    );
    const getStudyPhaseSearchOptions = useCallback(
        (search: string): Option<string>[] => {
            return studyPhaseSelectOptions.filter(caseInsensitiveIncludesStringFilter(item => item.label + '', search));
        },
        [studyPhaseSelectOptions]
    );
    const studyPhasesSelectProps = {
        ...useSearchEnabledSelect<string>(getStudyPhaseSearchOptions, renderStudyPhaseMultiSelectedValue, {
            dependencies: [studyPhaseSelectOptions.length],
        }),
        multiSelect: true,
    };

    return (
        <FaroSelectField
            data-automation-id={`phaseIdsSelectField`}
            {...{
                ...defaultSelectProps,
                ...studyPhasesSelectProps,
                name: 'phaseIds',
                readOnly,
            }}
        />
    );
}

function UnsavedChangesListener(): null {
    const { dirty, resetForm, submitForm } = useFormikContext();
    useUnsavedChangesNavigationBlocker({
        id: 'study-space-general-information-form',
        hasUnsavedChanges: () => dirty,
        onDiscard: () => resetForm(),
        onSave: async () => await submitForm(),
    });
    return null;
}

export default SpaceGeneralInformationForm;
