import React, { ForwardedRef, forwardRef, useState } from 'react';
import {
    FaroDeleteModal,
    FaroDialog,
    FaroIconButton,
    FaroMenu,
    FaroMenuProps,
    FaroOverlay,
    FaroOverlayTrigger,
} from '@faro/design-system';
import { ContentCopyIcon, DownloadIcon, MoreHorizontalIcon, RenameIcon, TabMoveIcon, Trash2Icon } from '@faro/icons';
import StudyDefinitionMoveModal from '../StudyDefintionMoveModal/StudyDefinitionMoveModal';
import { useStudySpacePermissions } from '../../../package/react-hooks/useStudySpacePermissions';
import styles from './StudyDefinitionMenu.module.scss';
import { StudyDefinitionTableRow } from '../StudyDefinitionTable.types';
import { StudyDefinitionTableRowCallbacks } from '../StudyDefinitionTableRow/StudyDefinitionTableRow';

export interface StudyDefinitionMenuProps extends FaroMenuProps, StudyDefinitionMenuCallbacks {
    studyDefinition: StudyDefinitionTableRow;
    index: number;
}

export type StudyDefinitionMenuCallbacks = Pick<
    StudyDefinitionTableRowCallbacks,
    'onRename' | 'onExport' | 'onClone' | 'onMove' | 'onDelete' | 'cloneDisabled'
>;

function StudyDefinitionMenu(props: StudyDefinitionMenuProps, ref: ForwardedRef<HTMLDivElement>): React.JSX.Element {
    const { studyDefinition, index, onExport, onRename, onClone, onMove, onDelete, cloneDisabled = false } = props;
    const [moveModalOpen, setMoveModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const permissions = useStudySpacePermissions();

    return (
        <>
            <FaroOverlayTrigger
                strategy="fixed"
                placement="bottom-end"
                openOnClick
                overlay={
                    <FaroMenu className={styles.menu} ref={ref} data-testid={`study-definition-menu-${index}`}>
                        {permissions.editor && onRename != null && studyDefinition.status.type === 'editable' && (
                            <FaroMenu.Item
                                left={<RenameIcon />}
                                onClick={() => {
                                    onRename(studyDefinition);
                                }}
                                data-testid="renameStudyDefinition"
                            >
                                Rename
                            </FaroMenu.Item>
                        )}
                        {permissions.editor && (
                            <FaroMenu.Item
                                left={<ContentCopyIcon />}
                                disabled={cloneDisabled}
                                onClick={() => {
                                    onClone(studyDefinition);
                                }}
                                data-testid="duplicateStudyDefinition"
                                data-automation-id="duplicateStudyDefinition"
                                data-intercom-target="Duplicate Study Definition"
                            >
                                Duplicate
                            </FaroMenu.Item>
                        )}
                        {permissions.editor && (
                            <FaroMenu.Item
                                left={<TabMoveIcon />}
                                onClick={() => {
                                    setMoveModalOpen(true);
                                }}
                                data-testid="moveStudyDefinition"
                                data-automation-id="moveStudyDefinition"
                                data-intercom-target="Move Study Definition"
                            >
                                Move
                            </FaroMenu.Item>
                        )}
                        <FaroMenu.Item
                            left={<DownloadIcon />}
                            onClick={() => {
                                onExport(studyDefinition);
                            }}
                            data-testid="exportStudyDefinition"
                            data-automation-id="exportStudyDefinition"
                            data-intercom-target="Export Study Definition"
                        >
                            Export
                        </FaroMenu.Item>
                        {permissions.editor && studyDefinition.status.type === 'editable' && (
                            <FaroMenu.Item
                                left={<Trash2Icon />}
                                color="danger"
                                onClick={() => {
                                    setDeleteModalOpen(true);
                                }}
                                data-testid="deleteStudyDefinition"
                                data-automation-id="deleteStudyDefinition"
                                data-intercom-target="Delete Study Definition"
                            >
                                Delete
                            </FaroMenu.Item>
                        )}
                    </FaroMenu>
                }
            >
                {(props: any, { open }: { open: boolean }) => (
                    <FaroIconButton
                        {...props}
                        icon={<MoreHorizontalIcon />}
                        appearance="inline"
                        data-open={open}
                        active={open}
                        className={`${styles.menuButton} ${open ? styles.open : ''}`}
                        data-testid={`studyDefinitionMenu-${index}`}
                    />
                )}
            </FaroOverlayTrigger>

            <FaroOverlay
                open={moveModalOpen}
                onClose={() => {
                    setMoveModalOpen(false);
                }}
            >
                <StudyDefinitionMoveModal
                    fromSpaceId={studyDefinition.spaceId}
                    onMove={(toSpaceId: string) => onMove(studyDefinition, toSpaceId)}
                />
            </FaroOverlay>

            <FaroOverlay
                open={deleteModalOpen}
                onClose={() => {
                    setDeleteModalOpen(false);
                }}
            >
                <FaroDeleteModal onDelete={() => onDelete(studyDefinition)}>
                    <FaroDialog.Header>Delete</FaroDialog.Header>
                    <FaroDialog.Body>
                        Are you sure you want to delete this study definition?
                        <br />
                        This action is irreversible.
                    </FaroDialog.Body>
                </FaroDeleteModal>
            </FaroOverlay>
        </>
    );
}

export default forwardRef(StudyDefinitionMenu);
