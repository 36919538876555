import { SystemDefinedObject, toRequiredSystemDefinedObject } from '@faro/study-designer-model';
import { cqrs } from '@faro/http-clients';

/**
 * Client responsible for managing allocation activity type
 */
export const epochTypeClient = cqrs<SystemDefinedObject>(
    '/api/study-designer/enumerated-values/epoch-types',
    toRequiredSystemDefinedObject
).listableEntityClient();
