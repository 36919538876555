import { Estimand, toEstimand } from '@faro/study-designer-model';
import { cqrs } from '@faro/http-clients';
import { StudyPathParameter } from '../parameter';

export type EstimandPathParameters = StudyPathParameter;

export const estimandClient = cqrs<Estimand, EstimandPathParameters>(
    '/api/study-designer/studies/:studyId/estimands',
    toEstimand
).readableEntityClient();
