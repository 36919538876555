import { ComponentPropsWithoutRef, FunctionComponent, ReactNode, useRef } from 'react';
import { FaroButton, FaroIconButton, FaroOverlayTrigger, FaroTooltip } from '@faro/design-system';
import styles from './EntityFormControls.module.scss';
import { Trash2Icon } from '@faro/icons';

export interface EntityFormControlsProps extends ComponentPropsWithoutRef<'div'> {
    /**
     * Called when the entity modification is cancelled
     */
    onCancel?(): void;

    /**
     * Custom label for the cancel button
     * {@default "Cancel"}
     */
    readonly cancelButtonLabel?: ReactNode;

    /**
     * Called when the entity is saved.  If this method is undefined, the **Save** button will not display.
     */
    onSave?(): void;

    /**
     * Custom label for the save button
     * {@default "Save"}
     */
    readonly saveButtonLabel?: ReactNode;

    /**
     * If true, the save button will display with a spinner
     */
    readonly saving?: boolean;

    /**
     * If true, the save button will appear disabled
     * {@default false}
     */
    readonly saveDisabled?: boolean;

    /**
     * Called when the entity is deleted
     * If not provided, a delete button will not be shown
     */
    onDelete?(): void;

    /**
     * Custom tooltip for the delete icon button
     * {@default undefined}
     */
    readonly deleteButtonTooltip?: ReactNode;

    /**
     * If true, the delete button will display with a spinner
     */
    readonly deleting?: boolean;
}

const EntityFormControls: FunctionComponent<EntityFormControlsProps> = props => {
    const {
        onCancel,
        onSave,
        saving = false,
        saveDisabled = false,
        deleting = false,
        cancelButtonLabel = 'Cancel',
        saveButtonLabel = 'Save',
        onDelete,
        deleteButtonTooltip,
        className = '',
        ...rest
    } = props;

    const saveButtonRef = useRef<HTMLButtonElement>(null);
    const cancelButtonRef = useRef<HTMLButtonElement>(null);
    const deleteButtonRef = useRef<HTMLButtonElement>(null);

    const handleOnSaveClick = (event: React.MouseEvent) => {
        if (saveButtonRef.current?.contains(event.target as HTMLElement)) {
            onSave?.();
        }
    };

    const handleOnCancelClick = (event: React.MouseEvent) => {
        if (cancelButtonRef.current?.contains(event.target as HTMLElement)) {
            onCancel?.();
        }
    };

    const handleOnDeleteClick = (event: React.MouseEvent) => {
        if (deleteButtonRef.current?.contains(event.target as HTMLElement)) {
            onDelete?.();
        }
    };

    return (
        <div
            {...rest}
            className={`
                ${styles.root}
                ${onCancel != null ? styles.cancelEnabled : ''}
                ${onSave != null ? styles.saveEnabled : ''}
                ${onDelete != null ? styles.deleteEnabled : ''}
                ${className}
            `}
        >
            <FaroOverlayTrigger
                container={document.body}
                overlay={deleteButtonTooltip ? <FaroTooltip>{deleteButtonTooltip}</FaroTooltip> : null}
            >
                <FaroIconButton
                    className={styles.deleteButton}
                    icon={<Trash2Icon />}
                    appearance="inline"
                    color="danger"
                    loading={deleting}
                    tabIndex={onDelete != null ? undefined : -1}
                    onClick={handleOnDeleteClick}
                    data-automation-id="deleteIcon"
                    ref={deleteButtonRef}
                />
            </FaroOverlayTrigger>

            <FaroButton
                className={styles.cancelButton}
                appearance="inline"
                tabIndex={onCancel != null ? undefined : -1}
                onClick={handleOnCancelClick}
                ref={cancelButtonRef}
                data-automation-id="cancelButton"
            >
                {cancelButtonLabel}
            </FaroButton>

            <FaroButton
                className={styles.saveButton}
                color="primary"
                onClick={handleOnSaveClick}
                loading={saving}
                disabled={saveDisabled}
                ref={saveButtonRef}
                data-automation-id="saveButton"
            >
                {saveButtonLabel}
            </FaroButton>
        </div>
    );
};

export default EntityFormControls;
