import { QueryFunctionContext, UseMutationOptions, UseMutationResult, UseQueryOptions } from '@tanstack/react-query';
import { ChangeEvent, ChangeEventService, Identified } from '@faro/realtime-services';
import { type DefaultError, QueryKey } from '@tanstack/query-core';
import { useRealtimeMutation } from './useRealtimeMutation';
import { OptimisticUpdateContext, useRealtimeQuery, UseRealtimeQueryResult } from './useRealtimeQuery';

export type ConsumerQueryOptions<
    TQueryFnData = unknown,
    TError = DefaultError,
    TData = TQueryFnData,
    TQueryKey extends QueryKey = QueryKey,
> = Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>;

export type ListFunction<TResult, TParameters> = (parameters: TParameters, signal: AbortSignal) => Promise<TResult[]>;
export type ListHook<TEntity, TParameters> = (
    parameters: TParameters,
    options?: ConsumerQueryOptions<TEntity[]>
) => UseRealtimeQueryResult<TEntity[]>;

export type GetFunction<TResult, TParameters extends Identified> = (
    parameters: TParameters,
    signal: AbortSignal
) => Promise<TResult>;
export type GetHook<TEntity, TParameters extends Identified> = (
    parameters: TParameters,
    options?: ConsumerQueryOptions<TEntity>
) => UseRealtimeQueryResult<TEntity>;

export type ConsumerMutationOptions<
    TData = unknown,
    TError = DefaultError,
    TVariables = void,
    TContext = unknown,
> = Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'scope' | 'mutationFn'>;

export type MutateFunction<TResult, TParameters> = (parameters: TParameters) => Promise<TResult>;
export type MutateHook<TResult, TParameters> = (
    scope: string,
    options?: ConsumerMutationOptions<TResult, unknown, TParameters>
) => UseMutationResult<TResult, unknown, TParameters>;

export interface RealtimeHookFactoryOptions<TEvent extends ChangeEvent, TScopeParameters> {
    /**
     * The name of the root entity type that the scope (scopeId) refers to (e.g. "study" or "study-space")
     * This is for query cache data readability during troubleshooting
     */
    scopeName: string;

    /**
     * Gets the scope ID from the given query params (e.g. x => x.studyId)
     * @param params The parameters to get the scope ID from
     */
    getScopeId: (params: TScopeParameters) => string;

    /**
     * Change service provider.
     * This function can use a hook inside to get the service.
     */
    getChangeEventService: () => ChangeEventService<TEvent>;
}

/**
 * Responsible for generating data-fetching hooks with "realtime" characteristics such as automatically refetching
 * when the data being queried changes.
 */
export class RealtimeDataHookFactory<TEvent extends ChangeEvent, TScopeParameters> {
    constructor(private config: RealtimeHookFactoryOptions<TEvent, TScopeParameters>) {}

    /**
     * Creates a RealtimeDataHookFactory scoped to a specific entity type.
     * This is a convenience method that is useful when you want to make multiple hooks
     * associated with the same entity and only want to configure the type once.
     *
     * @param type The entity type identifier
     */
    entity<
        TEntity,
        TEntityPathParameters extends TScopeParameters = TScopeParameters,
        TEntityType extends string = string,
    >(type: TEntityType): RealtimeEntityDataHookFactory<TEvent, TEntityPathParameters, TEntityType, TEntity> {
        return new RealtimeEntityDataHookFactory({
            ...(this.config as any),
            type,
        });
    }
}

/**
 * Options available when configuring a RealtimeEntityDataHookFactory
 */
export interface RealtimeEntityHookFactoryOptions<
    TEvent extends ChangeEvent,
    TScopeParameters,
    TEntityType extends string = string,
> extends RealtimeHookFactoryOptions<TEvent, TScopeParameters> {
    /**
     * The entity type identifier (e.g. Activity, Epoch, etc...)
     */
    type: TEntityType;
}

/**
 * Responsible for generating data-fetching hooks with "realtime" characteristics such as
 * automatically refetching when the data being queried changes.
 */
export class RealtimeEntityDataHookFactory<
    TEvent extends ChangeEvent,
    TEntityPathParameters,
    TEntityType extends string,
    TEntity,
> {
    constructor(private config: RealtimeEntityHookFactoryOptions<TEvent, TEntityPathParameters, TEntityType>) {}

    /**
     * Creates and returns a custom hook that queries data given the provided listFunction.
     * The data is refetched when change events with the same scopeId and entityType are encountered.
     *
     * @param listFunction Asynchronous data retrieval function
     * @param dependentEntityTypes Optional list of other change event entity types that should trigger data invalidation
     */
    list<TQueryParameters, TDependentEntityType extends string = string>(
        listFunction: ListFunction<TEntity, TEntityPathParameters & TQueryParameters>,
        dependentEntityTypes?: TDependentEntityType[]
    ): ListHook<TEntity, TEntityPathParameters & TQueryParameters> {
        const { config } = this;

        return (params: TEntityPathParameters & TQueryParameters, options?: ConsumerQueryOptions<TEntity[]>) => {
            const changeEventService = config.getChangeEventService();
            const events = changeEventService.events;
            const scopeId = config.getScopeId(params);
            const queryKey = [config.scopeName, scopeId, config.type, params];
            const queryFn = async ({ signal }: QueryFunctionContext) => {
                const revision = await changeEventService.latestRevisionCache?.get(scopeId);
                const paramsWithRevision = revision != null ? { ...params, revision } : params;
                return await listFunction(paramsWithRevision, signal);
            };

            return useRealtimeQuery({
                ...options,
                queryKey,
                queryFn,
                events,
                refetchWhen: event =>
                    event.scope === scopeId &&
                    (event.type === config.type || (dependentEntityTypes ?? []).includes(event.type)),
            });
        };
    }

    /**
     * Creates and returns a custom hook that queries data given the provided getFunction.
     * The data is refetched when change events with the same id field are encountered
     *
     * @param getFunction Asynchronous data retrieval function
     */
    get(
        getFunction: GetFunction<TEntity, TEntityPathParameters & Identified>
    ): GetHook<TEntity, TEntityPathParameters & Identified> {
        const { config } = this;

        return (params, options) => {
            const changeEventService = config.getChangeEventService();
            const events = changeEventService.events;
            const scopeId = config.getScopeId(params);
            const queryKey = [config.scopeName, scopeId, config.type, params.id];
            const queryFn = async ({ signal }: QueryFunctionContext) => {
                const revision = await changeEventService.latestRevisionCache?.get(scopeId);
                const paramsWithRevision = revision != null ? { ...params, revision } : params;
                return await getFunction(paramsWithRevision, signal);
            };

            return useRealtimeQuery({
                ...options,
                queryKey,
                queryFn,
                events,
                refetchWhen: event => event.id === params.id,
            });
        };
    }

    /**
     * Creates and returns a custom hook that mutates data given the provided mutateFunction.
     * The provided hook function will automatically roll back optimistic updates on error.
     *
     * @param mutateFunction The data mutation function
     */
    mutate<TResult, TParameters extends TEntityPathParameters = TEntityPathParameters>(
        mutateFunction: MutateFunction<TResult, TParameters>
    ): MutateHook<TResult, TParameters> {
        const { config } = this;

        return (scope: string, options) => {
            const service = config.getChangeEventService();
            const mutationKey = [config.scopeName, scope, config.type];

            return useRealtimeMutation({
                ...options,
                service,
                scope,
                mutationKey,
                mutationFn: mutateFunction,
                onError: (error, variables, context) => {
                    // Rollback optimistic updates on mutation errors
                    (context as OptimisticUpdateContext)?.rollback?.();

                    return options?.onError?.(error, variables, context);
                },
            });
        };
    }
}
