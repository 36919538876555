import { AllocationRule, toAllocationRule } from '@faro/study-designer-model';
import { cqrs } from '@faro/http-clients';
import { SchemaQueryParameter, StudyPathParameter } from '../parameter';

export type AllocationRulePathParameters = StudyPathParameter;
export type AllocationRuleQueryParameters = SchemaQueryParameter & {
    readonly parentId?: string;
};

/**
 * Client responsible for accessing {@link AllocationRule}s
 */
export const allocationRuleClient = cqrs<AllocationRule, AllocationRulePathParameters>(
    '/api/study-designer/studies/:studyId/allocation-rules',
    toAllocationRule
).writableEntityClient<AllocationRuleQueryParameters>();
