import { AppRouteContext } from '@faro/app-context';
import { FaroToastContainer } from '@faro/design-system';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import 'regenerator-runtime/runtime';
import { AppContextProvider } from './AppContextProvider';
import { AppQueryClientProvider } from './AppQueryClientProvider';
import { ErrorBoundary } from './component/ErrorBoundary/ErrorBoundary';
import TooltipPlugin from './component/TooltipPlugin/TooltipPlugin';
import { ConsoleLaunchedReactQueryDevtools } from './package/react-query';
import routes from './routes';
import { ServiceContextProvider } from './service';

const baseUrl = getBaseUrl();
axios.defaults.baseURL = baseUrl;
axiosRetry(axios, { retries: 0 });

export function getBaseUrl(): string {
    return document.querySelector('base')?.getAttribute('href')?.replace(/\/+$/, '') || '/';
}

function App(): React.JSX.Element {
    const router = createBrowserRouter(routes, { basename: baseUrl });
    return (
        <ErrorBoundary>
            <AppRouteContext.Provider value={{ routes: router.routes }}>
                <AppContextProvider>
                    <ServiceContextProvider>
                        <AppQueryClientProvider>
                            <RouterProvider router={router} />
                            <FaroToastContainer />
                            <TooltipPlugin />
                            <ConsoleLaunchedReactQueryDevtools />
                        </AppQueryClientProvider>
                    </ServiceContextProvider>
                </AppContextProvider>
            </AppRouteContext.Provider>
        </ErrorBoundary>
    );
}

export default App;
