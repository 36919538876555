import styles from './SideNavHeader.module.scss';
import { ComponentPropsWithRef, forwardRef, ReactNode, useMemo } from 'react';
import { AppsIcon, FaroTextIcon, LogoIcon, VerticalArrowsInIcon } from '@faro/icons';
import { matchPath, NavLink, useLocation } from 'react-router-dom';
import { AnalyticsEventKeys, ProductTypes, useAnalytics, useLocalStorageState } from '@faro/app-context';
import { FaroIconButton, FaroNavigationLink } from '@faro/design-system';

export interface LinkData {
    id: string;
    variant?: string;
    label: string;
    title?: string;
    icon: ReactNode;
    url: string;
}

export interface SideNavHeaderProps extends ComponentPropsWithRef<'div'> {
    links?: LinkData[];
    open?: boolean;
}

const SideNavHeader = forwardRef<HTMLDivElement, SideNavHeaderProps>((props, ref) => {
    const { children, className = '', links = [], open, ...rest } = props;
    const [expanded, setExpanded] = useLocalStorageState<boolean>('faro.side-nav.links-expanded', false);
    const location = useLocation();
    const activeLink = useMemo(
        () => links.find(link => matchPath({ path: link?.url + '/*', end: false }, location.pathname)),
        [location.pathname, links]
    );

    const { trackEvent } = useAnalytics();

    function trackLink(url: string) {
        if (url === '/spaces') {
            trackEvent(AnalyticsEventKeys.navigateToStudySpaces, { product: ProductTypes.studySpace });
        } else if (url === '/libraries') {
            trackEvent(AnalyticsEventKeys.navigateToBiomedicalLibrary, { product: ProductTypes.studySpace });
        } else if (url === '/user-management') {
            trackEvent(AnalyticsEventKeys.navigateToUserManagement, { product: ProductTypes.studySpace });
        }
    }

    return (
        <div
            {...rest}
            ref={ref}
            className={`
                ${className} 
                ${styles.sideNavHeader} 
                ${expanded ? styles.expanded : ''}
                ${open ? styles.open : ''}
            `}
            data-automation-id="sideNavHeader"
        >
            <div className={`${styles.links}`}>
                <div className={`${styles.link} ${styles.top}`}>
                    <NavLink to={activeLink?.url || '/'}>
                        <FaroNavigationLink
                            {...activeLink}
                            className={`${styles.linkNavItem}`}
                            variant={links[0]?.variant}
                            iconLeft={
                                <div className={styles.navIcon}>
                                    <LogoIcon />
                                </div>
                            }
                            label={
                                <div className={styles.homeLinkLabel}>
                                    <FaroTextIcon className={styles.faroText} />
                                </div>
                            }
                        />
                    </NavLink>

                    <FaroIconButton
                        data-automation-id="verticalArrows"
                        onClick={() => setExpanded(!expanded)}
                        className={styles.dropdownButton}
                        appearance="inline"
                        icon={
                            <>
                                <VerticalArrowsInIcon
                                    className={`${styles.icon} ${styles.overlayedIcon} ${
                                        expanded ? styles.visible : ''
                                    } `}
                                />
                                <AppsIcon className={`${styles.icon} ${expanded ? '' : styles.visible}`} />
                            </>
                        }
                    />
                </div>
                {links.map(linkProps => {
                    const { icon, url, ...rest } = linkProps;
                    return (
                        <div
                            key={rest.id}
                            className={styles.link}
                            data-automation-id={rest.id}
                            data-testid={rest.id}
                            data-intercom-target={`Navigate to ${rest.label}`}
                        >
                            <NavLink to={url} tabIndex={expanded ? 0 : -1}>
                                {({ isActive }) => (
                                    <FaroNavigationLink
                                        isActive={isActive}
                                        iconLeft={<div className={styles.navIcon}>{icon}</div>}
                                        className={styles.linkNavItem}
                                        {...rest}
                                        onClick={() => trackLink(url)}
                                    />
                                )}
                            </NavLink>
                        </div>
                    );
                })}
            </div>
        </div>
    );
});

export default SideNavHeader;
