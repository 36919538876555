import styles from './SpaceGeneralInformationForm.module.scss';
import React from 'react';
import { FaroSkeleton } from '@faro/design-system';

export const SpaceGeneralInformationFormSkeleton = (): JSX.Element => {
    return (
        <>
            <section className={styles.skeletonSection}>
                <h5>Study</h5>
                <FaroSkeleton height="lg" />
                <FaroSkeleton height="lg" />
                <FaroSkeleton height="lg" />
                <FaroSkeleton height="lg" />
                <FaroSkeleton height="lg" />
                <FaroSkeleton height="lg" />
                <FaroSkeleton height="lg" />
                <FaroSkeleton height="lg" />
                <FaroSkeleton height="lg" />
                <FaroSkeleton height="lg" />
            </section>
            <section className={styles.skeletonSection}>
                <h5>Vendors</h5>
                <FaroSkeleton height="lg" />
                <FaroSkeleton height="lg" />
                <FaroSkeleton height="lg" />
            </section>
            <section className={styles.skeletonSection}>
                <h5>Additional Information</h5>
                <FaroSkeleton height="lg" />
                <FaroSkeleton height="lg" />
                <FaroSkeleton height="lg" />
            </section>
        </>
    );
};
