import { PropsWithChildren, useMemo } from 'react';
import axios from 'axios';
import URLParse from 'url-parse';
import { FaroAuthProvider } from '@faro/auth';
import { memoize } from 'lodash';
import { useNavigate, NavigateFunction, useLocation, useHref } from 'react-router-dom';
import { useServiceContext } from '../../service';

function AuthProvider(props: PropsWithChildren<any>): JSX.Element {
    const navigate: NavigateFunction = useNavigate();
    const location = useLocation();
    const { tenantService, userInfoService } = useServiceContext();
    const postLogoutRedirectUrl = `${window.location.origin}${useHref('/signed-off')}`;
    const applicationRootUrl = `${window.location.origin}${useHref('/')}`;
    const providers = useMemo(
        () => ({
            options: memoize(() =>
                tenantService.get().then(tenant => ({
                    ...tenant,
                    authority: removeWellKnownAuthoritySuffix(tenant.authority),
                    metadataUrl: tenant.authority,
                }))
            ),
            user: memoize(() => userInfoService.getCurrent()),
            sessionTimeout: memoize(() =>
                tenantService.getConfiguration().then(configuration => configuration.sessionTimeout)
            ),
        }),
        []
    );

    /**
     * The oidc-client-ts's UserManager.stopSilentRenew() method call is not stopping the OIDC client
     * from doing requests. Particularly, 4 minutes after that it does an oidc-configuration call, which
     * causes the JS modules to reload and to execute the useAutoLogin() hook from ProtectedRoute component.
     * That effectively is performing a silent OIDC client login and the token's renew request is continue
     * to be executed against IdP. To avoid that we are navigating away from the application, to load in
     * the browser a new, designated timed-out page, which does not include an OIDC client, and thus the
     * token's renew requests are not sent anymore from this page.
     */
    function navigateToTimedOutPage(): void {
        if (location.pathname !== '/timed-out') {
            sessionStorage.setItem('last_pathname', location.pathname);
            navigate('/timed-out');
        }
    }

    return (
        <FaroAuthProvider
            {...providers}
            axios={axios}
            requireAuth={request => request?.url != null && new URLParse(request.url).pathname?.startsWith('/api/')}
            postLogoutRedirectUrl={postLogoutRedirectUrl}
            onBeforeSignIn={() => {
                const urlBeforeSignIn = `${location.pathname}${location.search}${location.hash}`;
                sessionStorage.setItem('urlBeforeSignIn', urlBeforeSignIn);
            }}
            onSignIn={() => {
                const urlBeforeSignIn = sessionStorage.getItem('urlBeforeSignIn') ?? '/';
                sessionStorage.removeItem('urlBeforeSignIn');
                navigate(urlBeforeSignIn || '/', { replace: true });
            }}
            onNoMatchingStateInStorageError={error => {
                console.error('No matching state found in storage', error);
                // Fix for when people bookmark or click the back button to return to
                // the /auth/openid/return (IDP auth) endpoint
                window.location.replace(applicationRootUrl);
            }}
            onUnauthorized={() => {
                navigate('/unauthorized');
            }}
            onUnauthenticated={navigateToTimedOutPage}
            onSessionTimeout={navigateToTimedOutPage}
        >
            {props.children}
        </FaroAuthProvider>
    );
}

function removeWellKnownAuthoritySuffix(authority: string): string {
    const wellKnownSuffix = '.well-known/openid-configuration';
    return authority.endsWith(wellKnownSuffix) ? authority.replace(wellKnownSuffix, '') : authority;
}

export default AuthProvider;
