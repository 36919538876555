import React, { ComponentPropsWithRef, forwardRef, useRef, useState } from 'react';
import {
    composeRefs,
    FaroIconButton,
    FaroMenuProps,
    FaroOverlayChildren,
    FaroOverlayTrigger,
    FaroTable,
} from '@faro/design-system';
import { MoreHorizontalIcon } from '@faro/icons';
import styles from './StudySpaceTable.module.scss';
import { Link } from 'react-router-dom';
import useSortableTable from '../../package/react-hooks/useSortableTable';
import { StudySpaceDisplayData } from '../../util/StudySpaceDisplayData';
import { AnalyticsEventKeys, ProductTypes, useAnalytics } from '@faro/app-context';

export interface StudySpaceTableProps extends ComponentPropsWithRef<'table'>, Pick<StudySpaceRowProps, 'menu'> {
    rows: StudySpaceDisplayData[];
}

const StudySpaceTable = forwardRef<HTMLTableElement, StudySpaceTableProps>((props, ref) => {
    const { rows, menu, className = '', ...rest } = props;

    const { items: sortedRows, sortDataBy, sortIconBy } = useSortableTable(rows);
    return (
        <FaroTable
            {...rest}
            ref={ref}
            className={`
                ${styles.table}
                ${className}
            `}
            appearance="bordered-minimally-interactive"
            data-automation-id="studySpaceTable"
        >
            <thead>
                <tr>
                    <th onClick={() => sortDataBy(['title'])}>
                        <div data-testid="studySpaceTableHeader-title" className={styles.headerContent}>
                            Protocol Information {sortIconBy('title')}
                        </div>
                    </th>
                    <th onClick={() => sortDataBy(['phase'])}>
                        <div data-testid="studySpaceTableHeader-phase" className={styles.headerContent}>
                            Phase {sortIconBy('phase')}
                        </div>
                    </th>
                    <th onClick={() => sortDataBy(['therapeuticArea'])}>
                        <div data-testid="studySpaceTableHeader-therapeuticArea" className={styles.headerContent}>
                            Therapeutic Area {sortIconBy('therapeuticArea')}
                        </div>
                    </th>
                    <th onClick={() => sortDataBy(['diseaseArea'])}>
                        <div data-testid="studySpaceTableHeader-diseaseArea" className={styles.headerContent}>
                            Disease Area {sortIconBy('diseaseArea')}
                        </div>
                    </th>
                    {typeof menu === 'function' && <th></th>}
                </tr>
            </thead>
            <tbody>
                {sortedRows.map((row: StudySpaceDisplayData, index: number) => (
                    <StudySpaceRow key={row.key} row={row} menu={menu} index={index} />
                ))}
            </tbody>
        </FaroTable>
    );
});

export interface StudySpaceRowProps extends ComponentPropsWithRef<'tr'> {
    row: StudySpaceDisplayData;
    menu?(row: StudySpaceDisplayData): React.ReactElement<FaroMenuProps>;
    index: number;
}

const StudySpaceRow = forwardRef<HTMLTableRowElement, StudySpaceRowProps>((props, ref) => {
    const { row, menu, index, className = '', ...rest } = props;
    const menuButtonRef = useRef<HTMLButtonElement>(null);
    const { trackEvent } = useAnalytics();

    const [rowIsActive, setRowIsActive] = useState(false);

    const handleRowClick = (event: React.MouseEvent<HTMLTableRowElement>) => {
        // Don't trigger row if the click is inside the menu or menu trigger
        if (
            !menuButtonRef.current?.contains(event.target as HTMLElement) &&
            (event.target as HTMLElement).closest('[role="menu"]') == null
        ) {
            trackEvent(AnalyticsEventKeys.selectStudySpaceInListingsView, { product: ProductTypes.studySpace });
        }
    };

    const handleRowMouseDown = (event: React.MouseEvent<HTMLTableRowElement>) => {
        // Don't trigger row if the click is inside the menu button
        if (!menuButtonRef.current?.contains(event.target as HTMLElement)) {
            setRowIsActive(true);
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLTableRowElement>) => {
        if (event.target === event.currentTarget && (event.code === 'Enter' || event.code === 'Space')) {
            setRowIsActive(true);
            trackEvent(AnalyticsEventKeys.selectStudySpaceInListingsView, { product: ProductTypes.studySpace });
            event.preventDefault();
        }
    };

    const { title = 'Untitled', space, description, phase, therapeuticArea, diseaseArea, otherDiseaseAreas } = row;

    return (
        <tr
            tabIndex={0}
            onClick={handleRowClick}
            onKeyDown={handleKeyDown}
            onKeyUp={() => setRowIsActive(false)}
            onMouseDown={handleRowMouseDown}
            onMouseUp={() => setRowIsActive(false)}
            onMouseLeave={() => setRowIsActive(false)}
            {...rest}
            ref={ref}
            className={`
                ${styles.row}
                ${rowIsActive ? styles.activeRow : ''}                
                ${className}
            `}
            data-automation-role="studySpaceRow"
            data-intercom-target="Select Study Space"
        >
            <FaroTable.Cell className={styles.protocolInformation}>
                <Link className={styles.link} to={`/spaces/${space.id}`} data-testid={`spaces-${space.id}-${index}`}>
                    <div data-testid="studySpaceTitle" data-automation-id="studySpaceTitle" className={styles.title}>
                        {title}
                    </div>
                    <div
                        data-automation-id="studySpaceDescription"
                        className={`${styles.cellText} ${styles.description}`}
                    >
                        {description}
                    </div>
                </Link>
            </FaroTable.Cell>
            <FaroTable.Cell data-automation-id="studySpacePhase" className={styles.phase}>
                <Link className={styles.link} to={`/spaces/${space.id}`}>
                    <div className={styles.cellText}>{phase}</div>
                </Link>
            </FaroTable.Cell>
            <FaroTable.Cell data-automation-id="studySpaceTherapeuticArea" className={styles.therapeuticArea}>
                <Link className={styles.link} to={`/spaces/${space.id}`}>
                    <div className={styles.cellText}>{therapeuticArea}</div>
                </Link>
            </FaroTable.Cell>
            <FaroTable.Cell data-automation-id="studySpaceDiseaseArea" className={styles.diseaseArea}>
                <Link className={styles.link} to={`/spaces/${space.id}`}>
                    <div className={styles.cellText}>{diseaseArea}</div>
                    <div
                        data-automation-id="studySpaceOtherDiseaseAreas"
                        className={`${styles.cellText} ${styles.otherDiseaseAreas}`}
                    >
                        {otherDiseaseAreas}
                    </div>
                </Link>
            </FaroTable.Cell>
            {typeof menu === 'function' && (
                <FaroTable.Cell>
                    <FaroOverlayTrigger placement="bottom-end" openOnClick overlay={menu(row) as FaroOverlayChildren}>
                        {(triggerProps, state) => (
                            <FaroIconButton
                                {...triggerProps}
                                ref={composeRefs(triggerProps.ref, menuButtonRef)}
                                icon={<MoreHorizontalIcon />}
                                appearance="inline"
                                className={`${styles.menuButton} ${state.open ? styles.menuOpen : ''}`}
                                active={state.open}
                                data-automation-id="studySpaceRowControlButton"
                                data-testid="studySpaceRowControlButton"
                                data-intercom-target="Study Space Row Menu"
                            />
                        )}
                    </FaroOverlayTrigger>
                </FaroTable.Cell>
            )}
        </tr>
    );
});

export default StudySpaceTable;
