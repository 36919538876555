import { Section, toSection } from '@faro/study-designer-model';
import { cqrs } from '@faro/http-clients';
import { StudyPathParameter } from '../parameter';

export type SectionPathParameters = StudyPathParameter;

/**
 * Client responsible for accessing the backend entity services
 */
export const sectionClient = {
    ...cqrs<Section, SectionPathParameters>(
        '/api/study-designer/studies/:studyId/sections',
        toSection
    ).writableEntityClient(),
};
