import { BiomedicalNamedObject, toRequiredBiomedicalNamedObject } from '@faro/study-designer-model';
import { cqrs } from '@faro/http-clients';

/**
 * Client responsible for managing study contributors
 */
export const studyContributorClient = cqrs<BiomedicalNamedObject>(
    '/api/study-designer/enumerated-values/study-contributors',
    toRequiredBiomedicalNamedObject
).listableEntityClient();
