import React from 'react';
import MetadataTable from '../MetadataTable/MetadataTable';
import { FieldArray } from 'formik';

export interface AdditionalInformationFieldProps {
    readOnly?: boolean;
    value: [string, string][];
}

export const AdditionalInformationField = (props: AdditionalInformationFieldProps): JSX.Element => {
    const { readOnly, value = [] } = props;

    return (
        <FieldArray
            name="additionalInformation"
            render={arrayHelpers => (
                <MetadataTable
                    name="additionalInformation"
                    rows={value}
                    readOnly={readOnly}
                    onRowInsert={(index: number) => {
                        arrayHelpers.insert(index, []);
                    }}
                    onRowClone={(_row, index) => {
                        arrayHelpers.insert(index + 1, [...value[index]]);
                    }}
                    onRowMove={(_row, from: number, to: number) => {
                        arrayHelpers.move(from, to);
                    }}
                    onRowDelete={(_row, index: number) => {
                        arrayHelpers.remove(index);
                    }}
                />
            )}
        />
    );
};
