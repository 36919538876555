import { DraggingStyle, NotDraggingStyle } from '@hello-pangea/dnd';
import { transformStyleToTranslations } from './transformStyleToTranslations';

/**
 * Removes the x-translation from the react-beautiful-dnd provided draggable style
 * @param style The draggable style to modify
 */
export function removeTranslationX<T extends DraggingStyle | NotDraggingStyle | undefined>(style: T): T {
    if (style?.transform != null) {
        const [, y] = transformStyleToTranslations(style.transform);
        return {
            ...style,
            transform: `translateY(${y})`,
        };
    }
    return style;
}
