import { AllocationRuleAttributes, toAllocationRuleAttributes } from '@faro/study-designer-model';
import { cqrs } from '@faro/http-clients';
import { SchemaQueryParameter, StudyPathParameter, VariantQueryParameter } from '../parameter';

export type AllocationRuleAttributesPathParameters = StudyPathParameter;
export type AllocationRuleAttributesQueryParameters = SchemaQueryParameter & VariantQueryParameter;

/**
 * Client responsible for accessing {@link AllocationRuleAttributes} entities.
 */
export const allocationRuleAttributesClient = cqrs<AllocationRuleAttributes, AllocationRuleAttributesPathParameters>(
    '/api/study-designer/studies/:studyId/allocation-rule-attributes',
    toAllocationRuleAttributes
).listableEntityClient<AllocationRuleAttributesQueryParameters>();
