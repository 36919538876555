import { chunk } from 'lodash';
/**
 * @deprecated
 * Use cases for this approach should be discussed with architecture team.
 *
 * In general apis retrieving with unbound query parameter lists should be refactored
 * to take advantage of other contextual identifiers.
 *
 * If nothing specific exists, retrieving a subset of the list and then filtering it based
 * on a hashmap of identifiers (e.g. entity ids) on the frontend is preferrable to
 * this api batching approach.
 *
 */
const batchIds = <Query, Entity, Return = Entity[]>(
    params: Query,
    list: (query: Query, signal?: AbortSignal) => Promise<Return>,
    options: {
        size?: number;
        property?: keyof Query;
        delimiter?: string;
        flattenResponses: (responses: Return[]) => Return;
    } = {} as any,
    signal?: AbortSignal
): Promise<Return> => {
    const { size = 50, property = 'ids', delimiter = ',', flattenResponses } = options;
    const identifiers = (params as any)[property]?.split(delimiter) || [];
    if (identifiers == null || identifiers?.length < size) {
        return list(params, signal);
    } else {
        const promises = chunk(identifiers, size).map(
            identifiers => list({ ...params, [property]: identifiers?.join(delimiter) }, signal) || ''
        );

        return Promise.all(promises).then(flattenResponses);
    }
};
export default batchIds;
