/**
 * Extracts the individual axis translation styles from a react-beautiful-dnd transform style
 * @param transformStyle Transform style in the format "translate(x, y)"
 */
export function transformStyleToTranslations(transformStyle: string): string[] {
    // Assumes transform only has translation (which is a safe assumption with react-beautiful-dnd)
    // This solution cannot be used outside of react-beautiful-dnd for general purpose
    const start = transformStyle.indexOf('(');
    const end = transformStyle.indexOf(')', start);
    return transformStyle.slice(start + 1, end).split(',');
}
