import { createResourceQuery } from '@faro/resource-query';
import { ServiceContextValue } from '../../../context';
import { StudyDefinitionReference, StudySpace } from '@faro/study-space-service-model';
import { AxiosError } from 'axios';
import { StudyEntityType } from '@faro/study-designer-model';
import { UserInfo } from '@faro/user-management-model';
import { uniqBy } from 'lodash';

export interface SpacePageData {
    space: StudySpace;
    studyDefinitionReferences: StudyDefinitionReference[];
    userInfos: UserInfo[];
}
export interface SpacePageDataQuery {
    id: string;
    includeDeleted?: boolean;
}

export interface SpacePageResolverArgs {
    context: ServiceContextValue;
    query: SpacePageDataQuery;
}

export const useSpacePageData = createResourceQuery<SpacePageData, SpacePageDataQuery, ServiceContextValue, AxiosError>(
    {
        id: 'spacePageData',
        resolve: async ({ context, query }: SpacePageResolverArgs) => {
            const { studySpaceService, userInfoService, studyDefinitionReferenceService } = context;
            const { id, includeDeleted } = query;
            const [space, studyDefinitionReferences] = await Promise.all([
                studySpaceService.get({ id }),
                studyDefinitionReferenceService.list({
                    spaceId: id,
                    includeDeleted,
                }),
            ]);

            const userIds = uniqBy(studyDefinitionReferences, 'lastUpdatedBy')
                .map(s => s.lastUpdatedBy)
                .filter(lastUpdatedBy => lastUpdatedBy != null);

            const userInfoQuery = { ids: userIds.join(',') };
            const hasIds = userIds.length > 0;
            const userInfos = hasIds ? await userInfoService.getAll(userInfoQuery) : [];

            return {
                space,
                studyDefinitionReferences,
                userInfos,
            };
        },
        queryKeys: [StudyEntityType.Study],
    }
);
