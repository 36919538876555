import { StageGroup, toStageGroup } from '@faro/study-designer-model';
import { cqrs } from '@faro/http-clients';
import { PopulationQueryParameter, StudyPathParameter } from '../parameter';

export type StageGroupPathParameters = StudyPathParameter;
export type StageGroupQueryParameters = PopulationQueryParameter;

/**
 * Client responsible for accessing the backend entity services
 */
export const stageGroupClient = cqrs<StageGroup, StageGroupPathParameters>(
    '/api/study-designer/studies/:studyId/stage-groups',
    toStageGroup
).listableEntityClient<StageGroupQueryParameters>();
